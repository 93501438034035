import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ErrorMessage from '../shared/components/ErrorMessage';
import { AuthPIResult, UserPI } from '../_entities/PI';
import { signAccountUser } from '../_redux/slices/auth/auth.actions';
import Header from './components/Header';
import SignIn from './components/SignIn';
import { GoogleLoginButton } from './components/GoogleLoginButton';
import { useGetUserDataForFirebaseMutation, useGetUserExistsForFirebaseMutation, useSignInWithFBMutation, useSignInWithGoogleMutation } from '../_redux/slices/API/externalLogin.service';
import { reset, signFB, signGoogle, signPassword } from '../_redux/slices/auth/auth.slice';
import { url } from 'inspector';
// Dialago imports
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { Alert, Button } from '@mui/material';
import { GoogleFirebaseLoginButton } from './components/GoogleFirebaseLoginButton';
import Loading from '../shared/components/Loading';

import Bird from './components/Bird';
import CounterDays from '../TEMP/counterDays';
import RegisterModal from './components/RegisterModal';

import { authFirebase, listenEventForUserEvents, mapErrorCodeToMessage, signWithEmailAndPasswordFirebase } from '../firebase';
import ResetPasswordModal from './components/ResetPasswordModal';
import { FirebaseError } from 'firebase/app';
import { Unsubscribe } from 'firebase/firestore';


interface PaymentDTO {
  amount: number,
  user_uid: string,
  created_at: string,
  identifier: string,
  metadata: Object,
  memo: string,
  status: {
    developer_approved: boolean,
    transaction_verified: boolean,
    developer_completed: boolean,
    cancelled: boolean,
    user_cancelled: boolean,
  },
  to_address: string,
  transaction: null | {
    txid: string,
    verified: boolean,
    _link: string,
  },
};

// Make TS accept the existence of our window.__ENV object - defined in index.html:
interface WindowWithEnv extends Window {
  __ENV?: {
    backendURL: string, // REACT_APP_BACKEND_URL environment variable
    sandbox: "true" | "false", // REACT_APP_SANDBOX_SDK environment variable - string, not boolean!
  }
}
const _window: WindowWithEnv = window;
const backendURL = _window.__ENV && _window.__ENV.backendURL;

const axiosClient = axios.create({ baseURL: `${backendURL}`, timeout: 20000, withCredentials: true });
const config = { headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' } };


interface ErrorMessageState { errorCode: string | Number, message: string }

export default function Account() {
  const [errors, setErrors] = useState({ email: '', password: '' });

  const [newCredentials, setNewCredentials] = useState({
    email: '',
    password: ''
  })

  const fechaObjetivo = new Date('2024-03-17');

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  //const [user, setUser] = useState<UserPI | null>(null);
  const { loading, userInfo, userToken, error, success } = useSelector(
    (state: any) => state.auth
  )
  const dispatch = useDispatch()
  const [signInWithGoogle, responseSignInWithGoogle] = useSignInWithGoogleMutation()
  const [getDataForFirebase, responseGetDataForFirebase] = useGetUserDataForFirebaseMutation();
  const [getUserExists, responseGetUserExists] = useGetUserExistsForFirebaseMutation()
  const [signInWithFB, responseSignInWithFB] = useSignInWithFBMutation()

  const [isGoogleLoading, setIsGoogleLoading] = useState<any>(false)

  const [isRegisterModalOpen, setIsRegisterModalOpen] = useState(false);
  const [isResetPasswordModalOpen, setIsResetPasswordModalOpen] = useState(false);

  const [userFirebaseId, setUserFirebaseId] = useState<null | string>(null)
  const [accessToken, setAccessToken] = useState<null | string>(null)

  

  const [errorMessage, setErrorMessage] = useState<null | ErrorMessageState>(null)

  /**
   * login with email and password
   */


  const handleSubmitLoginWithPassword = () => {

    signWithEmailAndPasswordFirebase(authFirebase, newCredentials.email, newCredentials.password)
      .then(async (userCredential) => {
        // Signed in 
        const user = userCredential.user;
        const token = await user.getIdToken();
        console.log(userCredential)
        //change state user auth
        dispatch(signPassword({
          userInfo: {
            uid: user.uid,
            username: user.displayName,
            language_preferences: [],

          },
          userToken: token,
        }))
        setErrorMessage(null)
      })
      .catch((error: FirebaseError) => {



        const errorCode = error.code;
        const message = error.message.replace('Firebase: ', '');
        console.log(error.message)
        dispatch(reset({}))
        setErrorMessage({
          errorCode,
          message: mapErrorCodeToMessage(errorCode)
        })
      });
  }




  const handleChangeFrmNewUser = (e: any) => {
    const { name, value } = e.target;
    setNewCredentials({
      ...newCredentials,
      [name]: value
    });

    // Validación del correo electrónico
    if (name === 'email') {
      if (!value) {
        setErrors({ ...errors, email: 'Email field cannot be empty' });
      } else if (!/\S+@\S+\.\S+/.test(value)) {
        setErrors({ ...errors, email: 'Enter a valid email address' });
      } else {
        setErrors({ ...errors, email: '' });
      }
    }

    // Validación de la contraseña
    if (name === 'password') {
      if (!value) {
        setErrors({ ...errors, password: 'Password field cannot be empty' });
      } else {
        setErrors({ ...errors, password: '' });
      }
    }


  };
  // Verificar si hay errores de validación
  const hasErrors = Object.values(errors).some(error => error !== '');
  /**
   * end login with email and password
   */



  /*
  google functions
  */
  const handleGoogleLoginSuccess = async (token: string) => {
    // Aquí puedes manejar el inicio de sesión exitoso
    console.log('Token de acceso:', token);

    let result = await signInWithGoogle({ payload: { token } })
      .unwrap()
      .then((res) => {
        console.log(res, 'result')
        //change state user auth
        dispatch(signGoogle({
          userInfo: {
            uid: res.data.uid,
            username: res.data.username,
            language_preferences: res.data.language_preferences,

          },
          userToken: res.data.systemtoken,
        }))


      }).catch((err) => {
        dispatch(reset({}))
      })
  };

  useEffect(() => {
    if (accessToken != null) {
      setIsGoogleLoading(false)
    }
  }, [accessToken])


  useEffect(() => {
    let unsubs: null | Unsubscribe = null
    if (userFirebaseId) {
      setIsGoogleLoading(true)
      unsubs = listenEventForUserEvents(userFirebaseId, (doc) => {
        console.log(doc,'doc updated ')
        if(doc){
          setIsGoogleLoading(false)
          dispatch(signGoogle({
            userInfo: {
              uid: doc.eventData.mongoId,
              username: doc.eventData.username,
              language_preferences: doc.eventData.language_preferences,
  
            },
            userToken: accessToken
          }))
        }

      })
    }

    // Limpia el listener cuando el componente se desmonta
    return () => {
      if (unsubs != null) {
        unsubs();
      }
    };
  }, [userFirebaseId])

  const handleGoogleFirebaseLoginSuccess = async (token: string, uid: string, username: string | null) => {
    // Aquí puedes manejar el inicio de sesión exitoso
    console.log('Token de acceso:', token);
    setAccessToken(token)
    setUserFirebaseId(uid)


  }





  const handleGoogleLoginFailure = (error: any) => {
    // Aquí puedes manejar el inicio de sesión fallido
    console.error('Error de inicio de sesión:', error);
  };

  /**
   * End google functions
   */

  /*
fb functions
*/
  const handleFBLoginSuccess = async (token: string) => {
    // Aquí puedes manejar el inicio de sesión exitoso
    console.log('Token de acceso:', token);

    let result = await signInWithFB({ payload: { token } })
      .unwrap()
      .then((res) => {
        console.log(res, 'result')
        //change state user auth
        dispatch(signFB({
          userInfo: {
            uid: res.data.uid,
            username: res.data.username,
            language_preferences: res.data.language_preferences,
          },
          userToken: res.data.systemtoken,
        }))


      }).catch((err) => {
        dispatch(reset({}))
      })
  };

  const handleFBLoginFailure = (error: any) => {
    // Aquí puedes manejar el inicio de sesión fallido
    console.error('Error de inicio de sesión:', error);
  };

  /**
   * End fb functions
   */

  const onIncompletePaymentFound = (payment: PaymentDTO) => {
    console.log("onIncompletePaymentFound", payment);
    return axiosClient.post('/payments/incomplete', { payment });
  }





  /**
 * birds functions
 */
  const birdsCount = 50; // Define cuántos pájaros quieres renderizar
  const birds = Array.from({ length: birdsCount }, (_, index) => {
    const top = `${Math.random() * 50}%`;
    const left = `0`;
    const animationDuration = 10 + Math.random() * 5;
    const animationDelay = Math.random() * 2;
    const flyCycleDelay = Math.random() * 0.5;

    return {
      animationDuration,
      animationDelay,
      flyCycleDelay,
      startPosition: { top, left },
    };
  });
  /**
   * Endbirds functions
   */


  const openRegisterModal = () => {
    setIsRegisterModalOpen(true);
  };

  const closeRegisterModal = () => {
    setIsRegisterModalOpen(false);
  };

  const openResetPasswordModal = () => {
    setIsResetPasswordModalOpen(true);
  }

  const closeResetPasswordModal = () => {
    setIsResetPasswordModalOpen(false);
  };



  return (
    <>
      <div style={{ width: '60vw', height: '100vh', position: 'absolute', top: '2vh', right: '-20vw', backgroundImage: "url('../_assets/wordsTree.png')", backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}></div>

      <div>
        {birds.map((bird, index) => (
          <Bird
            key={index}
            animationDuration={bird.animationDuration}
            animationDelay={bird.animationDelay}
            startPosition={bird.startPosition}
            flyCycleDelay={bird.flyCycleDelay}
          />
        ))}
      </div>


      <div className="bg-amber-100 min-h-screen flex items-center justify-center">

        <div className='absolute'>

          <div className='flex flex-col'>
            <div className='mb-8'>
              <img className="w-96 max-w-xs" src="../_assets/logoBlack.png" alt="Quotless" />
              <h1 className=" text-center text-6xl font-semibold text-gray-800  font-lora hidden">Quotless</h1>
            </div>

            {/* Formulario de inicio de sesión */}
            <form className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
              {
                errorMessage ?
                  <Alert variant="outlined" severity="warning">
                    {errorMessage.message}
                  </Alert> :
                  (null)
              }

              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
                  Email:
                </label>
                <input type="email" id="EmailAddress" name="email"
                  value={newCredentials.email}
                  onChange={handleChangeFrmNewUser} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder="Enter your email" />
                {errors.email && <div style={{ color: 'red' }}>{errors.email}</div>}
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">
                  Password:
                </label>
                <input type="password" id="Password"
                  name="password"
                  value={newCredentials.password}
                  onChange={handleChangeFrmNewUser} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder="Enter your password" />

                {errors.password && <div style={{ color: 'red' }}>{errors.password}</div>}
              </div>
              <div className="mb-4">
                <label className="text-sm text-gray-700" htmlFor="terms">
                  By logging in, you accept Quotless'
                  <a href='/termsandconditions.html' className="underline">
                    Terms and Conditions
                  </a> and <a href='/privacypolicy.html' className="underline">
                    Privacy Policy
                  </a>.
                </label>
              </div>
              <div className="flex items-center justify-between">
                <button onClick={handleSubmitLoginWithPassword} disabled={hasErrors} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="button">
                  Start the Journey! Sign In
                </button>
                <a className="inline-block align-baseline font-bold text-sm text-blue-500 hover:text-blue-800" href="#" onClick={openResetPasswordModal}>
                  Forgot your password?
                </a>
              </div>
            </form>

            {/* Botón de inicio de sesión con Google */}

            {
              !isGoogleLoading ? (

                <GoogleFirebaseLoginButton
                  onSuccess={handleGoogleFirebaseLoginSuccess}
                  onFailure={handleGoogleLoginFailure}
                />

              ) : (
                <Loading />
              )
            }


            {/* Enlace para registro */}
            <p className="text-center text-gray-500 text-xs">
              New here? <Button type='button' onClick={openRegisterModal}> Register now!</Button>
            </p>

            {/* Modal de registro */}
            <RegisterModal isOpen={isRegisterModalOpen} onClose={closeRegisterModal} />
            <ResetPasswordModal isOpen={isResetPasswordModalOpen} onClose={closeResetPasswordModal} />

          </div>

        </div>

      </div>


      {/* <div style={{ width: '40vw', height:'100vh', position:'absolute',top:'20vh',left:'-9vw',backgroundImage:"url('../_assets/golondrinas.png')", backgroundSize:'contain', backgroundRepeat:'no-repeat'}}></div> */}





    </>
  )
} 