import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Dialog from '@mui/material/Dialog';
import SendIcon from '@mui/icons-material/Send';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';
import Avatar from '@mui/material/Avatar';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { blue } from '@mui/material/colors';
import Grid from '@mui/material/Grid';
import { Box, Button, DialogContent, Icon } from '@mui/material';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Typography from "@mui/material/Typography";
import Stack from '@mui/material/Stack';
import Grow from '@mui/material/Grow';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import FormControl from '@mui/material/FormControl';
import { InputLabel, Input, FormHelperText } from '@mui/material';
import TextField from '@mui/material/TextField';
import { useCreateContentReportMutation, useGetReportReasonQuery, useGetReportStateQuery } from "../../_redux/slices/API/report.service"
import ReportForm from "./ReportForm"
import { amber } from '@mui/material/colors';
const ITEM_HEIGHT = 48;

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));


const options = [
    {
        name: 'Report',
        icon: <ReportProblemIcon/>,
        action: ReportForm,
        option_name: 'report'
    }
];


function OptionsDialog({setDialogOption} : any) : React.ReactNode{

    const handleClick = (value: any) => {
        setDialogOption(value);
    }

    return <>
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'space-evenly',
                bgcolor: 'background.paper',
                borderRadius: 1
            }}
        >
            {options.map((option) => (
                <Item sx={{
                    p: 2,
                    m: 1,
                }}> 
                    <IconButton onClick={ () => handleClick(option.option_name)}>
                        { option.icon }
                    </IconButton>
                    <Typography>
                        { option.name }
                    </Typography>
                </Item>                            
            ))}
        </Box>
    </>
}

type FeedOptions = {
    item: any
}

function OptionsIcon({item} : FeedOptions){
    const [open, setOpen] = React.useState(false);
    const [dialogOption, setDialogOption] = React.useState('menu');

    interface d {
        [state: string]: any    
    }

    const DIALOGS : d = {
        "menu": 
        {
            title: "Choose an option",
            function : OptionsDialog({ setDialogOption })
        },
        "report":
        {
            title: "Report this publication",
            function: ReportForm({ setDialogOption, setOpen, item })
        }
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (value: string) => {
        setOpen(false);
        setDialogOption("menu");
    };

    return <>
        <div>
            <IconButton
                aria-label="more"
                id="options-button"
                aria-haspopup="true"
                aria-controls={open ? 'options-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClickOpen}
            >
                <MoreHorizIcon sx={{ color: amber[200] }}/>
            </IconButton>
            <Dialog open={open} onClose={handleClose}>
            <DialogTitle>{ DIALOGS[dialogOption].title }</DialogTitle>
            <DialogContent>
                {  DIALOGS[dialogOption].function }
            </DialogContent>
        </Dialog>
        </div>
    </>
}

export default OptionsIcon;