// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, sendPasswordResetEmail, confirmPasswordReset, signOut, signInWithCredential } from "firebase/auth";

import { doc, getFirestore, onSnapshot } from "firebase/firestore";



// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAgMmtvX5Z60-XTcTcEdXnqSVOtrCiEDe0",
  authDomain: "quotless-prod-v1.firebaseapp.com",
  projectId: "quotless-prod-v1",
  storageBucket: "quotless-prod-v1.appspot.com",
  messagingSenderId: "321156957569",
  appId: "1:321156957569:web:dc37089524e72eb363d480",
  measurementId: "G-G9NT72TLRF"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const authFirebase = getAuth(app)
// Initialize Cloud Firestore and get a reference to the service
export const db = getFirestore(app);
export const createUserLoginEmailPasswordFirebase = createUserWithEmailAndPassword
export const signWithEmailAndPasswordFirebase = signInWithEmailAndPassword
export const sendPasswordResetEmailFirebase = sendPasswordResetEmail
export const confirmPasswordResetFirebase = confirmPasswordReset
export const signInWithCredentialFirebase = signInWithCredential
export const signOutFirebase = signOut

const errorCodeMap: any = {
  'auth/email-already-in-use': 'The email address is already in use.',
  'auth/invalid-email': 'The email address provided is invalid.',
  'auth/weak-password': 'The password provided is weak. It must be at least 6 characters long.',
  'auth/user-not-found': 'No user found for the given email address.',
  'auth/wrong-password': 'Incorrect password provided.',
  'auth/network-request-failed': 'A network error occurred. Please check your internet connection and try again.',
  'auth/too-many-requests': 'Too many unsuccessful login attempts. Please try again later.',
  'auth/operation-not-allowed': 'The operation is not allowed. Please contact support for assistance.',
  'auth/invalid-verification-code': 'The verification code is invalid. Please double-check the code and try again.',
  'auth/missing-verification-code': 'A verification code is required. Please provide a valid code.',
  'auth/user-disabled': 'The user account has been disabled. Please contact support for assistance.',
  'auth/user-token-expired': 'The user\'s token has expired. Please reauthenticate.',
  'auth/user-mismatch': 'There is a mismatch between the current user and the user whose credential was used to perform the operation.',
  'auth/invalid-credential': 'The credential used to authenticate is invalid. Please verify and try again.',
  'auth/user-not-signed-in': 'No user is currently signed in. No logout operation is needed.',
  // Agrega más códigos de error y mensajes aquí según sea necesario
};

export const mapErrorCodeToMessage = (errorCode: string) => {
  return errorCodeMap[errorCode] || 'An unexpected error occurred. Please try again later.';
};



/**
 * FUNCTIONS FOR FIREBASE
 */

export const listenEventForUserEvents = (firebaseUid:string, cb:(doc:any)=>void) => {
  return onSnapshot(doc(db, "userEvents",firebaseUid), (doc) => {
    console.log("Current data: ", doc.data());
    cb(doc.data())
  });
}
/**
 * END FUNCTIONS FOR FIREBASE
 */