import React, { useEffect, useState } from 'react';
import { Avatar, Card, CardContent, Typography, CardHeader, CardActions, Collapse, Box, IconButton, Button, Chip } from '@mui/material';
import CommentIcon from "@mui/icons-material/Comment";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeleteIcon from '@mui/icons-material/Delete';
import { Favorite, FavoriteBorder } from '@mui/icons-material';
import { red, pink } from "@mui/material/colors";




import Timeline from '@mui/lab/Timeline';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import { useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { TYPE_ELEMENT, TYPE_INTERACTION } from '..';
import { useUpdateNewsToReadedMutation } from '../../../_redux/slices/API/news.service';
import { ChangeFormatDate } from '../../../shared/utils/date.util';
import ErrorMessage from '../../../shared/components/ErrorMessage';

type USERDATA = {
    username: string,
    _id: string
}

export interface INewsProps {
    _id: string
    type_interaction: TYPE_INTERACTION,
    type_element: TYPE_ELEMENT,
    receptor: USERDATA,
    sender: USERDATA,
    is_readed?: boolean,
    content_id?: {
        _id: string,
        contentText: string
    },
    comment_id?: {
        _id: string,
        text: string
    },
    created_at: any | null
}
const NewsItem = ({
    _id,
    type_interaction,
    type_element,
    receptor,
    sender,
    is_readed,
    content_id,
    comment_id,
    created_at,
}: INewsProps) => {
    
    const navigate = useNavigate();
    const [udpdateNewsToReaded, response] = useUpdateNewsToReadedMutation()
    function stringToColor(string: string) {
        let hash = 0;
        let i;

        /* eslint-disable no-bitwise */
        for (i = 0; i < string.length; i += 1) {
            hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }

        let color = '#';

        for (i = 0; i < 3; i += 1) {
            const value = (hash >> (i * 8)) & 0xff;
            color += `00${value.toString(16)}`.slice(-2);
        }
        /* eslint-enable no-bitwise */

        return color;
    }

    const goToLink = (link: string) => {
        //updated to read
        udpdateNewsToReaded({id:_id})
        .unwrap()
        .then(() => {
            console.log('updated news') 
            return navigate(link, {
                state: {
                    fromNotification: true,
                    typeElement: type_element,
                    typeInteraction: type_interaction
                }
            })
        })
        .catch((error:any) => {      
          console.log(error,'ERROR/// :(')      
        }) 

    }

    const gotoProfile = (item: any) => {
        //TODO: Pasar esta funcion (gotoProfile) a una archivo de utils o hooks
        console.log('go to profile')
        navigate(`/profile/${item._id}`);
    }

    function stringAvatar(name: string) {
        return {
            sx: {
                bgcolor: stringToColor(name),
                width: 34, height: 34
            },
            //children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
            children: `${name[0]}${name[Math.round(name.length / 2)]}`,
        };
    }

    let textNotification: any = null

    if (type_element == TYPE_ELEMENT.author) {
        if (type_interaction == TYPE_INTERACTION.follow) {
            textNotification = {
                title: 'New Follower',
                body: `Congratulations! ${sender.username}  has just started following you. Enjoy having a new follower in your community!`,
                link: `/profile/${sender._id}`
            }
        }
    } else if (type_element == TYPE_ELEMENT.content) {
        switch (type_interaction) {
            case TYPE_INTERACTION.like:
                textNotification = {
                    title: 'New Like',
                    body: ` ${sender.username}  has liked your content. Keep sharing excellent stories!`,
                    link: `/content/${content_id?._id}`
                }
                break;
            case TYPE_INTERACTION.comment:
                textNotification = {
                    title: 'New Comment',
                    body: ` ${sender.username}   has left a comment on your post. Go and reply to keep the conversation going!`,
                    link: `/content/${content_id?._id}`
                }
                break;
        }
    } else if (type_element == TYPE_ELEMENT.comment) {
        switch (type_interaction) {
            case TYPE_INTERACTION.like:
                textNotification = {
                    title: 'New Like',
                    body: ` ${sender.username}  has liked your comment.!`,
                    link: `/content/${content_id?._id}`
                }
                break;
            case TYPE_INTERACTION.comment:
                textNotification = {
                    title: 'Reply to Your Comment',
                    body: ` ${sender.username}  has replied to your comment. Don't miss the response!`,
                    link: `/content/${content_id?._id}`
                }
                break;
        }
    } else {
        return null
    }


    return (
        <>
            <TimelineItem sx={{ paddingRight: '1px' }}>

                <TimelineSeparator>
                    <TimelineConnector />
                    {
                        <TimelineDot variant="outlined" sx={{ padding: '0px', borderStyle: 'none' }}>
                            <Avatar {...stringAvatar(sender.username)} onClick={() => gotoProfile(sender)} />
                        </TimelineDot>
                    }
                    <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent sx={{ py: '12px', px: 1 }}>
                    <Typography variant="subtitle2" component="span">
                        {'@' + sender.username}
                        {
                            is_readed == false ? (
                                <Chip label="NEW" className='mx-2' color="primary" size="small" />
                            ) : (null)
                        }
                    </Typography>
                    <Typography variant="caption" display="block" >
                        {ChangeFormatDate(created_at)}
                    </Typography>

                    <Typography variant="body2" color="text.secondary" onClick={() => {
                        if(!response.isLoading){
                            goToLink(textNotification?.link)
                        }
                    }}>
                        {textNotification?.body}
                    </Typography>
                    {
                        response.isError ? (<ErrorMessage message={response.error} />):(null)
                    }





                </TimelineContent>
            </TimelineItem>
            {/*<Box mb={2}>
    <Card sx={{ mb: 2 }}>      
      <CardHeader
        avatar={<Avatar sx={{ bgcolor: red[500] }}>{author.username.charAt(0)}</Avatar>}
        title={'@'+author.username}
        subheader={created_at.toLocaleString()}
      />
      <CardContent>
        <Typography variant="body2" color="text.secondary">
          {text}
        </Typography>
      </CardContent>
      <CardActions disableSpacing>
        <IconButton onClick={handleExpandClick} aria-expanded={isExpanded} aria-label="ver respuestas">
          <CommentIcon />
        </IconButton>
        <Typography variant="caption" color="text.secondary">
          {likes} likes
        </Typography>
        {isTopLevel && (
                <IconButton size="small">
                  <Favorite fontSize="small" />
                </IconButton>
          )}
        <IconButton aria-label="eliminar" onClick={handleRemoveClick} sx={{ ml: "auto"}}>
          <DeleteIcon sx={{ color: pink[500] }} />
        </IconButton>
        <IconButton aria-label="expandir" onClick={handleExpandClick} sx={{ ml: "auto", transform: isExpanded ? "rotate(180deg)" : "rotate(0deg)", transition: (theme:any) => theme.transitions.create("transform") }}>
          <ExpandMoreIcon />
        </IconButton>
      </CardActions>
      <Collapse in={isExpanded} timeout="auto" unmountOnExit>
        
        {CommentQueryStatus}
        {
          result.isSuccess ? (            
            result.data.comments.map((reply:any) => (
              <Comment key={reply.id} {...reply} idContent={idContent} level={level + 1} />
            ))
          ):(
            
            null
          )
        }
        <CardContent>
        <CommentInput idContent={idContent} idComment={_id} onNewData={()=>{
            trigger({page, idComment:String(_id)})
          }} />
        </CardContent>
      </Collapse>
    </Card>
    </Box>
      */}
        </>
    );
}

export default NewsItem;