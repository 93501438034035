import React from "react";
import "../../styles/birdsAnimation.css";

interface BirdProps {
  animationDuration: number;
  animationDelay: number;
  flyCycleDelay: number;
  startPosition: {
    top: string;
    left: string;
  };
}

const Bird: React.FC<BirdProps> = ({ animationDuration, animationDelay, flyCycleDelay, startPosition }) => {
    return (
      <div
        className="bird-container"
        style={{
          animationDuration: `${animationDuration}s`,
          animationDelay: `${animationDelay}s`,
          animationName: "fly-right-one",
          top: startPosition.top,
          left: startPosition.left,
        }}
      >
        <div className="bird" style={{ animationDelay: `${flyCycleDelay}s` }}></div>
      </div>
    );
  };

export default Bird;
