import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Feed from '../../Feed';
import { increment } from '../../_redux/slices/counter/counter.slice';
import PageViewTracker from '../../Statistics/PageViewTracker';
import { Link } from 'react-router-dom';
import EditorComponent from '../../_components/editor';
import StoryList from '../../Feed/storyList';



const ContentScreen = () => {

    /*
        const [inputValue, setInputValue] = useState("");
        
    
        const counter = useSelector((state:any) => state.counter);
    
        const dispatch = useDispatch();
    
        const submit = (e:any) => {
            e.preventDefault();
            dispatch(increment(Number(inputValue)));
        };
    
    
        return (
            <>
                Counter: {counter}
                <br />
                <form onSubmit={submit}>
                    <input
                        type="text"
                        placeholder="cuanto quieres incrementar"
                        value={inputValue}
                        onChange={(e) => setInputValue(e.target.value)}
                    />
                    <button>Submit</button>
                </form>
                <hr />
    
            </>
        );
        */

    return (
        <>

            <div className='grid grid-cols-1 md:grid-cols-9' >

                <div className='hidden md:block'>
                    <div className='m-2'>
                        <img className="w-48 max-w-xs" src="../_assets/logoBlack.png" alt="Quotless" />
                        <h1 className=" text-center text-6xl font-semibold text-gray-800  font-lora hidden">Quotless</h1>
                    </div>
                    
                </div>
                <div className='hidden md:block col-start-3 mt-14'>
                    <div className='w-full flex justify-end'>
                        <EditorComponent/>
                    </div>
                </div>

                <div className='col-start-4 col-span-3' >
                    <StoryList/>
                </div>
                <div className='hidden md:block'></div>
            </div>
        </>
    )
}



export default ContentScreen;