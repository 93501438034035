import React, { useState, useEffect } from 'react';
import { Routes, Route, useParams } from 'react-router-dom';
import ErrorMessage from '../../shared/components/ErrorMessage'
import PremiumIcon from '../../shared/components/PremiumIcon'
import { useGetAuthorQuery } from '../../_redux/slices/API/author.service'
import { useAddSubsToAuthorMutation, useRemoveSubsToAuthorMutation } from '../../_redux/slices/API/author.service'
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Loading from '../../shared/components/Loading'
import PaymentPremium from '../../PaymentPremium'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useNavigate } from "react-router-dom";

import PageViewTracker from '../../Statistics/PageViewTracker';
import ItemListContent from './itemListContent';
const AuthorProfileScreen = (props: any) => {
	const navigate = useNavigate();
	const [addSubsToAuthor, responseSub] = useAddSubsToAuthorMutation()
	const [removeSubsToAuthor, responseUnSub] = useRemoveSubsToAuthorMutation()
	const [page, setPage] = useState(1);
	const [feedContent, setFeedContent] = useState([])
	let { id } = useParams();

	const {
		data: contents,
		isLoading,
		isSuccess,
		isError,
		error,
		isFetching,
		refetch
	} = useGetAuthorQuery({ id: String(id), page })

	useEffect(() => {
		setFeedContent([])
	}, [])

	useEffect(() => {
		if (contents)
			if (contents.contents.length > 0) {
				setFeedContent((prevContent: any) => prevContent.concat(...contents.contents))
			}

	}, [contents])

	const goToPreview = (idContent: string) => {
		return navigate("/content/" + idContent, {state:{userId:id}})
	}
	const handleMore = () => {
		if (!isFetching) {
			if (contents.contents.length > 0) {
				setPage((prevPage: any) => prevPage + 1);
			}
		}
	};
	const subsAction = () => {
		addSubsToAuthor(id)
			.unwrap()
			.then(() => {
				//return navigate("/profile")
				refetch()
			})
			.catch((error: any) => {
				console.log(error, 'ERROR/// :(')

			})
	}

	const unsubsAction = () => {
		removeSubsToAuthor(id)
			.unwrap()
			.then(() => {
				//return navigate("/profile")
				refetch()

			})
			.catch((error: any) => {
				console.log(error, 'ERROR/// :(')
			})
	}

	let infoComponent
	if (responseSub.isLoading || responseUnSub.isLoading || isLoading) {
		infoComponent = (<Loading />)
	} else if (responseSub.isError || responseUnSub.isError || isError) {
		if (responseSub.isError) {
			infoComponent = (<ErrorMessage message={responseSub.error} />)
		} else if (responseUnSub.isError) {
			infoComponent = (<ErrorMessage message={responseUnSub.error} />)
		} else {
			infoComponent = (<ErrorMessage message={error} />)
		}
	}



	let tagContent
	if (isLoading) {
		tagContent = (
			<Loading />
		)
	} else if (isSuccess) {
		tagContent = (
			<>
				<Stack spacing={2} className={'p-2 mb-14'}>
					<div className={'flex flex-col justify-center mt-4'}>
						<p className={'text-xl text-center font-bold'}>Author</p>

						<p className={'text-2xl text-center font-light'}>
							@{contents.author.username}
						</p>

						<Box sx={{ p: 3 }} className={' flex justify-center'}>
							{infoComponent}
							{
								contents.subscribed ? (

									<Button
										onClick={unsubsAction}
										disabled={isFetching || responseSub.isLoading || responseUnSub.isLoading}
										variant="contained"
									>
										{(isFetching || responseSub.isLoading || responseUnSub.isLoading) ? 'Loading...' : 'Unfollow'}
									</Button>
								) :
									(
										<Button
											onClick={subsAction}
											disabled={isFetching || responseSub.isLoading || responseUnSub.isLoading}
											variant="contained"
										>
											{(isFetching || responseSub.isLoading || responseUnSub.isLoading) ? 'Loading...' : 'Follow'}
										</Button>
									)
							}
						</Box>

					</div>
					<Box sx={{ p: 3 }}>
						<Typography>

							<div className="flex flex-col space-y-4">

								{
									feedContent.map((item: any) => (
										<ItemListContent key={item._id} item={item} goToPreview={goToPreview}/>
									))
								}
								<Button variant="contained"
									onClick={handleMore}
									disabled={isFetching || responseSub.isLoading || responseUnSub.isLoading}
								>
									{isFetching ? 'Loading...' : 'Load more'}
								</Button>
							</div>
						</Typography>
					</Box>

				</Stack>


			</>
		)
	} else if (isError) {
		tagContent = (
			<ErrorMessage message={error}></ErrorMessage>
		)
	}
	return <div>
		                    <div className='grid grid-cols-1 md:grid-cols-9' >
                    
                    <div className='hidden md:block'>
                    <div className='m-2'>
                        <img className="w-48 max-w-xs" src="../_assets/logoBlack.png" alt="Quotless" />
                            <h1  className=" text-center text-6xl font-semibold text-gray-800  font-lora hidden">Quotless</h1>
                        </div>
                    </div>            
           
                    <div className='col-start-4 col-span-3' >                
                    {tagContent}
                    </div>
                    <div className='hidden md:block'></div>
                    </div>
		
		</div>

}

export default AuthorProfileScreen;