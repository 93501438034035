import React from 'react';
import Box from '@mui/material/Box';
import BottomNavigation from '@mui/material/BottomNavigation';
import { pink } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
const Footer = () => {

    return (
        <>

  {/* Footer */}
  <footer className="mt-auto">
    <div className='w-full flex justify-center '>
      <Box sx={{ width: '60vw', display: 'flex', alignContent: 'center', justifyContent: 'space-around', flexWrap: 'wrap' }}>
        <div className='font-lora text-lg font-medium'>
          <a href='/about.html'>About</a>
        </div>
        <div className='font-lora text-lg font-medium'>
          <a href='/privacypolicy.html'>Privacy Policy</a>
        </div>
        <div className='font-lora text-lg font-medium'>
          <a href='/termsandconditions.html'>Terms and Conditions</a>
        </div>

        <div className='flex flex-wrap text-center align-middle justify-center'>
          <p className='font-lora text-lg font-medium'>Connect with us <FavoriteIcon fontSize="inherit" sx={{ color: pink[500] }} />: </p>
          <Link target="_top" rel="noopener noreferrer" href={`mailto:quotline.devs@outlook.es`} underline="always">
            <p className='font-lora text-lg'>quotline.devs@outlook.es</p>
          </Link>
        </div>
      </Box>
    </div>
    <div className='w-full flex justify-center mt-5 '>
      <div className='font-lora text-base font-bold'>Quotless © 2024</div>
    </div>
  </footer>



        </>
    );
}



export default Footer;