import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
// Make TS accept the existence of our window.__ENV object - defined in index.html:
interface WindowWithEnv extends Window {
    __ENV?: {
        backendURL: string, // REACT_APP_BACKEND_URL environment variable
        sandbox: "true" | "false", // REACT_APP_SANDBOX_SDK environment variable - string, not boolean!
    }
}
const _window: WindowWithEnv = window;
const backendURL = _window.__ENV && _window.__ENV.backendURL;

export const apiNewsSlice = createApi({
    reducerPath: 'apiNewsSlice',
    baseQuery: fetchBaseQuery({
        baseUrl: backendURL,
        prepareHeaders: (headers, { getState }: any) => {
            const token = getState().auth.userToken            
            if (token) {
                // include token in req header
                headers.set('authorization', `Bearer ${token}`)
                return headers
            }
        }
    }),
    tagTypes: ['News'],
    endpoints: (builder) => ({
        getNews: builder.query({
            query: ({ page }) => ({
                url: '/news/' + page,
                method: 'GET',
            })
        }),
        getCountWithoutReadNews: builder.query({
            query: ({ }) => ({
                url: '/news/withoutRead',
                method: 'GET',
            })
        }),
        updateNewsToReaded: builder.mutation({
            query: ({id}) => ({
                url: '/news/'+id,
                method: 'PUT',                
                headers: {
                  'Content-type': 'application/json; charset=UTF-8',
                },
              }),
              invalidatesTags: ['News'],
        })
    }),

})
export const {
    useLazyGetNewsQuery ,
    useGetCountWithoutReadNewsQuery,
    useUpdateNewsToReadedMutation
 } = apiNewsSlice