import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
// Make TS accept the existence of our window.__ENV object - defined in index.html:
interface WindowWithEnv extends Window {
    __ENV?: {
      backendURL: string, // REACT_APP_BACKEND_URL environment variable
      sandbox: "true" | "false", // REACT_APP_SANDBOX_SDK environment variable - string, not boolean!
    }
}
const _window: WindowWithEnv = window;
const backendURL = _window.__ENV && _window.__ENV.backendURL;

export const apiSurveySlice = createApi({
          reducerPath: 'apiSurveySlice',
          baseQuery: fetchBaseQuery({
                    baseUrl: backendURL,
                    prepareHeaders: (headers, {getState}: any) => {
                              const token = getState().auth.userToken
                              if(token) {
                                        headers.set('authorization', `Bearer ${token}`);
                                        return headers;
                              }
                    }
          }),
          tagTypes: ['Content'],
          endpoints: (builder) => ({
                    getSurvey: builder.query({
                      query: ({}) => ({
                        url: '/survey/updating',
                        method: 'GET'
                      })
                    }),
                    createSurvey: builder.mutation({
                      query: (payload) => ({
                        url: '/survey/updating',
                        method:'POST',
                        body: payload,
                        headers: {
                          'Content-type': 'application/json; charset=UTF-8',
                        }
                      }),
                      invalidatesTags: ['Content'],
                    }),

          })
})

export const {
          useLazyGetSurveyQuery,
          useCreateSurveyMutation
} = apiSurveySlice;