import { buildCacheCollectionHandler } from '@reduxjs/toolkit/dist/query/core/buildMiddleware/cacheCollection';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

interface WindowWithEnv extends Window {
    __ENV?: {
      backendURL: string, // REACT_APP_BACKEND_URL environment variable
      sandbox: "true" | "false", // REACT_APP_SANDBOX_SDK environment variable - string, not boolean!
    }
}

const _window: WindowWithEnv = window;
const backendURL = _window.__ENV && _window.__ENV.backendURL;

export const apiReportSlice = createApi({
    reducerPath: 'apiReportSlice',
    baseQuery: fetchBaseQuery({
        baseUrl: backendURL,
        prepareHeaders: (headers, { getState } : any) => {
            const token = getState().auth.userToken
            if(token) {
                headers.set('authorization', `Bearer ${token}`);
                return headers;
            }
        }
    }),
    tagTypes: ['Content'],
    endpoints: (builder) => ({
        getReportState: builder.query({
            query: ({}) => ({
                url: "/report/state",
                method: 'GET',
            })
        }),
        getReportReason: builder.query({
            query: ({}) => ({
                url: "/report/reason",
                method: "GET"
            })
        }),
        createContentReport: builder.mutation({
            query: (payload) => ({
                url: "/report/content",
                method: "POST",
                body: payload,
                headers: {
                    'Content-type': 'application/json; charset=UTF-8'
                }
            }),
            invalidatesTags: ['Content'],
        })
    }),

});

export const {
    useGetReportStateQuery,
    useGetReportReasonQuery,
    useCreateContentReportMutation
} = apiReportSlice;