import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';


import './index.css';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";


import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { Provider } from "react-redux";


import Main from './Main';
import ErrorScreen from './Screens/ErrorScreen';
import CreatorScreen from './Screens/CreatorScreen';
import ProfileScreen from './Screens/ProfileScreen';
import DetailScreen from './Screens/DetailScreen';
import AuthorProfileScreen from './Screens/AuthorProfileScreen';
import ContentScreen from './Screens/ContentScreen';
import LoginScreen from './Screens/LoginScreen';
import {store} from './_redux/store';
import PageViewTracker from './Statistics/PageViewTracker';
import ReactGA from "react-ga4";
import NewsScreen from './Screens/NewsScreen';
import MenuCore from './MenuCore';
import EditorMobileScreen from './Screens/EditorMobileScreen';
import PasswordReset from './Screens/PasswordReset';



ReactGA.initialize("G-538KENRS4L"); 

const router = createBrowserRouter([
  {
    path: "/",
    element: <Main />,
    errorElement: <ErrorScreen/>,
    children:[
      {
        path: "",
        element: <ContentScreen/>,
      },
      {
        path: "creator",
        element: <CreatorScreen/>,
      },  
      {
        path: "editorMobile",
        element: <EditorMobileScreen/>,
      },     

      {
        path: "profile",
        element: <ProfileScreen/>,
      },  
      {
        path: "profile/:id",
        element: <AuthorProfileScreen/>,
      },
      {
        path: "content/:idContent",
        element: <DetailScreen/>,
      }, 
      {
        path: "news",
        element: <NewsScreen/>,
      },
    ],
  },
  {
    path:"login",
    element:<LoginScreen/>
  },
  {
    path:"confirmPassword",
    element:<PasswordReset/>
  }
]);


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const AppMain = () => {
  useEffect(() => {
    console.log(window.location.pathname + window.location.search, 'route current :)')
    // Registra la página en Google Analytics al cargar la aplicación
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search, title: window.location.pathname + window.location.search});


  }, []);

  return (
    <React.StrictMode>

    <Provider store={store}>
    
      <RouterProvider router={router}/>
    </Provider>
  </React.StrictMode>
  );
};


root.render(
<AppMain/>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
