import React, { useEffect } from 'react';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import ViewCarouselIcon from '@mui/icons-material/ViewCarousel';
import AddBoxIcon from '@mui/icons-material/AddBox';
import Person2Icon from '@mui/icons-material/Person2';
import FavoriteIcon from '@mui/icons-material/Favorite';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import Feed from '../Feed';
import { Link, Navigate, NavLink, Outlet, redirect, useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Header from '../Account/components/Header';
import { useDispatch } from 'react-redux';
import UniqueVisitorTracker from '../Statistics/UniqueVisitorTracker';
import { amber } from '@mui/material/colors';

import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import { Badge, Box } from '@mui/material';

import BuyMeAcoffeeButton from '../_components/buyMeCoffeeButton';
import { useGetCountWithoutReadNewsQuery } from '../_redux/slices/API/news.service';

const MenuTabItem = ({ children }: any) => {
    return (
        <div style={{ background: 'rgba(0, 0, 0, 0.2)', borderRadius: '100%', padding: '0.8rem', backdropFilter: 'saturate(180%) blur(10px)' }}>
            {children}
        </div>
    )
}

const Main = () => {
    const { currentFeedPage } = useSelector((state: any) => state.app)
    const { userInfo } = useSelector((state: any) => state.auth)
    const [value, setValue] = React.useState('recents');
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    //const usuario = getUser(); // La función getUser() devuelve el usuario si está logueado o null si no lo está.

    const {
        data: newsCount,
        isFetching,
        refetch,
        isLoading,
        isSuccess,
      } = useGetCountWithoutReadNewsQuery({})
    


    useEffect(() => {
        const intervalId = setInterval(() => {
          // Realiza la petición al servidor cada 15 minutos
          refetch();
        }, 15 * 60 * 1000); // 15 minutos en milisegundos
    
        // Limpia el temporizador al desmontar el componente
        return () => clearInterval(intervalId);
      }, [refetch]);


    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };



    const gotoScreen = (screenPath: string) => {
        console.log("goto=" + screenPath)
        navigate(screenPath)
    }



    // show unauthorized screen if no user is found in redux store
    if (!userInfo) {
        return (
            <Navigate to="/login" />
        )
    }



    return (
        <>

            {/* <Header onSignOut={signOut}></Header> */}

            <div id="detail">
                <Outlet />
            </div>
            {
                location.pathname == "/creator" || location.pathname == "/editorMobile" ? (
                    null
                ) : (
                    <>
                        <Paper className='md:hidden' sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, backgroundColor: 'rgba(0,0,0,0) !important' }} elevation={24}>
                            <BottomNavigation value={value} onChange={handleChange}

                                sx={{ background: 'rgba(31, 41, 55, 0.4)', backdropFilter: 'saturate(180%) blur(10px)', boxShadow: '0 -4px 8px rgba(0, 0, 0, 0.1)', padding:'2rem 1rem 2rem 1rem' }}>
                                <BottomNavigationAction
                                    onClick={() => {
                                        gotoScreen("/")
                                    }}
                                    label=""
                                    value="feed"
                                    sx={{ color: amber[200] }}

                                    icon={
                                        <MenuTabItem>
                                            <ViewCarouselIcon />
                                        </MenuTabItem>
                                    }
                                />

                                <BottomNavigationAction
                                    onClick={() => {
                                        gotoScreen("/editorMobile")
                                    }}
                                    label=""
                                    value="add"
                                    sx={{ color: amber[200] }}
                                    icon={
                                        <MenuTabItem>
                                            <AddBoxIcon />
                                        </MenuTabItem>
                                    }
                                />

                                <BottomNavigationAction
                                    onClick={() => {
                                        gotoScreen("/profile")
                                    }}
                                    label=""
                                    value="profile"
                                    sx={{ color: amber[200] }}
                                    icon={
                                        <MenuTabItem>
                                            <Person2Icon fontSize='small' />
                                        </MenuTabItem>
                                    }
                                />

                                <BottomNavigationAction
                                    onClick={() => {
                                        gotoScreen("/news")
                                    }}
                                    label=""
                                    value="news"
                                    sx={{ color: amber[200] }}
                                    icon={
                                        <MenuTabItem>
                                            <Badge badgeContent={isSuccess == true ? newsCount.totalNews : 0} max={999} color="error">
                                                <NotificationsActiveIcon fontSize='medium' />
                                            </Badge>
                                        </MenuTabItem>
                                    }
                                />

                            </BottomNavigation>
                        </Paper>
                        <Box className='hidden md:block' sx={{ position: 'fixed', top: '15vh', left: 0 }} >
                            <div className="  font-bold text-gray-800  font-lora   flex flex-col items-center p-8">
                                <nav className="flex flex-col items-start">
                                    <Link to="/" className="text-2xl my-2 flex items-center mx-4"> <ViewCarouselIcon className='mr-2' />Feed</Link>
                                    <Link to="/profile" className="text-2xl my-2 flex items-center mx-4"><Person2Icon className='mr-2' /> Profile</Link>
                                    <Link to="/news" className="text-2xl my-2 flex items-center mx-4"><Badge badgeContent={isSuccess == true ? newsCount.totalNews : 0} max={999} color="error">
                                        <NotificationsActiveIcon className='mr-2' />
                                    </Badge>News</Link>
                                    <BuyMeAcoffeeButton/>
                                </nav>
                            </div>
                        </Box>
                    </>
                )
            }
        </>
    );
}

export default Main;