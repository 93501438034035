import { configureStore, applyMiddleware, isRejectedWithValue, Middleware } from "@reduxjs/toolkit";
// Logger with default options
import logger from 'redux-logger'
import authSlice from "./slices/auth/auth.slice";
import counterSlice from "./slices/counter/counter.slice";
import creatorSlice from "./slices/creator/creator.slice";
import { apiContentSlice } from './slices/API/content.service';
import { apiAuthorSlice } from './slices/API/author.service';
import { apiPaymentSlice } from './slices/API/payment.service';
import { apiSurveySlice } from './slices/API/survey.service';

import { reset } from './slices/auth/auth.slice'
import { apiCommentSlice } from "./slices/API/comment.service";
import { apiStatisticsSlice } from "./slices/API/statistics.service";
import { apiNewsSlice } from "./slices/API/news.service";
import appSlice from "./slices/app/app.slice";
import { apiExternalAuthSlice } from "./slices/API/externalLogin.service";
import { apiAccountSlice } from "./slices/API/account.service";
import { apiReportSlice }  from "./slices/API/report.service"
import editorSlice from "./slices/editor/editor.slice";
import { apiHashtagsSlice } from "./slices/API/hashtags.service";
//import toDosSlice from "./slices/toDos.slice";
//import userNameSlice from "./slices/userName.slice";
const unauthenticatedMiddleware: Middleware = ({
 dispatch
}) => (next) => (action) => {
 if (isRejectedWithValue(action) && (action.payload.status === 401)) {
  console.log('ERROR 401 or 403')
  dispatch(reset({}))
 }

 return next(action);
};


export const store = configureStore({
  reducer: {
    auth:authSlice,
    creator:creatorSlice,
    editor:editorSlice,
    counter: counterSlice,
    app:appSlice,
    [apiContentSlice.reducerPath]: apiContentSlice.reducer,
    [apiAuthorSlice.reducerPath]: apiAuthorSlice.reducer,    
    [apiPaymentSlice.reducerPath]: apiPaymentSlice.reducer,
    [apiCommentSlice.reducerPath]: apiCommentSlice.reducer,
    [apiStatisticsSlice.reducerPath]: apiStatisticsSlice.reducer,
    [apiNewsSlice.reducerPath]: apiNewsSlice.reducer,
    [apiExternalAuthSlice.reducerPath]:apiExternalAuthSlice.reducer,
    [apiAccountSlice.reducerPath]:apiAccountSlice.reducer,
    [apiSurveySlice.reducerPath]: apiSurveySlice.reducer,
    [apiReportSlice.reducerPath]: apiReportSlice.reducer,
    [apiHashtagsSlice.reducerPath]: apiHashtagsSlice.reducer
    //toDos: toDosSlice,
    //userName: userNameSlice
  },
  middleware:(getDefaultMiddleware) => getDefaultMiddleware()
  .concat(logger)
  .concat([
     unauthenticatedMiddleware
   ])
  .concat(apiContentSlice.middleware)
  .concat(apiAuthorSlice.middleware)
  .concat(apiPaymentSlice.middleware)
  .concat(apiCommentSlice.middleware)
  .concat(apiStatisticsSlice.middleware)
  .concat(apiNewsSlice.middleware)
  .concat(apiExternalAuthSlice.middleware)
  .concat(apiHashtagsSlice.middleware)
  .concat(apiAccountSlice.middleware)
  .concat(apiSurveySlice.middleware)
  .concat(apiReportSlice.middleware)
  .concat(apiHashtagsSlice.middleware)
});


export type RootState = ReturnType<typeof store.getState>;

