import { createSlice } from "@reduxjs/toolkit";

// Cambiamos mySlice por el nombre de nuestro slice (usersSlice, toDosSlice...)
const counterSlice = createSlice({
  name: "counter",
  initialState: 2,
  reducers: {
    increment: (state, action) => {
      // setCounter(counter + 1)
      // action.payload = inputValue
      // setCounter(inputValue)
      return state + action.payload;
    }
  }
});

export const { increment } = counterSlice.actions;

export default counterSlice.reducer;
