import React, { useState, useEffect, useRef } from 'react';

import { Link, useNavigate } from 'react-router-dom';

import FontsData from '../../_entities/FontsData'
import FontColor from '../../_entities/FontColor'
import BgColor from '../../_entities/BgColor'
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import SmsIcon from '@mui/icons-material/Sms';
import CommentIcon from '@mui/icons-material/Comment';
import IconButton from '@mui/material/IconButton';
import { pink, grey } from '@mui/material/colors';
import Chip from '@mui/material/Chip';
import PremiumIcon from '../../shared/components/PremiumIcon'
import TextTruncate from 'react-text-truncate'; // recommend
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import Badge from '@mui/material/Badge';
import CommentView from '../../CommentView';
import CommentWithBadgeIcon from '../../CommentView/components/CommentWithBadgeIcon';
import OptionsIcon from '../../ReportView/components/OptionsIcon';
import { Button } from '@mui/material';
import { hexToRgba } from '../../shared/utils/number.util';
import { ChangeFormatDate } from '../../shared/utils/date.util';
import ItemMenuContainer from './ItemMenuContainer';
import BuyMeAcoffeeButtonMobile from '../../_components/buyMeCoffeeButtonMobile';


const LinkToCompleteView = (props: any) => {

  const {
    item
  } = props
  return (
    <>
      {item.content.isPremium && item.nLettersPremium != undefined && item.nLettersPremium != null ?
        (<Chip label={item.nLettersPremium + ' letters premium'} color="primary" size="small" variant="outlined" />) : null
      }
    </>
  )
}

const ContentItem = (props: any) => {

  const {
    item,
    gotoProfile,
    removeFavoriteAction,
    addFavoriteAction
  } = props
  const navigate = useNavigate();
  const [isTruncated, setIsTruncated] = useState(false);
  const content = useRef<any>(null)
  const containerRef = useRef<any>(null);
  const contentRef = useRef<any>(null);
  useEffect(() => {
    const containerHeight = containerRef.current.offsetHeight;
    const contentHeight = contentRef.current.scrollHeight;
    setIsTruncated(contentHeight > containerHeight);
  }, [item.content.contentText]);


  function verifyColorFont(colorF: string, colorBg: string) {
    if (colorF == '#000000' && colorBg == '#000000') return '#FAF7F6'
    return colorF
  }

  function stringToColor(string: string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

  function stringAvatar(name: string) {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      //children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
      children: `${name[0]}${name[Math.round(name.length / 2)]}`,
    };
  }


  const goToPreview = (idContent: string) => {
    return navigate("/content/" + idContent, { state: {} })
  }

  return (
    <div ref={content} className='sm:w-screen md:w-full  md:py-4' >
      <figure style={{ backgroundColor: BgColor[item.content.bgColor] }}
        className={"md:border md:border-gray-300 custom-height-content shadow-inner drop-shadow-lg   sm:rounded-none md:rounded-3xl relative  md:flex flex items-center overflow-hidden  bg-slate-100  p-8 md:p-0 dark:bg-slate-800"}
      >
        <div className="absolute top-0 right-0 rounded-t-md bg-opacity-30 bg-blur-md bg-gray-800 text-white p-4 rounded-md shadow-md">
          <p className="text-sm">
            <span className=" font-freehand  tracking-widest">{ChangeFormatDate(item.content.created_at)}</span>
          </p>
        </div>
        {item.content.isPremium ?
          (
            <div className=' absolute top-3 left-3'>
              <PremiumIcon />
            </div>
          ) : null
        }




        <div className=" pt-6 md:p-8 text-center md:text-left space-y-4">
          {
            item.content.titleText != null && item.content.titleText != undefined ? (
              <h2 className={'text-4xl ' + FontsData[item.content.titleFont]}
                style={{ color: verifyColorFont(FontColor[item.content.titleColor], BgColor[item.content.bgColor]) }}>
                {item.content.titleText}
              </h2>
            ) :
              (null)
          }

          <blockquote>
            <p className={" text-3xl font-medium " + FontsData[item.content.contentFont]}
              style={{ color: verifyColorFont(FontColor[item.content.contentColor], BgColor[item.content.bgColor]) }}>
              {/* <TextTruncate
                                line={8}
                                element="span"
                                truncateText="…"
                                text={item.content.contentText}                                                                                                
                                textTruncateChild={<Link to={"/content/"+item.content._id} className='text-blue-600'>Read on</Link>}
                            /> */}

              <div ref={containerRef} className={" h-72 flex justify-center  overflow-hidden " + isTruncated ? '' : 'items-center'}>
                <p ref={contentRef} style={{ color: verifyColorFont(FontColor[item.content.contentColor], BgColor[item.content.bgColor]) }} className={" text-2xl font-medium leading-snug text-gray-700   line-clamp-10 " + FontsData[item.content.contentFont]}>
                  {item.content.contentText}
                </p>
              </div>
              <LinkToCompleteView item={item} />
            </p>
            {isTruncated && (
              <Button className='text-blue-600' onClick={() => goToPreview(item.content._id)}>Read on</Button>
            )}
          </blockquote>
          {
            item.content.authorText != null && item.content.authorText != undefined ? (
              <figcaption className={"text-2xl font-medium " + FontsData[item.content.authorFont]}>
                <div style={{ color: verifyColorFont(FontColor[item.content.authorColor], BgColor[item.content.bgColor]) }}>
                  {item.content.authorText}
                </div>
              </figcaption>
            ) : (null)
          }


          <Stack direction="column" className={'absolute z-50 bottom-32 right-2 '} spacing={3} justifyContent="center" alignItems="center">

            <Avatar {...stringAvatar(item.content.createdBy.username)} onClick={() => gotoProfile(item)} />

            <ItemMenuContainer>
              {
                item.isFavorite ?
                  <Badge color="primary" overlap="circular" anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }} badgeContent={item.content.likes} max={999}>
                    <IconButton onClick={() => removeFavoriteAction()(item.content._id)} aria-label="favorite" >
                      <FavoriteIcon fontSize="inherit" sx={{ color: pink[500] }} />
                    </IconButton>
                  </Badge> :
                  <Badge color="primary" overlap="circular" anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }} badgeContent={item.content.likes} max={999}>
                    <IconButton onClick={() => addFavoriteAction()(item.content._id)} aria-label="favoriteBorder">
                      <FavoriteBorderIcon fontSize="inherit" sx={{ color: pink[500] }} />
                    </IconButton>
                  </Badge>
              }
            </ItemMenuContainer>
            <ItemMenuContainer>

              <CommentWithBadgeIcon item={item.content} />
            </ItemMenuContainer>
            <ItemMenuContainer>

              <OptionsIcon item={item.content} />
            </ItemMenuContainer>
            <ItemMenuContainer>

              <BuyMeAcoffeeButtonMobile/>
            </ItemMenuContainer>
          </Stack>
        </div>

        <div className='absolute bottom-14 left-2'>
          <div className='flex'>
            {item.content.tags ? item.content.tags.map(
              (tag: any) => (
                <div className=' '>
                  <Chip label={tag} color="primary" size="small" variant="outlined" />
                </div>)
            ) : (null)
            }
            {item.content.hashtags ? item.content.hashtags.map(
              (tag: any) => (
                <div className=' '>
                  <Chip label={tag} color="primary" size="small" variant="outlined" />
                </div>)
            ) : (null)
            }
          </div>
        </div>
      </figure>
    </div>

  )
}

export default ContentItem