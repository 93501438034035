import React from 'react';


const ErrorScreen = () => {
    return ( 
        <>
            Error Screen
        </>
     );
}
 


export default ErrorScreen;