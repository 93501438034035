import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { MentionsInput, Mention } from 'react-mentions'
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { Link } from 'react-router-dom';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import Tabs from '@mui/material/Tabs';
import BgColor from '../../_entities/BgColor';
import Tab from '@mui/material/Tab';
import Chip from '@mui/material/Chip';
import FontsData from '../../_entities/FontsData';
import { useDispatch, useSelector } from 'react-redux';
import { useLazyGetHashtagsSuggestionsByUsageQuery } from '../../_redux/slices/API/hashtags.service';
import { ALIGN_TEXT_VALUES, ContentItem, DECORATION_LINE_TEXT_VALUES, Editor, ITALIC_TEXT_VALUES, SIZE_TEXT_VALUES, WEIGHT_TEXT_VALUES, changeBackgroundColor, changeFocus, changeFont, changeFontColor, newAfterParagraph, newBeforeParagraph, removeParagraph, write, setLanguage } from '../../_redux/slices/editor/editor.slice';
import SendIcon from '@mui/icons-material/Send';
import FontColor from '../../_entities/FontColor';
import ToolBarMobileEditor from './toolBarMobile';
import Button from '@mui/material/Button';
import ISO6391 from 'iso-639-1';
import './editor.css'

import { useState } from 'react';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SettingsIcon from '@mui/icons-material/Settings';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import InputBox from './inputBox';
import InputLine from './inputline';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { useAddNewContentMutation } from '../../_redux/slices/API/content.service';
import { useNavigate } from 'react-router-dom';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="down" ref={ref} {...props} />;
});

export default function EditorMobileComponent() {

    const languages_names = ISO6391.getAllNames()
    const [languageInput, setLangageInput] = React.useState('');
    const { content,
        bgColor,
        language,
        focusIn } = useSelector((state: any) => state.editor)
    const [addNewContent, response] = useAddNewContentMutation()
    const navigate = useNavigate();
    const dispatch = useDispatch()







    const handleChangeFont = (event: any, newValue: number) => {


        dispatch(changeFont(newValue));

    }






    const getContentInfoFont = () => {
        return content[focusIn].font
    }

    const getContentInfoBgColor = () => {
        if (bgColor == null) {
            return 0
        }
        return bgColor
    }

    const getContentInfoFontColor = () => {
        return content[focusIn].color
    }

    const handleChangeBgColor = (event: React.SyntheticEvent, newValue: number) => {
        dispatch(changeBackgroundColor(newValue))
    };

    const handleChangeFontColor = (event: React.SyntheticEvent, newValue: number) => {


        dispatch(changeFontColor(newValue));


    }

    const getContentLanguage = ()  => {
        return ISO6391.getName(language);
    }

    const setContentLanguage = (event: any, newValue: string | null) => {
        if(newValue){
            dispatch(setLanguage(ISO6391.getCode(newValue)));
        }
        
    }


    const submitNewContent = () => {
        addNewContent({ content, bgColor, language })
            .unwrap()
            .then(() => {
                return navigate("/profile")
            })
            .catch((error: any) => {
                console.log(error, 'ERROR/// :(')
            })
    }

    const handleCloseEditor = () => {
        navigate(-1)
    }
    return (
        <div>



            <ToolBarMobileEditor />


            <figure style={{ backgroundColor: BgColor[bgColor] }} className={" rounded rounded-xl p-4"}>
                <div>

                    {
                        content.map((item: ContentItem, idx: number) => (


                            <InputLine item={item} idx={idx} key={idx} />

                        ))
                    }


                </div>
            </figure>


            <DialogActions className='bg-amber-50'>
                <div className='w-full'>
                    <Tabs>
                    <Box sx={{ width: '100%' }}>
                        <Stack
                            direction="row"
                            spacing={2}
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Autocomplete
                                
                                value={getContentLanguage()}
                                onChange={setContentLanguage}
                                inputValue={languageInput}
                                onInputChange={(event, newLanguageInput) => {
                                    setLangageInput(newLanguageInput)
                                }}
                                id='content-language-selector'
                                options={languages_names}
                                sx={{ width: 300 }}
                                renderInput={(params) => <TextField {...params} label="Content language"/>}
                                />

                        </Stack>
                    </Box>
                    </Tabs>
                    <Tabs
                        value={getContentInfoBgColor()}
                        onChange={handleChangeBgColor}
                        variant="scrollable"
                        scrollButtons="auto"
                        aria-label="scrollable auto tabs example"
                    >
                        {
                            BgColor.map((bgcolor, idx) => {
                                return (
                                    <Tab key={idx} label={
                                        <Chip label="-" style={{ backgroundColor: bgcolor, borderRadius: '5px' }} />

                                    } className="tab-color-width" />
                                )
                            })
                        }


                    </Tabs>
                    <Tabs
                        value={getContentInfoFont()}
                        onChange={handleChangeFont}
                        variant="scrollable"
                        scrollButtons="auto"
                        aria-label="scrollable auto tabs example"
                    >
                        {
                            FontsData.map((font, idx) => {
                                return (
                                    <Tab key={idx} label={

                                        <h3 className={font}>Hello</h3>
                                    } />
                                )
                            })
                        }

                    </Tabs>
                    
                    <Tabs
                        value={getContentInfoFontColor()}
                        onChange={handleChangeFontColor}
                        variant="scrollable"
                        scrollButtons="auto"
                        aria-label="scrollable auto tabs example"
                    >
                        {
                            FontColor.map((fnColor) => {
                                return (
                                    <Tab label={
                                        <Chip label="-" style={{ backgroundColor: fnColor }} />

                                    } className="tab-color-width" />
                                )
                            })
                        }
                    </Tabs>
                    <div className='w-full flex justify-center m-4' >
                        <Button variant="outlined" onClick={handleCloseEditor} startIcon={<ArrowBackIosIcon />}>
                            Back
                        </Button>
                        <Button variant="contained" onClick={submitNewContent} endIcon={<SendIcon />}>
                            Send
                        </Button>
                    </div>
                </div>
            </DialogActions>






        </div>
    );
}



