import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

type AuthResult = {
    data:{
      uid: string,
      username: string,     
      photoURL?:string,
      systemtoken?:string,
      language_preferences?:any[],     
    },
    message:string
  
}

type AuthQuery = {
    payload:{
      token:string}
}
// Make TS accept the existence of our window.__ENV object - defined in index.html:
interface WindowWithEnv extends Window {
    __ENV?: {
        backendURL: string, // REACT_APP_BACKEND_URL environment variable
        sandbox: "true" | "false", // REACT_APP_SANDBOX_SDK environment variable - string, not boolean!
    }
}
const _window: WindowWithEnv = window;
const backendURL = _window.__ENV && _window.__ENV.backendURL;

export const apiExternalAuthSlice = createApi({
  reducerPath: 'apiExternalAuthSlice',
  baseQuery: fetchBaseQuery({
    baseUrl: backendURL    
  }),
  tagTypes: ['authExternal'],
  endpoints: (builder) => ({
    
    getUserExistsForFirebase: builder.mutation<any,any>({
      query: ({payload}) => {
        console.log(payload,'sent')
        return ({
          url: '/firebaseLogin/exists?accessToken='+payload.token,
          method: 'POST',     
          body:{},
          headers: {
              'Content-type': 'application/json'
          }
        })      
      },
      invalidatesTags: ['authExternal'],
    }),
    getUserDataForFirebase: builder.mutation<any,any>({
      query: ({payload}) => {
        console.log(payload,'sent')
        return ({
          url: '/firebaseLogin/userData?accessToken='+payload.token,
          method: 'POST',     
          body:{},
          headers: {
              'Content-type': 'application/json'
          }
        })      
      },
      invalidatesTags: ['authExternal'],
    }),
    signInWithGoogle: builder.mutation<AuthResult,AuthQuery>({
      query: ({payload}) => {
        console.log(payload,'sent')
        return ({
          url: '/google/userData?accessToken='+payload.token,
          method: 'POST',     
          body:{},
          headers: {
              'Content-type': 'application/json'
          }
        })      
      },
      invalidatesTags: ['authExternal'],
    }),
    signInWithFB: builder.mutation<AuthResult,AuthQuery>({
      query: ({payload}) => {
        console.log(payload,'sent')
        return ({
          url: '/fb/userData?accessToken='+payload.token,
          method: 'POST',     
          body:{},
          headers: {
              'Content-type': 'application/json'
          }
        })      
      },
      invalidatesTags: ['authExternal'],
    })
  }),
   
})
export const { 
    useSignInWithGoogleMutation,
    useSignInWithFBMutation,
    useGetUserDataForFirebaseMutation,
    useGetUserExistsForFirebaseMutation
 } = apiExternalAuthSlice