import React, { useState, useEffect, useRef } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

import ContentItem from './ContentItem'


// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import Story from './Story';

const ContentSwiper = (props: any) => {
  const [swiperRef, setSwiperRef] = useState<any>(null);
  const [items, setItems] = useState<any>([]);
  const [currentItem, setCurrentItem] = useState<any>({})
  const {
    list,
    isFetching,
    goToProfile,
    removeFavoriteAction,
    addFavoriteAction,
    addFavResponse,
    removeFavResponse,
    addNewItem
  } = props

  useEffect(() => {
    //if(items.length==0){
    console.log('load list from feedContents')
    addItem(list)
    //}    
  }, [list])

  useEffect(() => {
    console.log('add facvorite action', currentItem)
    if (addFavResponse.isSuccess) {
      replaceWithFavoriteItem(currentItem, true)
      setCurrentItem({})
    }
    if (addFavResponse.isError) {
      replaceWithFavoriteItem(currentItem, false)
      setCurrentItem({})
    }
  }, [addFavResponse])

  useEffect(() => {
    if (removeFavResponse.isSuccess) {
      replaceWithFavoriteItem(currentItem, false)
      setCurrentItem({})
    }
    if (removeFavResponse.isError) {
      replaceWithFavoriteItem(currentItem, true)
      setCurrentItem({})
    }
  }, [removeFavResponse])

  function addItem(item: any) {
    let totalItems = [...items, ...item]
    let resultados = eliminarDuplicadosPorId(totalItems)
    setItems([...resultados]);
    console.log(items, "ITEMS")
  }
  // Función para eliminar duplicados por _id en un array
  function eliminarDuplicadosPorId(array: any[]) {
    const uniqueMap = new Map();
    for (const item of array) {
      uniqueMap.set(item.content._id.toString(), item);
    }
    return Array.from(uniqueMap.values());
  }
  function replaceWithFavoriteItem(item: any, isFavorite: boolean) {
    let idx = items.findIndex((element: any) => item._id == element.content._id)
    let cloneElement = { ...items[idx] }
    cloneElement.isFavorite = isFavorite
    items[idx] = cloneElement
    setItems([...items])
  }



  return (
    <>
      {items.map((item: any, index: any) => (

        <div key={item.content._id} className="snap-end h-full">
          <>

            {

              item.content.editorVersion == null || item.content.editorVersion == undefined ? (
                <>
                  <ContentItem item={item} gotoProfile={goToProfile} addFavoriteAction={
                    () => {
                      setCurrentItem(item.content)
                      return addFavoriteAction
                    }

                  } removeFavoriteAction={
                    () => {
                      setCurrentItem(item.content)
                      return removeFavoriteAction
                    }
                  } />
                </>
              ) : (
                item.content.editorVersion == 1 ? (
                  <>
                    <Story item={item} gotoProfile={goToProfile} addFavoriteAction={
                      () => {
                        setCurrentItem(item.content)
                        return addFavoriteAction
                      }

                    } removeFavoriteAction={
                      () => {
                        setCurrentItem(item.content)
                        return removeFavoriteAction
                      }
                    } />
                  </>
                ) : (null)
              )
            }

          </>

        </div>


      ))}
      {/* 	<Swiper 
            className="mySwiper h-screen"
            style={{zIndex:'0'}} 
            onSwiper={(data:any) => setSwiperRef(data)}
            direction = "vertical"
            slidesPerView={1}
            centeredSlides={true}
            onReachEnd={reachEnd}
            >
      
            {items.map((item:any,index:any) => (
                <SwiperSlide key={item._id}>
                	<ContentItem  item={item} gotoProfile={goToProfile} addFavoriteAction={
                    ()=>{
                      setCurrentItem(item.content)
                      return  addFavoriteAction  
                    }
                    
                  } removeFavoriteAction={
                    ()=>{
                      setCurrentItem(item.content)
                      return  removeFavoriteAction
                    }
                  } />
                </SwiperSlide>
                
            ))}
          
              {isFetching && <div>Loading more...</div>}
                
                

            </Swiper>*/}
    </>
  )
}

export default ContentSwiper