import { createSlice } from "@reduxjs/toolkit";
type FOCUSIN = 'title' | 'content' | 'author' 
export interface ContentInfo{    
    title:{
        text:string | null,
        font:number,
        color:number
    } | null,
    content:{
        text:string | null,
        font:number,
        color:number
    },
    author:{
        text:string | null,
        font:number,
        color:number
    },
    bgColor:number,
    focusIn:FOCUSIN,
    tags:string[],
    language: String,
    isPubiclyVisible: boolean,
    //isPremium:boolean
}
export interface ContentNew{
    loading:boolean,
    contentInfo:ContentInfo | null,    
    error:null | any,
    success:boolean
}


const initialState:ContentNew = {
    loading: false,
    contentInfo: {
        title:{
        text:null,
        font:0,
        color:0
        },
        content:{
        text:null,
        font:0,
        color:0
        },
        author:{
        text:null,
        font:0,
        color:0,
        },
        focusIn:"title",
        bgColor:0,
        language: "",
        isPubiclyVisible: false,
        tags:[],
        //isPremium:false
    },
    error: null,
    success: false,        
}
const contentSlice = createSlice(
    {
        name: 'newcontent',
        initialState,
        reducers: {
            edit:(state:ContentNew, action)=>{                
                state.contentInfo = {...state.contentInfo,...action.payload}
            }
        },
    },    
)


// export actions
export const { edit } = contentSlice.actions
export default contentSlice.reducer