import { Paper, Tabs, Tab, Box, Typography, Badge, Chip, TextField, Stack, ButtonGroup, Button, Autocomplete } from '@mui/material';
import React from 'react';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import Slider from "react-slick";
import { ContentInfo, edit } from '../../_redux/slices/creator/creator.slice';
import { useNavigate } from "react-router-dom";
import ErrorMessage from '../../shared/components/ErrorMessage'
import { useGetTagsQuery } from '../../_redux/slices/API/tag.service'
import { useAddNewContentMutation } from '../../_redux/slices/API/content.service'
import Loading from '../../shared/components/Loading'
import FontsData from '../../_entities/FontsData'
import FontColor from '../../_entities/FontColor'
import BgColor from '../../_entities/BgColor'
import Switch from '@mui/material/Switch';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import { amber } from '@mui/material/colors';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import EditorMobileComponent from '../../_components/editor-mobile';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
const EditorMobileScreen = () => {
  const [addNewContent, response] = useAddNewContentMutation()
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState(0);
  const { contentInfo } = useSelector((state: any) => state.creator)
  const dispatch = useDispatch()
  const navigate = useNavigate();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const submitNewContent = () => {
    addNewContent(contentInfo)
      .unwrap()
      .then(() => {
        return navigate("/profile")
      })
      .catch((error: any) => {
        console.log(error, 'ERROR/// :(')
      })
  }

  let infoComponent
  if (response.isLoading) {
    infoComponent = (<Loading />)
  } else if (response.isError) {
    infoComponent = (<ErrorMessage message={response.error} />)
  }

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleChangeBgColor = (event: React.SyntheticEvent, newValue: number) => {
    dispatch(edit(
      {

        bgColor: newValue

      }
    ))
  };

  const handleChangeFontColor = (event: React.SyntheticEvent, newValue: number) => {
    if (contentInfo == null) {
      return
    }

    if (contentInfo.focusIn == 'title') {
      dispatch(edit({
        title: {
          ...contentInfo.title,
          color: newValue
        }

      }));
    }

    if (contentInfo.focusIn == 'author') {
      dispatch(edit({
        author: {
          ...contentInfo.author,
          color: newValue
        }

      }));
    }

    if (contentInfo.focusIn == 'content') {
      dispatch(edit({
        content: {
          ...contentInfo.content,
          color: newValue
        }

      }));
    }

  }
  const handleChangeIndex = (index: number) => {
    setValue(index);
  };


  const getContentInfoFontColor = (contentInfo: ContentInfo) => {
    console.log(contentInfo)
    if (contentInfo == null) {
      return 0
    }
    if (contentInfo.focusIn == 'title') {
      if (contentInfo.title == null) {
        return 0
      }
      return contentInfo.title.color
    }

    if (contentInfo.focusIn == 'author') {
      if (contentInfo.author == null) {
        return 0
      }
      return contentInfo.author.color
    }

    if (contentInfo.focusIn == 'content') {
      if (contentInfo.content == null) {
        return 0
      }
      return contentInfo.content.color
    }

    return 0
  }

  const getContentInfoBgColor = (contentInfo: ContentInfo) => {
    if (contentInfo == null) {
      console.log("0 ///////////////////////")
      return 0
    }
    console.log(contentInfo.bgColor, "*************")
    return contentInfo.bgColor
  }

  const onFocusTitle = (e: any) => {
    console.log("title focus")
    dispatch(edit({

      focusIn: "title"

    }))
  }

  const onFocusContent = (e: any) => {
    console.log("content focus")
    dispatch(edit({

      focusIn: "content"

    }))
  }

  const onFocusAuthor = (e: any) => {
    console.log("author focus")
    dispatch(edit({

      focusIn: "author"

    }))
  }

  const changeTextTitle = (e: any) => {
    const value = e.target.value
    dispatch(edit({
      title: {
        ...contentInfo.title,
        text: value
      }

    }));
  }

  const changeTextContent = (e: any) => {
    const value = e.target.value
    dispatch(edit({
      content: {
        ...contentInfo.content,
        text: value
      }

    }));
  }

  const changeTextAuthor = (e: any) => {
    const value = e.target.value
    dispatch(edit({
      author: {
        ...contentInfo.author,
        text: value
      }

    }));
  }

  const getContentInfoFont = (contentInfo: ContentInfo) => {
    console.log(contentInfo)
    if (contentInfo == null) {
      return 0
    }
    if (contentInfo.focusIn == 'title') {
      if (contentInfo.title == null) {
        return 0
      }
      return contentInfo.title.font
    }

    if (contentInfo.focusIn == 'author') {
      if (contentInfo.author == null) {
        return 0
      }
      return contentInfo.author.font
    }

    if (contentInfo.focusIn == 'content') {
      if (contentInfo.content == null) {
        return 0
      }
      return contentInfo.content.font
    }

    return 0
  }

  const handleChangeFont = (event: any, newValue: number) => {
    if (contentInfo == null) {
      return
    }

    if (contentInfo.focusIn == 'title') {
      dispatch(edit({
        title: {
          ...contentInfo.title,
          font: newValue
        }

      }));
    }

    if (contentInfo.focusIn == 'author') {
      dispatch(edit({
        author: {
          ...contentInfo.author,
          font: newValue
        }

      }));
    }

    if (contentInfo.focusIn == 'content') {
      dispatch(edit({
        content: {
          ...contentInfo.content,
          font: newValue
        }

      }));
    }
  }

  /*   const handleChangePremium = (event: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(edit({
        isPremium:event.target.checked
      }));
    }; */

  const onChangeTags = (e: any, value: any | null) => {
    dispatch(edit({

      tags: value

    }))
  }

  const getFocusInText = (content: any) => {
    if (content.focusIn == 'title') {
      return 'For Title'
    } else if (content.focusIn == 'content') {
      return 'For Content'
    } else {
      return 'For Author'
    }
  }



  return (
    <>


      <EditorMobileComponent />





    </>
  );
}


const TagsAutoComplete = (props: any) => {
  const [inputValue, setInputValue] = React.useState('');
  const {
    defaultTags,
    onChangeTags
  } = props;
  const {
    data: tags,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetTagsQuery('tagsList')

  const tagFormat = (data: any) => {
    return data;
  }


  let tagContent
  if (isLoading) {
    tagContent = (
      <Loading />
    )
  } else if (isSuccess) {
    tagContent = (
      <Autocomplete
        multiple
        id="size-small-standard-multi"
        size="small"
        options={tagFormat(tags)}
        getOptionLabel={(option: any) => {
          return option.title
        }}
        onChange={onChangeTags}
        value={defaultTags !== undefined ? defaultTags : null}

        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        renderInput={(params) => {

          return (
            <TextField
              {...params}

              variant="standard"
              label="Size small"
              placeholder="Favorites"
            />
          )
        }
        }
      />
    )
  } else if (isError) {
    tagContent = (
      <ErrorMessage message={error}></ErrorMessage>
    )
  }
  return <div>{tagContent}</div>

}


export default EditorMobileScreen;