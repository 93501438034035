const FontsData = [
              'font-atwriter',
              'font-anton',
              'font-antonio',
              'font-aracnoide',
              'font-bloody',
              'font-bombulate',
              'font-cheap-fire',
              'font-creature',
              'font-flanella',
              'font-freehand',
              'font-horror-hote',
              'font-kanitthin',
              'font-kanitregular',
              'font-kanitblack',
              'font-latothin',
              'font-latoregular',
              'font-latoBlack',
              'font-LibreBaskervilleRegular',
              'font-LibreBaskervilleItalic',
              'font-LibreBaskervilleBold',
              'font-maria',
              'font-N8htmare',
              'font-parisienneRegular',
              'font-poppinsThin',
              'font-poppinsRegular',
              'font-poppinsBlack',
              'font-quicksand',
              'font-sourceserifproLight',
              'font-sourceserifproRegular',
              'font-sourceserifproBlack',
              'font-lora'
]

export default FontsData