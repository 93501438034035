import { createSlice } from "@reduxjs/toolkit";

const initApp = {
    currentFeedPage:1,
    isMenuActive:true,
}

// Cambiamos mySlice por el nombre de nuestro slice (usersSlice, toDosSlice...)
const appSlice = createSlice({
  name: "app",
  initialState: initApp,
  reducers: {
    changeFeedPage: (state, action) => {
      // setCounter(counter + 1)
      // action.payload = inputValue
      // setCounter(inputValue)
       
      console.warn(action.payload.currentFeedPage, 'payload')
      state.currentFeedPage =  action.payload.currentFeedPage;
    },
    toggleMainMenu: (state) => {       
      state.isMenuActive=!state.isMenuActive
      return state;       
    },
  }
});

export const { changeFeedPage, toggleMainMenu } = appSlice.actions;

export default appSlice.reducer;