import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { logout, reset } from '../../_redux/slices/auth/auth.slice';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import ErrorMessage from '../../shared/components/ErrorMessage'

import Loading from '../../shared/components/Loading'
import { useGetAuthorMeQuery, useGetSubsQuery, useGetMeSubsQuery, useRemoveSubsToAuthorMutation } from '../../_redux/slices/API/author.service'
import { useRemoveContentMutation, useGetFavoritesQuery, useRemoveContentToFavoriteMutation } from '../../_redux/slices/API/content.service'
import { useLazyGetSurveyQuery, useCreateSurveyMutation } from '../../_redux/slices/API/survey.service'

import { pink } from '@mui/material/colors';
import CreateIcon from '@mui/icons-material/Create';
import FavoriteIcon from '@mui/icons-material/Favorite';
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Alert, IconButton } from '@mui/material';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import SentimentNeutralIcon from '@mui/icons-material/SentimentNeutral';
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon';

import { useLocation, useNavigate } from "react-router-dom";
import AddReactionIcon from '@mui/icons-material/AddReaction';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';

import PageViewTracker from '../../Statistics/PageViewTracker';
import ItemListMyContent from './itemListMyContent';
import ItemListFavoriteContent from './itemListFavoriteContent';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import { TextField } from '@mui/material';
import { useRemoveAccountMutation } from '../../_redux/slices/API/account.service';
import UpdateProfileInfo from "./updateProfile";

// Dialago imports
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { authFirebase, mapErrorCodeToMessage, signOutFirebase } from '../../firebase';


interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
interface ErrorMessageState {errorCode:string | Number,message:string}

const ProfileScreen = () => {
  const navigate = useNavigate();

  const [errorMessage, setErrorMessage] = useState<null | ErrorMessageState>(null)

  const [openDialogRemoveAccount, setOpenDialogRemoveAccount] = React.useState(false);

  const [openMenuMore, setOpenMenuMore] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);


  const [valueUsernameRemove, setValueUsernameRemove] = React.useState('')


  const [openAlert, setOpenAlert] = React.useState(false);
  const [survey, setSurvey] = React.useState({});

  const [valueMenu, setValueMenu] = React.useState(0);
  const [page, setPage] = useState(1);
  const [pageFav, setPageFav] = useState(1);
  const [pageSubs, setPageSubs] = useState(1);
  const [pageMeSubs, setPageMeSubs] = useState(1);
  const [feedContent, setFeedContent] = useState([])
  const [favContent, setFavContent] = useState([])
  const [subsList, setSubsList] = useState([])
  var [updateProfile, setUpdateProfile] = useState(false);
  const [meSubsList, setMeSubsList] = useState([])
  const [removeContent, responseRemoveContent] = useRemoveContentMutation()
  const [removeFavoriteContent, responseRemoveFavoriteContent] = useRemoveContentToFavoriteMutation()
  const [removeSubs, responseRemoveSubs] = useRemoveSubsToAuthorMutation()

  const [removeUserAccount, responseRemoveUserAccount] = useRemoveAccountMutation()


  const [createUpdatingSurvey, responseCreateUpdatingSurvey] = useCreateSurveyMutation();

  const { loading, userInfo, error, success } = useSelector(
    (state: any) => state.auth
  )

  const [seed, setSeed] = useState(1);

  const [
    trigger,
    result
  ] = useLazyGetSurveyQuery();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));


  const handleRetrieveUpdatingSurvey = async () => {
    if (!result.isLoading && !result.isFetching) {

      const response = await trigger({}, false).then(
        (res) => {
          if (res.isError) {
            setOpenAlert(true);
          } else {
            setOpenAlert(false);
          }
        }
      )

    }
  }



  useEffect(() => {
    handleRetrieveUpdatingSurvey();
  }, [])

  const handleClose = () => {
    setOpenAlert(false);
  };

  const handleChangeMenu = (event: React.SyntheticEvent, newValue: number) => {
    refetchSubs()
    refetchFav()
    refetch()
    setValueMenu(newValue);
  };




  const {
    data: contents,
    isLoading,
    isSuccess,
    isError,
    error: errorContentLoad,
    isFetching,
    refetch
  } = useGetAuthorMeQuery({ page })



  const {
    data: favorites,
    isLoading: isLoadingFav,
    isSuccess: isSuccessFav,
    isError: isErrorFav,
    error: errorFav,
    isFetching: isFetchingFav,
    refetch: refetchFav
  } = useGetFavoritesQuery({ page: pageFav })

  const {
    data: subs,
    isLoading: isLoadingSubs,
    isSuccess: isSuccessSubs,
    isError: isErrorSubs,
    error: errorSubs,
    isFetching: isFetchingSubs,
    refetch: refetchSubs
  } = useGetSubsQuery({ page: pageSubs })

  const {
    data: meSubs,
    isLoading: isLoadingMeSubs,
    isSuccess: isSuccessMeSubs,
    isError: isErrorMeSubs,
    error: errorMeSubs,
    isFetching: isFetchingMeSubs,
    refetch: refetchMeSubs
  } = useGetMeSubsQuery({ page: pageMeSubs })

  const handleMore = () => {
    if (!isFetching) {
      if (contents.contents.length > 0) {
        setPage((prevPage: any) => prevPage + 1);
      }
    }
  };

  const handleMoreFav = () => {
    if (!isFetchingFav) {
      if (favorites.favorites.length > 0) {
        setPageFav((prevPage: any) => prevPage + 1);
      }
    }
  };

  const handleMoreSubs = () => {
    if (!isFetchingSubs) {
      if (subs.subscribers.length > 0) {
        setPageSubs((prevPage: any) => prevPage + 1);
      }
    }
  };

  const handleMoreMeSubs = () => {
    if (!isFetchingMeSubs) {
      if (meSubs.subscribers.length > 0) {
        setPageMeSubs((prevPage: any) => prevPage + 1);
      }
    }
  };


  const goToPreview = (idContent: string) => {
    return navigate("/content/" + idContent, { state: { userId: userInfo.uid } })
  }

  useEffect(() => {
    console.log('mount ./...... profile')
    setFeedContent([])
    setFavContent([])
  }, [])

  useEffect(() => {
    if (contents)
      if (contents.contents.length > 0) {
        setFeedContent((prevContent: any) => prevContent.concat(...contents.contents))
      }

  }, [contents])

  useEffect(() => {

    if (favorites)
      if (favorites.favorites.length > 0) {
        setFavContent((prevContent: any) => prevContent.concat(...favorites.favorites))
      }

  }, [favorites])

  useEffect(() => {

    if (subs)
      if (subs.subscribers.length > 0) {
        setSubsList((prevContent: any) => prevContent.concat(...subs.subscribers))
      }

  }, [subs])

  useEffect(() => {

    if (meSubs)
      if (meSubs.subscribers.length > 0) {
        setMeSubsList((prevContent: any) => prevContent.concat(...meSubs.subscribers))
      }

  }, [meSubs])
  const dispatch = useDispatch()

  const onSignOut = () => {
    signOutFirebase(authFirebase)
      .then(() => {
        // Sign-out successful.
        dispatch(logout({}) as any)
        setErrorMessage(null)
        navigate('/')
      }).catch((error) => {
        // An error happened.
        const errorCode = error.code;
        const message = error.message.replace('Firebase: ', '');
        console.log(message)
        dispatch(reset({}))
        setErrorMessage({
          errorCode,
          message: mapErrorCodeToMessage(errorCode)
        })
      });
  }

  const removeItem = (id: string) => {
    removeContent(id)
      .unwrap()
      .then(() => {
        //return navigate("/profile")
        setFeedContent((prevContent: any) => {
          console.log('add////////////////')
          console.log(prevContent)
          console.log(prevContent.filter((item: any) => item._id != id))
          return prevContent.filter((item: any) => item._id != id)
        })
        refetch()
      })
      .catch((error: any) => {
        console.log(error, 'ERROR/// :(')

      })
  }

  const handleCreateSurvey = (answer: string) => {
    createUpdatingSurvey({
      answer: answer
    }).then(() => {
      handleClose();
    })
  }

  const removeFavoriteItem = (id: string) => {
    removeFavoriteContent(id)
      .unwrap()
      .then(() => {
        //return navigate("/profile")
        setFavContent((prevContent: any) => {
          console.log('add////////////////')
          return prevContent.filter((item: any) => item._id != id)
        })
        refetchFav()
      })
      .catch((error: any) => {
        console.log(error, 'ERROR/// :(')

      })
  }

  const removeSubsItem = (id: string) => {
    removeSubs(id)
      .unwrap()
      .then(() => {
        //return navigate("/profile")
        setSubsList((prevContent: any) => {
          console.log('add////////////////')
          return prevContent.filter((item: any) => item._id != id)
        })
        refetchSubs()
      })
      .catch((error: any) => {
        console.log(error, 'ERROR/// :(')

      })
  }

  const gotoProfile = (item: any) => {
    //TODO: (esta es una version diferente , existen 2 gotoProfile)Pasar esta funcion (gotoProfile) a una archivo de utils o hooks
    console.log('go to profile')

    navigate(`/profile/${item._id}`);

  }


  /**
   * MENU
   */
  const handleToggle = () => {
    setOpenMenuMore((prevOpen) => !prevOpen);
  };



  const handleCloseActionDialogRemoveAccount = (event: Event | React.SyntheticEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpenMenuMore(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpenMenuMore(false);
    } else if (event.key === 'Escape') {
      setOpenMenuMore(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef<any>(openMenuMore);
  React.useEffect(() => {
    if (prevOpen.current === true && openMenuMore === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = openMenuMore;
  }, [openMenuMore]);
  /**
   * END MENU
   */

  /**
   *  DIALOG REMOVE ACCOUNT
   */
  const handleRemoveAccountMenu = (event: Event | React.SyntheticEvent) => {
    setOpenDialogRemoveAccount(true);
    handleCloseActionDialogRemoveAccount(event)
  };


  const handleCloseDialogRemoveAccount = () => {
    setOpenDialogRemoveAccount(false);
  };

  /**
   * END DIALOG REMOVE ACCOUNT
   */

  const removeAccount = () => {
    removeUserAccount({})
      .unwrap()
      .then(() => {
        onSignOut()
      })
      .catch((error: any) => {
        console.log(error, 'ERROR/// :(')

      })
  }

  let removeAccountResponseComponent

  if (responseRemoveUserAccount.isLoading) {
    removeAccountResponseComponent = (<Loading />)
  } else if (responseRemoveUserAccount.isError) {
    removeAccountResponseComponent = (<ErrorMessage message={responseRemoveUserAccount.error} />)
  }

  let infoComponent
  if (responseRemoveContent.isLoading ||
    responseRemoveFavoriteContent.isLoading ||
    responseRemoveSubs.isLoading) {
    infoComponent = (<Loading />)
  } else if (responseRemoveContent.isError ||
    responseRemoveFavoriteContent.isError ||
    responseRemoveSubs.isError) {
    if (responseRemoveContent.isError) {
      infoComponent = (<ErrorMessage message={responseRemoveContent.error} />)
    } else if (responseRemoveFavoriteContent.isError) {
      infoComponent = (<ErrorMessage message={responseRemoveFavoriteContent.error} />)
    } else {
      infoComponent = (<ErrorMessage message={responseRemoveSubs.error} />)
    }
  }


  let tagContent
  if (isLoading || isLoadingFav || isLoadingSubs || isLoadingMeSubs) {
    tagContent = (
      <Loading />
    )
  } else if (isSuccess && isSuccessSubs && isSuccessMeSubs && isSuccessFav) {
    tagContent = (
      <>
      
        <Stack spacing={2} className={'p-2 mb-14 md:w-auto w-screen'}>
          <div className={'flex flex-col justify-center mt-4'}>
            <div className='flex justify-end'>

              <IconButton
                aria-label="more"
                ref={anchorRef}
                id="long-button"
                aria-controls={openMenuMore ? 'long-menu' : undefined}
                aria-expanded={openMenuMore ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
              >
                <MoreVertIcon />
              </IconButton>
              <Popper
                open={openMenuMore}
                anchorEl={anchorRef.current}
                role={undefined}
                placement="bottom-start"
                transition
                disablePortal
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin:
                        placement === 'bottom-start' ? 'left top' : 'left bottom',
                    }}
                  >
                    <Paper>
                      <ClickAwayListener onClickAway={handleCloseActionDialogRemoveAccount}>
                        <MenuList
                          autoFocusItem={openMenuMore}
                          id="composition-menu"
                          aria-labelledby="composition-button"
                          onKeyDown={handleListKeyDown}
                        >
                          <MenuItem onClick={handleRemoveAccountMenu}>Remove Account</MenuItem>
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>

            </div>
            <p className={'text-xl text-center font-bold'}>
              Me
            </p>
            {userInfo === null ? (
              <></>
            ) : (
              <>
                <p className={'text-2xl text-center font-light'}> @{userInfo.username}</p>

                <Button type="button" className={'mt-4'} onClick={onSignOut}>Sign out</Button>
              </>
            )}

{
                                    errorMessage ?
                                        <Alert variant="outlined" severity="warning">
                                            {errorMessage.message}
                                        </Alert> :
                                        (null)
                                }
          </div>
          <div className='justify-center'>
            <Tabs value={valueMenu} onChange={handleChangeMenu} aria-label="icon tabs example" centered variant="scrollable"
              scrollButtons="auto">
              <Tab icon={<CreateIcon />} aria-label="create" />
              <Tab icon={<FavoriteIcon />} aria-label="favorite" />
              <Tab icon={<EmojiEmotionsIcon />} aria-label="emoji" />
              <Tab icon={<AddReactionIcon />} aria-label="add" />
              <Tab icon={<ManageAccountsIcon />} aria-label="manage" />
            </Tabs>
            <TabPanel value={valueMenu} index={0}>
              <div className="flex flex-col space-y-4">
                {infoComponent}
                {
                  feedContent.map((item: any) => (
                    <ItemListMyContent key={item._id} item={item} goToPreview={goToPreview} removeItem={removeItem} responseRemoveContent={responseRemoveContent} />

                  ))
                }
                <Button variant="contained"
                  onClick={handleMore}
                  disabled={isFetching}
                >
                  {isFetching ? 'Loading...' : 'Load more'}
                </Button>
              </div>
            </TabPanel>
            <TabPanel value={valueMenu} index={1}>
              <div className="flex flex-col space-y-4">
                {infoComponent}
                {
                  favContent.map((item: any) => (
                    <ItemListFavoriteContent key={item._id} item={item} goToPreview={goToPreview} removeFavoriteItem={removeFavoriteItem} responseRemoveFavoriteContent={responseRemoveFavoriteContent} />

                  ))
                }
                <Button variant="contained"
                  onClick={handleMoreFav}
                  disabled={isFetchingFav}
                >
                  {isFetchingFav ? 'Loading...' : 'Load more'}
                </Button>
              </div>
            </TabPanel>
            <TabPanel value={valueMenu} index={2}>
              <div className="flex flex-col space-y-4">
                {infoComponent}
                {
                  subsList.map((item: any) => (
                    <div key={item._id} className="bg-white rounded-lg shadow-lg p-4 w-full">
                      <div className="flex items-center justify-between">
                        <Button
                          onClick={() => removeSubsItem(item._id)}
                          disabled={isFetchingSubs || responseRemoveSubs.isLoading || responseRemoveSubs.isLoading}
                          variant="contained"
                          sx={{ color: pink[500] }}
                        >
                          {(isFetchingSubs || responseRemoveSubs.isLoading || responseRemoveSubs.isLoading) ? 'Loading...' : 'Unfollow'}
                        </Button>

                      </div>
                      <Button onClick={() => gotoProfile(item)} >
                        <p className="text-gray-800 text-lg font-medium mt-2">@{item.username}</p>
                      </Button>
                    </div>
                  ))
                }
                <Button variant="contained"
                  onClick={handleMoreSubs}
                  disabled={isFetchingSubs}
                >
                  {isFetchingSubs ? 'Loading...' : 'Load more'}
                </Button>
              </div>
            </TabPanel>
            <TabPanel value={valueMenu} index={3}>
              <div className="flex flex-col space-y-4">
                {infoComponent}
                {
                  meSubsList.map((item: any) => (
                    <Button key={item._id} onClick={() => gotoProfile(item)} >
                      <div className="bg-white rounded-lg shadow-lg p-4 w-full" >

                        <p className="text-gray-800 text-lg font-medium mt-2">@{item.username}</p>
                      </div>
                    </Button>
                  ))
                }
                <Button variant="contained"
                  onClick={handleMoreMeSubs}
                  disabled={isFetchingMeSubs}
                >
                  {isFetchingMeSubs ? 'Loading...' : 'Load more'}
                </Button>
              </div>
            </TabPanel>
            <TabPanel value={valueMenu} index={4}>
              <div className="flex flex-col space-y-4">
                {infoComponent}
                {
                  <UpdateProfileInfo key={seed} userInfo={userInfo} />
                }

              </div>
            </TabPanel>
          </div>

        </Stack>

        <Dialog open={openDialogRemoveAccount} onClose={handleCloseActionDialogRemoveAccount}>
          <DialogTitle>Remove account</DialogTitle>
          <DialogContent>
            <DialogContentText>
              When you choose to delete your account, please be aware that all your data will be permanently
              erased, and there will be no way to retrieve it in the future. This includes comments, content,
              and any other information associated with your account. By clicking the 'Remove my account' button,
              you are confirming your understanding and agreement with this irreversible action.


            </DialogContentText>

            <div className="bg-white rounded-md p-8 shadow-lg max-w-md mx-auto">

              <p className="text-2xl font-semibold mb-4">Thank you for being part of our community.</p>
              <p className="text-lg mb-6">We hope you enjoyed every shared word. We wish you inspiration and meaningful moments along the way. If you ever wish to return, we'll be here with more stories for you.</p>
              <p className="text-sm">Goodbye for now!</p>

            </div>
            <TextField
              autoFocus
              margin="dense"
              id="username"
              label="Write Username"
              type="text"
              fullWidth
              variant="standard"
              onChange={(event) => {
                setValueUsernameRemove(event.target.value)
              }}
              error={valueUsernameRemove != userInfo.username}
            />

            {removeAccountResponseComponent}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialogRemoveAccount} disabled={responseRemoveUserAccount.isLoading}>Cancel</Button>
            <Button onClick={removeAccount} disabled={valueUsernameRemove != userInfo.username || responseRemoveUserAccount.isLoading || (valueUsernameRemove == '')}>Remove my account</Button>
          </DialogActions>
        </Dialog>
      </>
    )
  }
  return <div>
    <div className='grid grid-cols-1 md:grid-cols-9' >
      <div className='hidden md:block'>
        <div className='m-2'>
          <img className="w-48 max-w-xs" src="../_assets/logoBlack.png" alt="Quotless" />
          <h1 className=" text-center text-6xl font-semibold text-gray-800  font-lora hidden">Quotless</h1>
        </div>
      </div>

      <div className='col-start-4 col-span-3' >
        {tagContent}
      </div>
      <div className='hidden md:block'>

      </div>
    </div>
  </div>
}

export default ProfileScreen;