import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useAddContentToFavoriteMutation, useLazyGetFeedQuery, useRemoveContentToFavoriteMutation } from "../_redux/slices/API/content.service";
import ContentSwiper from "./components/ContentSwiper";
import { changeFeedPage } from "../_redux/slices/app/app.slice";
import zIndex from "@mui/material/styles/zIndex";




// Dialago imports
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { authFirebase, createUserLoginEmailPasswordFirebase } from '../firebase';
import { useAddNewAccountMethodPassMutation, useLazyVerifyAccountCreatedFirebaseQuery } from "../_redux/slices/API/account.service";


import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

function Alert(props: any) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const pageSize = 10; // Cantidad máxima de elementos por página


const StoryList = () => {

  const [feedContent, setFeedContent] = useState<any>([])
  const [page, setPage] = useState(1);
  const dispatch = useDispatch()
  const history = useNavigate();
  const observerRef = useRef<any>(null);
  const { currentFeedPage } = useSelector((state: any) => state.app)


  const [addContentToFavorite, responseAddFavorite] = useAddContentToFavoriteMutation()
  const [removeContentToFavorite, responseRemoveFavorite] = useRemoveContentToFavoriteMutation()


  const [openAlert, setOpenAlert] = useState(false);// colocar a false
  const [newCredentials, setNewCredentials] = useState({
    email: '',
    password: '',
    confirmPassword: ''
  })

  const [errors, setErrors] = useState({ email: '', password: '', confirmPassword: '' });

  const [openSnackSuccess, setOpenSnackSuccess] = useState(false);
  const [openSnackError, setOpenSnackError] = useState(false);


  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));


  const [
    trigger,
    result
  ] = useLazyGetFeedQuery()

  const [
    triggerVerifyAccount,
    resultVerifyAccount
  ] = useLazyVerifyAccountCreatedFirebaseQuery()

  const gotoProfile = (item: any) => {
    history(`/profile/${item.content.createdBy._id}`);
  }

  const fetchMoreData = async () => {
    if (!result.isLoading && !result.isFetching) {
      console.warn("Current feed page: " + currentFeedPage)
      const result = await trigger({ page: currentFeedPage }, false)
      if (result.data.contents) {
        const newItems = result.data.contents
        setFeedContent((prevItems: any) => [...prevItems, ...newItems]);
        if (result.data.page < result.data.lastPage) {
          setPage(currentFeedPage + 1);
        }
      } else {
        setFeedContent([])
      }
    }
  }


  const handleIntersection = async (entries: any) => {
    if (entries[0].isIntersecting) {
      console.log('interceccion, fetching data')
      await fetchMoreData();
    }
  };

  useEffect(() => {

    handleRetrieveUpdatingNewLoginAccount();



    const observer = new IntersectionObserver(handleIntersection);
    observer.observe(observerRef.current);
    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    console.warn('page current changed /// ', page)
    dispatch(changeFeedPage({ currentFeedPage: page }))
  }, [page])

  const addFavoriteAction = async (idContent: string) => {
    await addContentToFavorite(idContent)
  }

  const removeFavoriteAction = async (idContent: string) => {
    await removeContentToFavorite(idContent)
  }




  const handleRetrieveUpdatingNewLoginAccount = async () => {

    // verificar si es un usuario de PI
    // verificar si el usuario no tiene una cuenta en firebase
    // mostrar el modal
    /*if (!result.isLoading && !result.isFetching) {
 
      const response = await trigger({}, false).then(
        (res) => {
          if (res.isError) {
            setOpenAlert(true);
          } else {
            setOpenAlert(false);
          }
        }
      )
 
    }
    */
    const loginWith: string | null = localStorage.getItem('loginWith')
      ? localStorage.getItem('loginWith') : ''

    if (loginWith == 'PI') {
      setOpenAlert(true);
      if (!resultVerifyAccount.isLoading && !resultVerifyAccount.isFetching) {

        const response = await triggerVerifyAccount({}, false).then(
          (res) => {
            if (!res.data.hasFirebaseAccount) {
              setOpenAlert(true);
            } else {
              setOpenAlert(false);
            }
          }
        )

      }

    }
  }




  const handleClose = () => {
    setOpenAlert(false);
  };


  const handleClickSnackbarSuccess = () => {
    setOpenSnackSuccess(true);
  };

  const handleCloseSnackbarSuccess = (event: any, reason: any) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackSuccess(false);
  };



  const handleClickSnackbarError = () => {
    setOpenSnackError(true);
  };

  const handleCloseSnackbarError = (event: any, reason: any) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackError(false);
  };


  // Verificar si hay errores de validación
  const hasErrors = Object.values(errors).some(error => error !== '');


  return (
    <>




   

      <div className='overflow-y-hidden '>

        <main className="overflow-y-scroll scroll-container snap-mandatory snap-y h-screen">
          <ContentSwiper
            list={feedContent}
            isFetching={result.isFetching}
            goToProfile={gotoProfile}
            addFavoriteAction={addFavoriteAction}
            removeFavoriteAction={removeFavoriteAction}
            addFavResponse={responseAddFavorite}
            removeFavResponse={responseRemoveFavorite}
            addNewItem={(setNewItem: any) => {
              //console.log(contents,'contentsss')
              //setNewItem(contents ? contents.contents : [])
            }}
          />


          <div ref={observerRef}>{result.isLoading ? ('Loading more items...') : feedContent.length == 0 ? ('Currently, there is no existing content.') : (null)}</div>


        </main>
      </div>




      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={openSnackSuccess}
        onClose={handleCloseSnackbarSuccess}
        message="Operation successful."
        key={'top' + 'center1'}
      />

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={openSnackError}
        onClose={handleCloseSnackbarError}
        message={"An error has occurred. Please try again later or contact us for assistance."}
        key={'top' + 'center2'}
      />

    </>
  )
}

export default StoryList;