import * as React from 'react';
import { Box, Button, Typography, FormControl, InputLabel, Input, FormHelperText, TextField } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { useCreateContentReportMutation, useGetReportReasonQuery, useGetReportStateQuery } from "../../_redux/slices/API/report.service"
import Loading from '../../shared/components/Loading';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Alert from '@mui/material/Alert';

function ReportForm({setDialogOption, setOpen, item} : any) : React.ReactNode{
    const [ createContentReport, response ] = useCreateContentReportMutation();
    const [ description, setDescription ] = React.useState('');
    const [ details, setDetails ] = React.useState('');
    const [ reason, setReason ] = React.useState('');
    const {
        data: reasons,
        isLoading: reasonsIsLoading,
        isSuccess: reasonsIsSuccess,
        isError: reportsIsError,
        error: reasonsError
    } = useGetReportReasonQuery('');

    const {
        data: states,
        isLoading: statesIsLoading,
        isSuccess: statesIsSuccess,
        isError: statesIsError,
        error: statesError
    } = useGetReportStateQuery('');

    const handleDescription = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setDescription(event.target.value);
    }

    const handleDetails = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setDetails(event.target.value);
    }

    const handleReason = (event: SelectChangeEvent) => {
        console.warn(event.target.value)
        setReason(event.target.value)
        
    }

    const handleSubmit = () => {
        let payload = {
            description: description,
            details : details,
            contentParent: item._id,
            reason: {
                "_id": reason
            }
        };
        
        createContentReport(payload)
        .unwrap()
        .then(() => {
            setDialogOption("menu");
            setOpen(false);
            setDetails("");
            setDescription("");
            setReason("");
            
        })
        .catch((error: any) => {
            console.log(error, 'ERROR///')
        })
    }

    if(statesIsLoading || reasonsIsLoading) {
        return <Loading/>
    }else if(reasonsIsSuccess){
        return <>
            <Box
                sx={{
                    p:2,
                    justifyContent: 'center',
                    bgcolor: 'background.paper',
                    borderRadius: 1,
                    
                }}
            >
                <Box
                    sx={{
                        mb:3
                    }}
                >
                    <Typography variant="subtitle1">
                        You are the most important thing for us, so we will take into account everything you want to tell us. 
                    </Typography><br/>
                    <Typography variant="subtitle2">
                        You will report a publication of { item.authorText } user.
                    </Typography>
                </Box>
                <Box>
                    <FormControl fullWidth variant='standard'>
                        <InputLabel htmlFor="description">Report description</InputLabel>
                        <Input inputProps={{ maxLength: 40 }} value={description} onChange={(e) => handleDescription(e)}  id="description" aria-describedby="my-helper-text" />
                        <FormHelperText id="my-helper-text">Describe de reason for this report.</FormHelperText>
                        <TextField inputProps={{ maxLength: 300 }} value={details} onChange={(e) => handleDetails(e)} variant="standard" multiline rows={6} label="Report details" />
                        <FormHelperText id="my-helper-text-2">Write the details of the report.</FormHelperText>
                    </FormControl>
                    <br />
              
                    <FormControl variant="standard" fullWidth>
                        <InputLabel id="reason-select-label">Report reason</InputLabel>
                        <Select
                            labelId="reason-select-label"
                            id="reason-select"
                            label="Report reason"
                            onChange={(e) => {handleReason(e)}}
                            defaultValue=''
                            value={reason}
                        >
                        {
                            reasons.map(( reason: any ) => {
                                return <MenuItem value={ reason._id }>{ reason.name }</MenuItem>
                            })
                        }
                        </Select>
                        <Button onClick={() => {handleSubmit()}}>
                            <SendIcon></SendIcon>
                        </Button>
                    </FormControl>
               
                    
                </Box>        
            </Box>
        </>
    }

    
}

export default ReportForm;