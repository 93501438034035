const hexToRgba = (hex:string,alpha:number) =>  {
    // Eliminar el símbolo "#" del valor hexadecimal, si está presente
    hex = hex.replace("#", "");
  
    // Verificar si el valor hexadecimal tiene 3 o 6 caracteres
    const isShortHex = hex.length === 3;
    const step = isShortHex ? 1 : 2;
  
    // Obtener los valores R, G y B del valor hexadecimal
    const values = [];
    for (let i = 0; i < hex.length; i += step) {
      const hexValue = isShortHex ? hex[i] + hex[i] : hex.substring(i, i + step);
      values.push(parseInt(hexValue, 16));
    }
  
    // Devolver el valor RGB en formato "rgb(r, g, b)"
    return `rgb(${values.join(",")},${alpha})`;
  }

  function formatNumber(number:number) {
    const SI_SYMBOL = ['', 'K', 'M', 'B']; // Símbolos en inglés para cada escala de número (K: thousands, M: millions, B: billions, T: trillions)
  
    // Divide el número por la escala correspondiente
    const scale = Math.floor((number.toString().length - 1) / 3);
  
    // Calcula el valor formateado
    const scaledValue = number / Math.pow(1000, scale);
    const formattedValue = scaledValue % 1 === 0 ? scaledValue.toFixed(0) : scaledValue.toFixed(1);
  
    // Concatena el valor formateado con el símbolo correspondiente
    let symbol = SI_SYMBOL[scale];
  
    if (scale >= SI_SYMBOL.length) {
      symbol = 'B+';
    }
  
    return `${formattedValue}${symbol}`;
  }


export {hexToRgba,formatNumber}