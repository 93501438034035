import React, { useState } from 'react';
import Modal from '@mui/material/Modal';
import { Box } from '@mui/system';
import { authFirebase, createUserLoginEmailPasswordFirebase, mapErrorCodeToMessage, sendPasswordResetEmailFirebase } from '../../firebase';
import { Alert } from '@mui/material';

interface ErrorMessageState { errorCode: string | Number, message: string }

const ResetPasswordModal = ({ isOpen, onClose }: any) => {

    const [errorMessage, setErrorMessage] = useState<null | ErrorMessageState>(null)
    const [successMessage, setSuccessMessage] = useState(false)

    const [newCredentials, setNewCredentials] = useState({
        email: '',
    })

    const [errors, setErrors] = useState({ email: '' });
    const handleSubmitResetPassword = () => {


        sendPasswordResetEmailFirebase(authFirebase, newCredentials.email)
            .then(() => {
                console.log('password changed')
                setErrorMessage(null)
                setSuccessMessage(true)
                // Password reset email sent!
                // ..
            })
            .catch((error) => {
                const errorCode = error.code;
                const message = error.message.replace('Firebase: ', '');
                console.log(message)

                setErrorMessage({
                    errorCode,
                    message: mapErrorCodeToMessage(errorCode)
                })

                setSuccessMessage(false)
                // ..
            });

    }




    const handleChangeFrmNewUser = (e: any) => {
        const { name, value } = e.target;
        setNewCredentials({
            ...newCredentials,
            [name]: value
        });

        // Validación del correo electrónico
        if (name === 'email') {
            if (!value) {
                setErrors({ ...errors, email: 'Email field cannot be empty' });
            } else if (!/\S+@\S+\.\S+/.test(value)) {
                setErrors({ ...errors, email: 'Enter a valid email address' });
            } else {
                setErrors({ ...errors, email: '' });
            }
        }


    };
    // Verificar si hay errores de validación
    const hasErrors = Object.values(errors).some(error => error !== '');

    return (
        <Modal
            open={isOpen}
            onClose={onClose}
            aria-labelledby="register-modal-title"
            aria-describedby="register-modal-description"
        >
            <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', bgcolor: 'Background', boxShadow: 24, p: 4, width: 400 }}>
                <h2 id="register-modal-title" className='flex text-center text-lg font-bold'>Reset Password</h2>
                <div id="register-modal-description">
                    {
                        successMessage ?

                            <Alert variant="outlined" severity="success">
                                <div>
                                    <h3>Success! Password reset email sent!</h3>
                                    <p>
                                     If you don't find the email in your inbox, we recommend checking your spam or junk folder. If you still can't find the password reset email, try logging in with your Google account.
                                    </p>
                                </div>
                            </Alert> :

                            <form>
                                {
                                    errorMessage ?
                                        <Alert variant="outlined" severity="warning">
                                            {errorMessage.message}
                                        </Alert> :
                                        (null)
                                }

                                <div className="mb-4">
                                    <label htmlFor="email" className="block text-gray-700 text-sm font-bold mb-2">Email</label>


                                    <input type="email" id="EmailAddress" name="email"
                                        value={newCredentials.email}
                                        onChange={handleChangeFrmNewUser}
                                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                        placeholder="Enter your email" />

                                    {errors.email && <div style={{ color: 'red' }}>{errors.email}</div>}
                                </div>

                                <button type="button" disabled={hasErrors} onClick={handleSubmitResetPassword} className={`bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline ${hasErrors ? "bg-slate-400" : "bg-sky-500"}`}>Send Password Reset Email</button>
                            </form>
                    }
                </div>
            </Box>
        </Modal>
    );
};

export default ResetPasswordModal;