import React, { useState } from 'react';
import { useAddNewCommentMutation, useAddNewReplyMutation } from '../../_redux/slices/API/comment.service';
import { Card, CardContent, Box, IconButton, TextField } from '@mui/material';
import SendIcon from "@mui/icons-material/Send";
import { useSelector } from 'react-redux';
interface CommentInputProps{
    idContent:string, 
    idComment?:string,
    onNewData:(arg:any)=>void
  }
  const CommentInput = ({idContent,idComment, onNewData}:CommentInputProps)=>{
    const [addNewComment, responseNewComment] = useAddNewCommentMutation()
    const [addNewReply, responseReply] = useAddNewReplyMutation()
    const {userInfo} = useSelector((state:any)=>state.auth)
    const [newComment, setNewComment] = useState("");
  
  
    const handleSubmit = (event:any) => {
      event.preventDefault();
      // Agregar lógica para enviar el nuevo comentario
      if(idComment!==undefined && idComment!==null){
        addNewReply({payload:{text:newComment}, idContent, idCommentParent:idComment})
        .unwrap()
        .then((data:any) => {
          console.log(data)
          setNewComment("");          
          if(data){
            if(data._id){
              onNewData({
                _id:data._id,
                contentParent:idContent, 
                parent:idComment,
                likes:[],
                text:newComment,
                nReplies:0,
                level:data.level,
                author:{_id:userInfo.uid, username:userInfo.username},
                created_at:data.created_at
              })
            }
          }
        })
        .catch((error:any) => {      
          console.log(error,'ERROR/// :(')      
        })  
        
      }else if(idContent!==undefined && idContent!==null){
        addNewComment({payload:{text:newComment}, idContent})
        .unwrap()
        .then((data:any) => {
          setNewComment("");
          if(data){
            if(data._id){
              onNewData({
                _id:data._id,
                contentParent:idContent,                 
                likes:[],
                nReplies:0,
                text:newComment,
                level:0,
                author:{_id:userInfo.uid, username:userInfo.username},
                created_at:data.created_at
              })
            }
          }
        })
        .catch((error:any) => {      
          console.log(error,'ERROR/// :(')      
        })  
      }
     
    };

    const handleNewCommentChange = (event:any) => {
        setNewComment(event.target.value);
      };
  
    return(
      <Box mb={2}>
  
            <form onSubmit={handleSubmit}>
              <TextField
                id="new-comment"
                label={idComment!==undefined && idComment!==null ? "Responder"  : "Agregar comentario"}
                fullWidth
                size="small"
                value={newComment}
                onChange={handleNewCommentChange}
                margin="dense"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  endAdornment: (
                    <IconButton type="submit" disabled={!newComment} aria-label="enviar">
                      <SendIcon />
                    </IconButton>
                  ),
                }}
              />
            </form>

  
  
    </Box>
    )
  }

  export default CommentInput;