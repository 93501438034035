import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';
import Typography from '@mui/material/Typography';
import Comment from './components/Comment';
import { useLazyGetCommentsByContentQuery } from '../_redux/slices/API/comment.service';
import Loading from '../shared/components/Loading';
import ErrorMessage from '../shared/components/ErrorMessage';
import CommentInput from './components/CommentInput';



import Timeline from '@mui/lab/Timeline';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';

import FastfoodIcon from '@mui/icons-material/Fastfood';
import LaptopMacIcon from '@mui/icons-material/LaptopMac';
import HotelIcon from '@mui/icons-material/Hotel';
import RepeatIcon from '@mui/icons-material/Repeat';
import InfiniteScroll from 'react-infinite-scroll-component';
import CommentScroll from './components/CommentScroll';


const CommentView = ({idContent, onClose}:any) => {
  const [page, setPage] = useState(1);
  const [items, setItems] = useState<any>([]);
  //const [openDialog, setOpenDialog] = useState(true)


  const [
    trigger,
    result    
  ]= useLazyGetCommentsByContentQuery()

  const removeElements = (id:string)=>{
    setItems(items.filter((elemento:any) => elemento._id !== id));
  }



  const addNewData=(data:any)=>{
    setItems((prevItems:any[])=> {
      let arr = [...prevItems]
      arr.unshift(data)
      if(arr.length>(10*page) && page<result.data.totalPages){
        arr.pop()
      }
      return arr
    })
  }
  const fetchMoreData = () => {
    // Llame a su API aquí para obtener los siguientes elementos
    // Añada los nuevos elementos al estado `items`
    // Si no hay más elementos, actualice el estado `hasMore` a `false`
    console.log('try fetching more...', result.data.totalPages)
    if (page < result.data.totalPages) {
      console.log('try fetching more...', page)
      setPage((prevPage:any) => prevPage + 1);
    }

  };



  useEffect(()=>{
    console.log('pafge change')
    trigger({page, idContent:String(idContent)},false)    
  },[page])


  useEffect(()=>{    
    if(result){
      if(result.data && !result.isFetching){
        if(result.data.comments){          
          if(result.data.comments.length>0){
            console.log('data new ', result)
            setItems((prevContent:any) => prevContent.concat(...result.data.comments) )                  
          }
        }
      }
    }
                      
},[result.data, result.isFetching])


  const handleClose = () => {
    onClose()
    //setOpenDialog(false)
  };
  let commentContentView
  if (result.isLoading) {
    commentContentView = (
      <Loading/>
    )
  } else if (result.isSuccess) {
    commentContentView =  (
      <>
      <Timeline position="right" sx={{
            [`& .${timelineItemClasses.root}:before`]: {
              flex: 0,
              padding: 0,
            },
          }}>        
            {items.map(({ author,text,likes,level,created_at, parent, _id, nReplies, isFavorite}:any) => (          
              <Comment 
              key={_id}
              author={author}
              text={text}
              likes={likes}
              level={level}
              created_at={created_at}
              _id={_id}
              idContent={idContent}
              numReplies={nReplies==undefined || nReplies==null ? 0 : nReplies}
              isFavorite={isFavorite}
              parent={parent} 
              removeElement={removeElements}             
              onShowReplies />          
              ))}
            
              </Timeline>
      </>
    )
  } else if (result.isError) {
    commentContentView = (
      <ErrorMessage message={result.error}></ErrorMessage>
    )
  }
  return (
    <>      
    <Dialog onClose={handleClose} open={true} fullWidth={true} maxWidth={'xl'}>
    <DialogTitle>Comments</DialogTitle>
    
    
    <CommentInput idContent={idContent} onNewData={addNewData} />
    
          <InfiniteScroll
            dataLength={items.length}
            next={fetchMoreData}
            hasMore={ result.isSuccess ? page < result.data.totalPages  : false}
            loader={<h4>Loading...</h4>}
            endMessage={<h4>There are no more comments</h4>}
            height={400}
            scrollableTarget="dialog-content"
          >
            <div id="dialog-content">
              {commentContentView}          
            </div>
          </InfiniteScroll>
      
      
     

    
  </Dialog> 
    </>
  )

}




export default CommentView;