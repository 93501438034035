import React, { useState, useEffect, useRef } from 'react';

import { Link, useNavigate } from 'react-router-dom';

import FontsData from '../../_entities/FontsData'
import FontColor from '../../_entities/FontColor'
import BgColor from '../../_entities/BgColor'
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import SmsIcon from '@mui/icons-material/Sms';
import CommentIcon from '@mui/icons-material/Comment';
import IconButton from '@mui/material/IconButton';
import { pink, grey } from '@mui/material/colors';
import Chip from '@mui/material/Chip';
import PremiumIcon from '../../shared/components/PremiumIcon'
import TextTruncate from 'react-text-truncate'; // recommend
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import Badge from '@mui/material/Badge';
import CommentView from '../../CommentView';
import CommentWithBadgeIcon from '../../CommentView/components/CommentWithBadgeIcon';
import { Button } from '@mui/material';
import { formatNumber, hexToRgba } from '../../shared/utils/number.util';
import { Mention, MentionsInput } from 'react-mentions';
import { ALIGN_TEXT_VALUES, DECORATION_LINE_TEXT_VALUES, ITALIC_TEXT_VALUES, SIZE_TEXT_VALUES, WEIGHT_TEXT_VALUES } from '../../_redux/slices/editor/editor.slice';
import styleEditor from '../../_components/editor/editor.styles';
import OptionsIcon from '../../ReportView/components/OptionsIcon';
import { ChangeFormatDate } from '../../shared/utils/date.util';
import ItemMenuContainer from './ItemMenuContainer';
import BuyMeAcoffeeButtonMobile from '../../_components/buyMeCoffeeButtonMobile';

interface ContentStory {

    text: string | null,
    font: number,
    color: number,
    align: number,
    size: number,
    weight: number
    italic: number
    decorationLine: number

}

const LinkToCompleteView = (props: any) => {

    const {
        item
    } = props
    return (
        <>
            {item.content.isPremium && item.nLettersPremium != undefined && item.nLettersPremium != null ?
                (<Chip label={item.nLettersPremium + ' letters premium'} color="primary" size="small" variant="outlined" />) : null
            }
        </>
    )
}

const Story = (props: any) => {

    const {
        item,
        gotoProfile,
        removeFavoriteAction,
        addFavoriteAction
    } = props
    const navigate = useNavigate();
    const [isTruncated, setIsTruncated] = useState(false);

    const containerRef = useRef<any>(null);
    const contentRef = useRef<any>(null);
    useEffect(() => {
        const containerHeight = containerRef.current.offsetHeight;
        const contentHeight = contentRef.current.scrollHeight;
        console.log(containerHeight, contentHeight)
        console.log(contentHeight > containerHeight)
        setIsTruncated(contentHeight > containerHeight);
    }, [item.content.contentText]);


    function verifyColorFont(colorF: string, colorBg: string) {
        if (colorF == '#000000' && colorBg == '#000000') return '#FAF7F6'
        return colorF
    }

    function stringToColor(string: string) {
        let hash = 0;
        let i;

        /* eslint-disable no-bitwise */
        for (i = 0; i < string.length; i += 1) {
            hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }

        let color = '#';

        for (i = 0; i < 3; i += 1) {
            const value = (hash >> (i * 8)) & 0xff;
            color += `00${value.toString(16)}`.slice(-2);
        }
        /* eslint-enable no-bitwise */

        return color;
    }

    function stringAvatar(name: string) {
        return {
            sx: {
                bgcolor: stringToColor(name),
            },
            //children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
            children: `${name[0]}${name[Math.round(name.length / 2)]}`,
        };
    }


    const goToPreview = (idContent: string) => {
        return navigate("/content/" + idContent, { state: {} })
    }

    const renderSuggestion = (suggestion: any, search: any, highlightedDisplay: any) => {
        // Lógica para personalizar la representación de cada sugerencia
        return <div>{highlightedDisplay} - {formatNumber(suggestion.usageCount != undefined || suggestion.usageCount != null ? suggestion.usageCount : 0)}</div>;
    };

    return (
        <div ref={containerRef} className='sm:w-screen md:w-full  md:py-4' >

            <figure style={{ backgroundColor: BgColor[item.content.bgColor] }}
                className={isTruncated ? "md:border md:border-gray-300 custom-height-content shadow-inner drop-shadow-lg   sm:rounded-none md:rounded-3xl relative  md:flex flex  overflow-hidden  bg-slate-100  md:p-0 dark:bg-slate-800" : "md:border md:border-gray-300 custom-height-content shadow-inner drop-shadow-lg   sm:rounded-none md:rounded-3xl relative  md:flex flex items-center overflow-hidden  bg-slate-100  md:p-0 dark:bg-slate-800"}
            >

                <div className="absolute top-0 right-0 rounded-t-md bg-opacity-30 bg-blur-md bg-gray-800 text-white p-4 rounded-md shadow-md">
                    <p className="text-sm">
                        <span className=" font-freehand  tracking-widest">{ChangeFormatDate(item.content.created_at)}</span>
                    </p>
                </div>

                <div className={"w-full  pt-6 md:p-8  space-y-4"}>

                    <div ref={contentRef} className={isTruncated ? 'h-full items-center    overflow-hidden' : ' overflow-hidden items-center'}>
                        {
                            item.content.story.map((story: ContentStory, idx: number) => (

                                <div className={'w-screen md:w-full ' + SIZE_TEXT_VALUES[story.size] + ' ' + FontsData[story.font]}>

                                    <MentionsInput
                                        id={"standard-basic-" + idx}
                                        ref={contentRef}
                                        value={story.text == null ? '' : story.text}
                                        spellCheck={false}

                                        disabled={true}
                                        style={{
                                            color: FontColor[story.color],
                                            'highlighter': {
                                                padding: '8px',
                                                margin: '0px',
                                                ...styleEditor[ALIGN_TEXT_VALUES[story.align]],
                                                ...styleEditor[DECORATION_LINE_TEXT_VALUES[story.decorationLine]],
                                                ...styleEditor[ITALIC_TEXT_VALUES[story.italic]],
                                                ...styleEditor[WEIGHT_TEXT_VALUES[story.weight]],
                                            },

                                            'input':
                                            {
                                                padding: '8px',
                                                ...styleEditor[ALIGN_TEXT_VALUES[story.align]],
                                                ...styleEditor[DECORATION_LINE_TEXT_VALUES[story.decorationLine]],
                                                ...styleEditor[ITALIC_TEXT_VALUES[story.italic]],
                                                ...styleEditor[WEIGHT_TEXT_VALUES[story.weight]],
                                            },
                                            suggestions: {
                                                list: {
                                                    backgroundColor: '#333',
                                                    border: '1px solid rgba(0,0,0,0)',
                                                    borderRadius: '5px',
                                                    fontSize: 14,
                                                },
                                                item: {
                                                    padding: '5px 15px',
                                                    borderBottom: '1px solid rgba(0,0,0,0.15)',
                                                    '&focused': {
                                                        backgroundColor: '#444',
                                                        color: '#fff'
                                                    },
                                                },
                                            },
                                        }}
                                        className={SIZE_TEXT_VALUES[story.size]}
                                    >
                                        <Mention
                                            trigger="#"
                                            markup='#[__display__](__id__)'
                                            displayTransform={(id: string, display: string) => '#' + display}
                                            data={[]}
                                            style={{
                                                backgroundColor: 'red',
                                            }}
                                            renderSuggestion={renderSuggestion}
                                            appendSpaceOnAdd={true}
                                        />

                                    </MentionsInput>
                                </div>
                            ))
                        }
                    </div>

                    <LinkToCompleteView item={item} />

                    {isTruncated && (

                        <div className=" absolute bottom-20 left-0 rounded-t-md bg-opacity-30 bg-blur-md bg-gray-800 text-white p-4 rounded-md shadow-md">
                            <p className="text-sm">

                                <Button className='font-freehand  tracking-widest ' style={{ color: 'rgb(255 255 255)' }} onClick={() => goToPreview(item.content._id)}>Read on</Button>
                            </p>
                        </div>
                    )}


                </div>


                <Stack direction="column" className={' absolute z-50 bottom-32 right-2 '} spacing={3} justifyContent="center" alignItems="center">

                    <Avatar {...stringAvatar(item.content.createdBy.username)} onClick={() => gotoProfile(item)} />

                    <ItemMenuContainer>

                        {
                            item.isFavorite ?
                                <Badge color="primary" overlap="circular" anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }} badgeContent={item.content.likes} max={999}>
                                    <IconButton onClick={() => removeFavoriteAction()(item.content._id)} aria-label="favorite">
                                        <FavoriteIcon fontSize="inherit" sx={{ color: pink[500],fontSize:'1.7rem' }} />
                                    </IconButton>
                                </Badge> :
                                <Badge color="primary" overlap="circular" anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }} badgeContent={item.content.likes} max={999}>
                                    <IconButton onClick={() => addFavoriteAction()(item.content._id)} aria-label="favoriteBorder" >
                                        <FavoriteBorderIcon fontSize="inherit" sx={{ color: pink[500], fontSize:'1.7rem' }} />
                                    </IconButton>
                                </Badge>
                        }
                    </ItemMenuContainer>

<ItemMenuContainer>

                    <CommentWithBadgeIcon item={item.content} />
</ItemMenuContainer>
<ItemMenuContainer>

                    <OptionsIcon item={item.content} />
</ItemMenuContainer>
<ItemMenuContainer>

<BuyMeAcoffeeButtonMobile/>
</ItemMenuContainer>

                </Stack>
            </figure>



        </div>

    )
}

export default Story