import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
// Make TS accept the existence of our window.__ENV object - defined in index.html:
interface WindowWithEnv extends Window {
    __ENV?: {
        backendURL: string, // REACT_APP_BACKEND_URL environment variable
        sandbox: "true" | "false", // REACT_APP_SANDBOX_SDK environment variable - string, not boolean!
    }
}
const _window: WindowWithEnv = window;
const backendURL = _window.__ENV && _window.__ENV.backendURL;

export const apiHashtagsSlice = createApi({
    reducerPath: 'apiHashtagsSlice',
    baseQuery: fetchBaseQuery({
        baseUrl: backendURL,
        prepareHeaders: (headers, { getState }: any) => {
            const token = getState().auth.userToken
            if (token) {
                // include token in req header
                headers.set('authorization', `Bearer ${token}`)
                return headers
            }
        }
    }),
    tagTypes: ['Hashtag'],
    endpoints: (builder) => ({
        getHashtagsSuggestionsByUsage: builder.query({
            query: ({ suggestion }) => ({
                url: '/hashtags/suggestion/' + suggestion,
                method: 'GET',
            })
        }),
    }),

})
export const {
    useLazyGetHashtagsSuggestionsByUsageQuery,
 } = apiHashtagsSlice