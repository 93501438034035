import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { redirect } from 'react-router-dom'
import { UserSign } from '../../../_entities/PI'
import { signAccountUser } from './auth.actions'

type UserState = {
  loading?:boolean,
  userInfo?: any, // for user object
  userToken?:any,
  error?: any,
  success?: boolean,
}

type UserStatePayload = {
  loading:any,
  userInfo?: any, // for user object
  userToken?:any,
  error?: any,
  success?: boolean,
}

// initialize userToken from local storage
/*const user: UserData = localStorage.getItem('useruid')
  ? { uid: localStorage.getItem('useruid'), username: localStorage.getItem('username') }
  : null*/
const userToken:string | null = localStorage.getItem('userToken')
  ? localStorage.getItem('userToken') : null
  

  const userInfo:string | null = localStorage.getItem('userInfo')!=null && localStorage.getItem('userInfo')!=undefined 
  ? JSON.parse(localStorage.getItem('userInfo')as any): null
const initialState = {
  loading: false,
  userInfo, // for user object
  userToken,
  error: null,
  success: false, // for monitoring the registration process.
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    signPassword: (state:any,action:PayloadAction<any>) => { 
      console.log(action,'action')     
        
        localStorage.setItem('loginWith','Password')
        localStorage.setItem('userToken', action.payload.userToken)
        localStorage.setItem('userInfo', JSON.stringify(action.payload.userInfo))
        state.loading = false
        state.success = true
        state.userInfo = {
          uid: action.payload.userInfo.uid,
          username: action.payload.userInfo.username,
          language_preferences:action.payload.userInfo.language_preferences,
        }        
        state.userToken = action.payload.userToken
        return state;       
    },
    signGoogle: (state:any,action:PayloadAction<any>) => { 
      console.log(action,'action')     
        
        localStorage.setItem('loginWith','Google')
        localStorage.setItem('userToken', action.payload.userToken)
        localStorage.setItem('userInfo', JSON.stringify(action.payload.userInfo))
        state.loading = false
        state.success = true
        state.userInfo = {
          uid: action.payload.userInfo.uid,
          username: action.payload.userInfo.username,
          language_preferences:action.payload.userInfo.language_preferences,
        }        
        state.userToken = action.payload.userToken
        return state;       
    },
    signFB: (state:any,action:PayloadAction<any>) => { 
      console.log(action,'action')     
        
        localStorage.setItem('loginWith','FB')
        localStorage.setItem('userToken', action.payload.userToken)
        localStorage.setItem('userInfo', JSON.stringify(action.payload.userInfo))
        state.loading = false
        state.success = true
        state.userInfo = {
          uid: action.payload.userInfo.uid,
          username: action.payload.userInfo.username,
          language_preferences:action.payload.userInfo.language_preferences,
        }        
        state.userToken = action.payload.userToken
        return state;       
    },
    updateLanguagesPreferences:(state:any,action:PayloadAction<any>)=>{
      console.log(action.payload, "save preferemces wiotj")
        state.userInfo.language_preferences = action.payload.language_preferences
        localStorage.setItem('userInfo', JSON.stringify(state.userInfo))
        return state
    }
    ,
    updateUsername:(state:any,action:PayloadAction<any>)=>{
      console.log(action.payload,'new state')
      state.userInfo.username = action.payload.username
      localStorage.setItem('userInfo', JSON.stringify(state.userInfo))
      return state
   },
    reset:(state:any, action)=>{
      localStorage.removeItem('loginWith')
      localStorage.removeItem('userToken')
      localStorage.removeItem('userInfo')
      state={...initialState, userInfo:null, userToken:null}
      console.log('state/////remove',state)
      return state
    },
    logout: (state:any,action) => {
      localStorage.removeItem('loginWith')
      localStorage.removeItem('userToken') // deletes token from storage
      localStorage.removeItem('userInfo')
      state.loading = false
      state.userInfo = null
      state.userToken = null
      state.error = null
    },
  },
  extraReducers(builder) {
    builder
      .addCase(signAccountUser.pending, (state:any, action:any) => {
        state.loading = true
        state.error = null
      })
      .addCase(signAccountUser.fulfilled, (state:any, { payload }:any) => {
        console.log(payload, "payload////")
        localStorage.setItem('userToken', payload.data.systemtoken)
        localStorage.setItem('userInfo', JSON.stringify({
          uid: payload.data.uid,
          username: payload.data.username,
          language_preferences:payload.data.language_preferences,
        }))
        state.loading = false
        state.success = true
        state.userInfo = {
          uid: payload.data.uid,
          username: payload.data.username,
          language_preferences:payload.data.language_preferences,
        }        
        state.userToken = payload.data.systemtoken

      })
      .addCase(signAccountUser.rejected, (state:any, { payload }:any) => {
        state.loading = false
        state.error = payload as any
      })
      /*.addCase(logoutAccountUser.pending, (state, action) => {
        state.loading = true
        state.error = null
      })*/
      /*.addCase(logoutAccountUser.fulfilled, (state, { payload }) => {
        console.log(payload, "payload////")
        localStorage.removeItem('userToken') // deletes token from storage
        state.loading = false
        state.userInfo = null
        state.userToken = null
        state.error = null
      })
      .addCase(logoutAccountUser.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload as any
      })*/

  },
})
export const { reset, logout,signPassword,signGoogle, signFB, updateLanguagesPreferences, updateUsername } = authSlice.actions
export default authSlice.reducer