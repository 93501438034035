import * as React from 'react';
import { ALIGN_TEXT_VALUES, ContentItem, DECORATION_LINE_TEXT_VALUES, ITALIC_TEXT_VALUES, SIZE_TEXT_VALUES, WEIGHT_TEXT_VALUES, changeFocus, newAfterParagraph, newBeforeParagraph, removeParagraph, write } from '../../_redux/slices/editor/editor.slice';
import FontsData from '../../_entities/FontsData';
import { Mention, MentionsInput } from 'react-mentions';
import FontColor from '../../_entities/FontColor';
import styleEditor from './editor.styles';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { formatNumber } from '../../shared/utils/number.util';
import { useLazyGetHashtagsSuggestionsByUsageQuery } from '../../_redux/slices/API/hashtags.service';
import { IconButton } from '@mui/material';
import MoreHorizTwoToneIcon from '@mui/icons-material/MoreHorizTwoTone';
import AddCircleOutlineTwoToneIcon from '@mui/icons-material/AddCircleOutlineTwoTone';
import RemoveCircleOutlineTwoToneIcon from '@mui/icons-material/RemoveCircleOutlineTwoTone';
import AddCircleTwoToneIcon from '@mui/icons-material/AddCircleTwoTone';



import { Divider, Popover } from '@mui/material';

export default function InputLine({ item, idx }: { item: ContentItem, idx: number }) {
    const [anchorMenuEl, setAnchorMenuEl] = useState(null);
    const [trigger, result] = useLazyGetHashtagsSuggestionsByUsageQuery()

    const dispatch = useDispatch()
    const openMenu = Boolean(anchorMenuEl);


    const { content,
        bgColor,
        focusIn } = useSelector((state: any) => state.editor)

    const changeTextContent = (e: any) => {
        const value = e.target.value
        dispatch(write(value));
    }

    const handleMenuClick = (event: any, idx: number) => {
        setAnchorMenuEl(event.currentTarget);
        updateFocus(idx)
    };

    const handleMenuClose = () => {
        setAnchorMenuEl(null);
    };
    const createAfterParagraph = () => {
        dispatch(newAfterParagraph())
        handleMenuClose()
    }

    const createBeforeParagraph = () => {
        dispatch(newBeforeParagraph())
        handleMenuClose()
    }

    const removeCurrentParagraph = (currentIdx: number) => {
        dispatch(removeParagraph(currentIdx))
        handleMenuClose()
    }

    const updateFocus = (idx: number) => {
        if (idx != focusIn) {
            dispatch(changeFocus(idx))
            handleMenuClose()
        }
    }

    const renderSuggestion = (suggestion: any, search: any, highlightedDisplay: any) => {
        // Lógica para personalizar la representación de cada sugerencia
        return <div>{highlightedDisplay} - {formatNumber(suggestion.usageCount != undefined || suggestion.usageCount != null ? suggestion.usageCount : 0)}</div>;
    };

    const getSuggestionsTitleWithFormat = (query: any, callback: any) => {
        if (!query) return;
        if (!result.isLoading && !result.isFetching && query.length > 3) {

            trigger({ suggestion: query }, false)
                .then((response) => {

                    const suggestedHashtags = response.data.suggestedHashtags;
                    let suggestions = suggestedHashtags.map((hashtag: any) => ({ id: typeof hashtag === 'string' ? hashtag : hashtag._id, display: typeof hashtag === 'string' ? hashtag : hashtag.text, usageCount: typeof hashtag === 'string' ? 0 : hashtag.usageCount }))
                    callback(suggestions)
                    console.log(suggestions, 'suggestions')
                    return
                })


        }
        callback([{ id: query, display: query }])

    }



    return (
        <div className='inline-flex w-full justify-around'>
            <div className={'w-full ' + SIZE_TEXT_VALUES[item.size] + ' ' + FontsData[item.font]}>
                <MentionsInput
                    id={"standard-basic-" + idx}
                    value={item.text == null ? '' : item.text}
                    spellCheck={false}
                    autoFocus={idx == focusIn}
                    onFocus={() => updateFocus(idx)}
                    style={{
                        color: FontColor[item.color],
                        'highlighter': {
                            padding: '8px',
                            margin: '0px',
                            ...styleEditor[ALIGN_TEXT_VALUES[item.align]],
                            ...styleEditor[DECORATION_LINE_TEXT_VALUES[item.decorationLine]],
                            ...styleEditor[ITALIC_TEXT_VALUES[item.italic]],
                            ...styleEditor[WEIGHT_TEXT_VALUES[item.weight]],
                        },
                        'input':
                        {
                            padding: '8px',
                            ...styleEditor[ALIGN_TEXT_VALUES[item.align]],
                            ...styleEditor[DECORATION_LINE_TEXT_VALUES[item.decorationLine]],
                            ...styleEditor[ITALIC_TEXT_VALUES[item.italic]],
                            ...styleEditor[WEIGHT_TEXT_VALUES[item.weight]],
                        },
                        suggestions: {
                            list: {
                                backgroundColor: '#333',
                                border: '1px solid rgba(0,0,0,0)',
                                borderRadius: '5px',
                                fontSize: 14,
                            },
                            item: {
                                padding: '5px 15px',
                                borderBottom: '1px solid rgba(0,0,0,0.15)',
                                '&focused': {
                                    backgroundColor: '#444',
                                    color: '#fff'
                                },
                            },
                        },
                    }}
                    className={SIZE_TEXT_VALUES[item.size]}
                    onChange={changeTextContent}>

                    <Mention
                        trigger="#"
                        markup='#[__display__](__id__)'
                        displayTransform={(id: string, display: string) => '#' + display}
                        data={getSuggestionsTitleWithFormat}
                        style={{
                            backgroundColor: 'red',
                        }}
                        renderSuggestion={renderSuggestion}
                        appendSpaceOnAdd={true}
                    />
                </MentionsInput>

            </div>
            <div className='flex flex-col justify-center'>
                <IconButton aria-label="menu" onClick={(ev: any) => handleMenuClick(ev, idx)} style={{ padding: "0px" }}>
                    <MoreHorizTwoToneIcon sx={{ color: FontColor[item.color] }} />
                </IconButton>

            </div>

            <Popover
                open={openMenu}
                anchorEl={anchorMenuEl}
                onClose={handleMenuClose}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'left',
                }}
            >
                <div style={{ padding: '16px' }}>
                    <Divider textAlign="left">Remove</Divider>
                    <div>
                        <IconButton aria-label="exit" onClick={() => removeCurrentParagraph(idx)}>
                            <RemoveCircleOutlineTwoToneIcon className='p-0 m-0' />
                        </IconButton>
                    </div>
                    <Divider textAlign="left">Before</Divider>
                    <div>
                        <IconButton aria-label="plus" onClick={createBeforeParagraph} >
                            <AddCircleOutlineTwoToneIcon className='p-0 m-0' />
                        </IconButton>
                    </div>
                    <Divider textAlign="left">After</Divider>
                    <div>

                        <IconButton aria-label="plus" onClick={createAfterParagraph} >
                            <AddCircleTwoToneIcon className='p-0 m-0' />
                        </IconButton>
                    </div>
                </div>
            </Popover>
        </div>
    );
}