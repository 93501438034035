import FavoriteIcon from '@mui/icons-material/Favorite';
import ISO from 'iso-639-1';
import React, { useState, useEffect } from 'react';
import { Card, Paper, Grid, CardContent, List, ListItemText, ListItem, Divider, ListItemAvatar, FormControl, IconButton, CardActions, InputLabel, Tabs,Tab, Box, Typography, Badge, Chip, TextField, Stack, ButtonGroup, Button, Autocomplete, Select, MenuItem } from '@mui/material';
import ISO6391 from 'iso-639-1';
import DeleteIcon from '@mui/icons-material/Delete';
import { useLazyGetProfileInfoQuery } from '../../_redux/slices/API/author.service'
import { useUpdateProfileInfoMutation } from '../../_redux/slices/API/author.service'
import { CommentsDisabledOutlined } from '@mui/icons-material';
import Loading from '../../shared/components/Loading'
import ErrorMessage from '../../shared/components/ErrorMessage';
import { useNavigate } from 'react-router-dom';
import { updateLanguagesPreferences, updateUsername } from '../../_redux/slices/auth/auth.slice';
import { useDispatch } from 'react-redux';

type ProfileInfoProps = {
    userInfo : any,
}

function UpdateProfileInfo({userInfo} : ProfileInfoProps){
    const languages_codes = ISO6391.getAllCodes();
    const [inputValue, setInputValue] = React.useState('')
    const [ updateProfileInfo, response ] = useUpdateProfileInfoMutation();
    const [currentValuesUser, setCurrentValuesUser] = useState({
        username: userInfo.username,
        language_preferences:userInfo.language_preferences
    })

    const dispatch = useDispatch()
    /* const [
        trigger,
        result,
    ] = useLazyGetProfileInfoQuery(); */

   
    
    /* useEffect(() => {
        trigger(userInfo.uid, true);
        
    }, []) */
    
    /* useEffect(() => {
        if(result.isSuccess && !result.isFetching){
            
        }
    }, [result]) */
    
    const handleSaveProfile = async () => {
        try{

            await updateProfileInfo({
                username:userInfo.username,
                language_preferences:userInfo.language_preferences
            })
      
           /*  setCurrentValuesUser({
                username: userInfo.username,
                language_preferences:userInfo.language_preferences
            }) */
        }  catch (error) {
      console.error(error, 'Error');
    }
    }
    
    const changeUsername = (event : any) =>{
        let newUsername = event.target.value
        console.log('update username')
        dispatch(updateUsername({username:newUsername}))
    }
    

    /* useEffect(()=>{
        
        return() => {
            console.log(currentValuesUser, "Talves aqui el error")
            if(userInfo.language_preferences)
            dispatch(updateUsername({username:currentValuesUser.username}))
            dispatch(updateLanguagesPreferences(
                {
                    language_preferences: currentValuesUser.language_preferences
                }
            ))

        }

    },[currentValuesUser])
 */

     
    let updateProfileContent;

        updateProfileContent = (
            <>
                <Paper elevation={3} className='overflow-auto  p-2 m-2 mt-6 ' style={{height:"75vh"}}>
                    <Stack
                        direction="column"
                        
                        spacing={3}
                        padding={4}
                    >
                            <FormControl>
                            <TextField
                                    id="standard-multiline-static"
                                    label="My username"
                                    multiline
                                    onChange={changeUsername}
                                    variant="standard"
                                    className=" font-['atwriter']"
                                    //defaultValue={profile.username}
                                    defaultValue={userInfo.username}
                                />
                            </FormControl>
                            <FormControl>
                                <Autocomplete
                                    multiple
                                    id="language-selection"
                                    options={languages_codes}
                                    getOptionLabel={(option) => option}
                                    value={userInfo.language_preferences ? userInfo.language_preferences : []}
                                    onChange={(event: any, newValue: string[] | null) => {
                                        dispatch(updateLanguagesPreferences(
                                            {
                                                language_preferences: newValue
                                            }
                                        ))
                                      }}
                                    inputValue={inputValue}
                                    onInputChange={(_, newInputValue) => {
                                        setInputValue(newInputValue)
                                      }}
                                    renderOption={(props, option) => (
                                        <li {...props}>{ISO6391.getName(option)}</li>
                                      )}
                                    renderInput={(params) => ( 
                                        <TextField {...params} variant="standard" label="Language preferences" placeholder="Languages" />
                                      )}

                                />                                 
                            </FormControl>                                
                                
                                <Button variant="contained"
                                    onClick={handleSaveProfile}
                                    >
                                    {'Save'}
                                    
                                </Button>                            
                    </Stack>
                </Paper >
            </>
        )
    

   
    
    

    return <div>{ updateProfileContent }</div>;
}

export default UpdateProfileInfo;