import React, { useEffect, useState } from 'react';
import { Avatar, Card, CardContent, Typography, CardHeader, CardActions, Collapse, Box, IconButton, Button } from '@mui/material';
import CommentIcon from "@mui/icons-material/Comment";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeleteIcon from '@mui/icons-material/Delete';
import { Favorite, FavoriteBorder } from '@mui/icons-material';
import { red, pink } from "@mui/material/colors";
import CommentInput from './CommentInput';
import { useAddCommentToFavoriteMutation, useLazyGetCommentsByCommentQuery, useRemoveCommentMutation, useRemoveCommentToFavoriteMutation } from '../../_redux/slices/API/comment.service';
import Loading from '../../shared/components/Loading';
import ErrorMessage from '../../shared/components/ErrorMessage';


import Timeline from '@mui/lab/Timeline';
import TimelineItem, { timelineItemClasses }  from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import { useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { ChangeFormatDate } from '../../shared/utils/date.util';
const MAX_LEVEL_COMMENTS = 2;

const Comment = ({ author, text, likes, level, created_at, parent, _id, idContent, isFavorite, numReplies, removeElement }: any) => {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const isTopLevel = level === 1;
  const [isExpanded, setIsExpanded] = useState(false);
  const [isElFavorite, setIsElFavorite] = useState(isFavorite)
  const [nLikes,setNLikes] = useState(likes.length)
  const [removeComment, responseRemoveComment] = useRemoveCommentMutation()
  const [addCommentToFavorite, responseAddFavorite] = useAddCommentToFavoriteMutation()
  const [removeCommentToFavorite, responseRemoveFavorite] = useRemoveCommentToFavoriteMutation()
  const [items, setItems] = useState<any>([]);
  const {userInfo} = useSelector((state:any)=>state.auth)
  const [
    trigger,
    result,
    lastPromiseInfo
  ] = useLazyGetCommentsByCommentQuery()


  const handleExpandClick = () => {
    setItems([])
    setIsExpanded(!isExpanded);
    if(!isExpanded){    
      trigger({ page, idComment: String(_id) })
    }
    /**
     * When the trigger function returned from a LazyQuery is
     *  called, it always initiates a new request to the server 
     * even if there is cached data. 
     * Set preferCacheValue(the second argument to the function) 
     * as true if you want it to immediately return a cached 
     * value if one exists.
     */
  };




  const removeElements = (id:string)=>{
    setItems(items.filter((elemento:any) => elemento._id !== id));
  }


  const addNewData=(data:any)=>{
    setItems((prevItems:any[])=> {
      let arr = [...prevItems]
      arr.unshift(data)
      /*
      si supera mayor a 10, se hace un pop, para no afectar
      la lista actual, por que el servidor nos traera un 
      dato repetido.
      */     
      if(arr.length>(10*page) && page<result.data.totalPages){
        arr.pop()
      }
      return arr
    })
  }

  const fetchMoreData = () => {
    // Llame a su API aquí para obtener los siguientes elementos
    // Añada los nuevos elementos al estado `items`
    // Si no hay más elementos, actualice el estado `hasMore` a `false`
    console.log('try fetching more replies...', result.data.totalPages)
    if (result.data.currentPage < result.data.totalPages) {
      console.log('try fetching more replies...', page)
      trigger({ page:result.data.currentPage+1, idComment: String(_id) })        
    }

  };



  useEffect(()=>{    
    if(result){
      if(result.data && !result.isFetching){
        if(result.data.comments){          
          if(result.data.comments.length>0){
            console.log('data new ', result)
            setItems((prevContent:any) => prevContent.concat(...result.data.comments) )                  
          }
        }
      }
    }
                      
},[result.data, result.isFetching])
  
  const handleFavoriteClick = () => {
    addCommentToFavorite(_id)
    .unwrap()
    .then(()=>{

        setIsElFavorite(true)        
        setNLikes((prevNLikes:number)=> prevNLikes+1)

    })
    .catch((error:any)=>{
      console.log(error,'ERROR/// :(')
    })
  }

  const handleRemoveFavoriteClick = () => {
    removeCommentToFavorite(_id)
    .unwrap()
    .then(()=>{

        setIsElFavorite(false)
        setNLikes((prevNLikes:number)=> prevNLikes-1)

    })
    .catch((error:any)=>{
      console.log(error,'ERROR/// :(')
    })
  }

  const handleRemoveClick = () => {
    removeComment(_id)
      .unwrap()
      .then(() => {        
          removeElement(_id)        
      })
      .catch((error: any) => {
        console.log(error, 'ERROR/// :(')
      })
  }

  let CommentQueryStatus
  if (result.isLoading) {
    CommentQueryStatus = (<Loading />)
  } else if (result.isError) {
    CommentQueryStatus = (<ErrorMessage message={result.error} />)
  }

  

  function stringToColor(string: string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

  const gotoProfile = (item: any) => {
    //TODO: Pasar esta funcion (gotoProfile) a una archivo de utils o hooks
    console.log('go to profile')
    navigate(`/profile/${item._id}`);
  }

  function stringAvatar(name: string) {
    return {
      sx: {
        bgcolor: stringToColor(name),
        width: 34, height: 34
      },
      //children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
      children: `${name[0]}${name[Math.round(name.length/2)]}`,
    };
  }


  return (
    <>
      <TimelineItem sx={{paddingRight:'1px'}}>

        <TimelineSeparator>
          <TimelineConnector />
          { 
            level==0 ? (<TimelineDot  variant="outlined" sx={{padding:'0px', borderStyle:'none'}}>
              <Avatar {...stringAvatar(author.username)} onClick={() => gotoProfile(author)} />
            </TimelineDot>):(null)
          }
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ py: '12px', px: 1 }}>
          <Typography variant="subtitle2" component="span">
            {'@' + author.username}
          </Typography>
          <Typography variant="caption" display="block" >
            {ChangeFormatDate(created_at)}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {text}
          </Typography>

          <div className='flex justify-around'>
            <div>

            <Typography variant="caption" color="text.secondary">
              {numReplies >= 10000 ? '+'+9999 : (numReplies == 0 ? '' : numReplies)} 
            </Typography>
              {
                level< MAX_LEVEL_COMMENTS ? 
                (<IconButton onClick={handleExpandClick} aria-expanded={isExpanded} aria-label="ver respuestas">
                  <CommentIcon />
                </IconButton>):(null)
              }
              
            </div>
            <div>

            <Typography variant="caption" color="text.secondary">
              {nLikes >= 10000 ? '+'+9999 : (nLikes == 0 ? '' : nLikes)} 
            </Typography>
             {              
              isElFavorite ? responseRemoveFavorite.isLoading ? (
                <Loading/>
              ):(<IconButton aria-label="eliminar" onClick={handleRemoveFavoriteClick} sx={{ ml: 1 }}>
                <Favorite fontSize="small" />
              </IconButton>):
              responseAddFavorite.isLoading ? (
                <Loading/>
              ):
              (
                <IconButton aria-label="eliminar" onClick={handleFavoriteClick} sx={{ ml: 1 }}>
                <FavoriteBorder fontSize="small" />
              </IconButton>
              )
             }
            </div>
            {
              responseRemoveComment.isLoading ? (
                <Loading/>
              ): author._id==userInfo.uid ? (                
                <IconButton aria-label="eliminar" onClick={handleRemoveClick} sx={{ ml: 4 }}>
                    <DeleteIcon sx={{ color: pink[500] }} />
                </IconButton>
              ):(null)
            }
            
            {/*
            <IconButton aria-label="expandir" onClick={handleExpandClick} sx={{ ml: "auto", transform: isExpanded ? "rotate(180deg)" : "rotate(0deg)", transition: (theme: any) => theme.transitions.create("transform") }}>
              <ExpandMoreIcon />
            </IconButton>
            */}
          </div>
          <Collapse in={isExpanded} timeout="auto" unmountOnExit>

            {CommentQueryStatus}
            <Timeline sx={{
        [`& .${timelineItemClasses.root}:before`]: {
          flex: 0,
          padding: 0,
        },
      }}>
              <CommentInput idContent={idContent} idComment={_id} onNewData={addNewData} />
              {
                result.isSuccess ? (
                  
                  items.map((reply: any) => (
                    <Comment key={reply._id} {...reply} removeElement={removeElements}   idContent={idContent} numReplies={reply.nReplies==undefined || reply.nReplies==null ? 0 : reply.nReplies} level={level + 1} />
                  ))
                ) : (

                  null
                )
                
              }

              {
                result.isSuccess && result.data.currentPage < result.data.totalPages ? (
                  <Button size="small" onClick={fetchMoreData} variant="text" sx={{mt:2}} color="primary">
                    Show more replies
                  </Button>
                ):(null)
              }
              
            </Timeline>

          </Collapse>


        </TimelineContent>
      </TimelineItem>
      {/*<Box mb={2}>
    <Card sx={{ mb: 2 }}>      
      <CardHeader
        avatar={<Avatar sx={{ bgcolor: red[500] }}>{author.username.charAt(0)}</Avatar>}
        title={'@'+author.username}
        subheader={created_at.toLocaleString()}
      />
      <CardContent>
        <Typography variant="body2" color="text.secondary">
          {text}
        </Typography>
      </CardContent>
      <CardActions disableSpacing>
        <IconButton onClick={handleExpandClick} aria-expanded={isExpanded} aria-label="ver respuestas">
          <CommentIcon />
        </IconButton>
        <Typography variant="caption" color="text.secondary">
          {likes} likes
        </Typography>
        {isTopLevel && (
                <IconButton size="small">
                  <Favorite fontSize="small" />
                </IconButton>
          )}
        <IconButton aria-label="eliminar" onClick={handleRemoveClick} sx={{ ml: "auto"}}>
          <DeleteIcon sx={{ color: pink[500] }} />
        </IconButton>
        <IconButton aria-label="expandir" onClick={handleExpandClick} sx={{ ml: "auto", transform: isExpanded ? "rotate(180deg)" : "rotate(0deg)", transition: (theme:any) => theme.transitions.create("transform") }}>
          <ExpandMoreIcon />
        </IconButton>
      </CardActions>
      <Collapse in={isExpanded} timeout="auto" unmountOnExit>
        
        {CommentQueryStatus}
        {
          result.isSuccess ? (            
            result.data.comments.map((reply:any) => (
              <Comment key={reply.id} {...reply} idContent={idContent} level={level + 1} />
            ))
          ):(
            
            null
          )
        }
        <CardContent>
        <CommentInput idContent={idContent} idComment={_id} onNewData={()=>{
            trigger({page, idComment:String(_id)})
          }} />
        </CardContent>
      </Collapse>
    </Card>
    </Box>
      */}
    </>
  );
}

export default Comment;