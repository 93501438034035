const FontColor = [
              '#000000',
              '#FAF7F6',
              '#fbe77c',
              '#00e8fb',
              '#e14900',
              '#53235f',
              '#c6768e',
              '#24201d',
              '#464646',
              '#e33a2d',
              '#ffd603',
              '#379d8f',
              '#16776c',
              '#55b355',
              '#b5b70f',
              '#dac711',
              '#eac445',
              '#da8023',
              '#d22e04',
              '#b30916',
              '#dc2438'
]

export default FontColor