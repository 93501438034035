const BgColor = [
              '#000000',
              '#2d5f2e',
              '#fddab0',
              '#442628',
              '#353535',
              '#421f57',
              '#01b1af',
              '#27c1b7',
              '#27c1b7',
              '#b1b901',
              '#091128',
              '#dddddd',
              '#f4e8e8',
              '#2b2b2b',
              '#e315fd',
              '#24bce3',
              '#b74248',
              '#691547',
              '#74020c',
              '#150858',
              

]

export default BgColor