import { Paper, InputLabel, Tabs,Tab, Box, Typography, Badge, Chip, TextField, Stack, ButtonGroup, Button, Autocomplete, Select, MenuItem } from '@mui/material';
import React from 'react';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import Slider from "react-slick";
import { ContentInfo, edit } from '../../_redux/slices/creator/creator.slice';
import { useNavigate } from "react-router-dom";
import ErrorMessage from '../../shared/components/ErrorMessage'
import { useAddNewContentMutation } from '../../_redux/slices/API/content.service'
import Loading from '../../shared/components/Loading'
import FontsData from '../../_entities/FontsData'
import FontColor from '../../_entities/FontColor'
import BgColor from '../../_entities/BgColor'
import Switch from '@mui/material/Switch';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import { amber } from '@mui/material/colors';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import ISO6391 from 'iso-639-1';
import InputHashtags from './components/inputHashtags';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
const CreatorScreen = () => {
  const languages_names = ISO6391.getAllNames()
  const [addNewContent, response] = useAddNewContentMutation()
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState(0);
  const { contentInfo } = useSelector((state:any) => state.creator)
  const dispatch = useDispatch()
  const navigate = useNavigate();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const submitNewContent = () => {  
    console.log(contentInfo)
    addNewContent(contentInfo)
    .unwrap()
    .then(() => {
      return navigate("/profile")
    })
    .catch((error:any) => {      
      console.log(error,'ERROR/// :(')      
    })    
  }

  const handleChangeContentLanguage = (event: any) => {
    
    dispatch(edit(
      {
        language: ISO6391.getCode(event.target.value)
      }
      ))
  }

  let infoComponent
  if(response.isLoading){
    infoComponent = (<Loading/>)
  }else if (response.isError){
    infoComponent = (<ErrorMessage message={response.error} />)
  }

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleChangeBgColor = (event: React.SyntheticEvent, newValue: number) => {
    dispatch(edit(
      {

        bgColor:newValue
      
      }
    ))
  };

  const handleChangeFontColor =  (event: React.SyntheticEvent, newValue: number) => {
    if(contentInfo==null){
        return
    }

    if(contentInfo.focusIn == 'title' ){
      dispatch(edit({
        title:{
          ...contentInfo.title,          
          color:newValue
        }
  
      }));
    }

    if(contentInfo.focusIn == 'author' ){
      dispatch(edit({
        author:{
          ...contentInfo.author,          
          color:newValue
        }
  
      }));
    }

    if(contentInfo.focusIn == 'content' ){
      dispatch(edit({
        content:{
          ...contentInfo.content,          
          color:newValue
        }
  
      }));
    } 

  }
  const handleChangeIndex = (index: number) => {
    setValue(index);
  };


  const getContentInfoFontColor = (contentInfo:ContentInfo) =>{
    console.log(contentInfo)
    if(contentInfo == null){
      return 0
    }
    if(contentInfo.focusIn == 'title' ){
      if(contentInfo.title == null){
        return 0  
      }
      return contentInfo.title.color
    }

    if(contentInfo.focusIn == 'author' ){
      if(contentInfo.author == null){
        return 0  
      }
      return contentInfo.author.color
    }

    if(contentInfo.focusIn == 'content' ){
      if(contentInfo.content == null){
        return 0  
      }
      return contentInfo.content.color
    }

    return 0
  }

  const getContentInfoBgColor = (contentInfo:ContentInfo) =>{
    if(contentInfo == null){
      console.log("0 ///////////////////////")
      return 0
    }
    console.log(contentInfo.bgColor,"*************")
    return contentInfo.bgColor
  }

  const onFocusTitle= (e:any) =>{
    console.log("title focus")
    dispatch(edit({

        focusIn:"title"
      
    }))
  }

  const onFocusContent= (e:any) =>{
    console.log("content focus")
    dispatch(edit({

        focusIn:"content"
      
    }))
  }

  const onFocusAuthor= (e:any) =>{
    console.log("author focus")
    dispatch(edit({

        focusIn:"author"
      
    }))
  }


  const changeTextTitle = (e:any) =>{
    const value = e.target.value
    dispatch(edit({
        title:{
          ...contentInfo.title,          
          text:value
        }
  
      }));
  }

  const changeTextContent = (e:any) =>{
    const value = e.target.value
    dispatch(edit({
        content:{
          ...contentInfo.content,          
          text:value
        }
  
      }));
  }

  const changeTextAuthor = (e:any) =>{
    const value = e.target.value
    dispatch(edit({
        author:{
          ...contentInfo.author,          
          text:value
        }
  
      }));
  }

  const getContentInfoFont = (contentInfo:ContentInfo) =>{
    console.log(contentInfo)
    if(contentInfo == null){
      return 0
    }
    if(contentInfo.focusIn == 'title' ){
      if(contentInfo.title == null){
        return 0  
      }
      return contentInfo.title.font
    }

    if(contentInfo.focusIn == 'author' ){
      if(contentInfo.author == null){
        return 0  
      }
      return contentInfo.author.font
    }

    if(contentInfo.focusIn == 'content' ){
      if(contentInfo.content == null){
        return 0  
      }
      return contentInfo.content.font
    }

    return 0
  }

  const handleChangeFont = (event: any, newValue: number) =>{    
    if(contentInfo==null){
        return
    }

    if(contentInfo.focusIn == 'title' ){
      dispatch(edit({
        title:{
          ...contentInfo.title,          
          font:newValue
        }
  
      }));
    }

    if(contentInfo.focusIn == 'author' ){
      dispatch(edit({
        author:{
          ...contentInfo.author,          
          font:newValue
        }
  
      }));
    }

    if(contentInfo.focusIn == 'content' ){
      dispatch(edit({
        content:{
          ...contentInfo.content,          
          font:newValue
        }
  
      }));
    } 
  }

  /* const handleChangePremium = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(edit({
      isPremium:event.target.checked
    }));};*/

  const handleChangePubiclyVisible = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(edit({
      isPubiclyVisible: event.target.checked
    }))
  }

  const onChangeTags = (e:any,value:any | null) =>{
    dispatch(edit({
      tags:value
    }))
  }
  
  const handleHashtagsChange = (hashtags:any[]) => {
    
    dispatch(edit({ 

        hashtags
      
    }))
  };



  const getFocusInText = (content:any) => {
    if(content.focusIn == 'title'){
      return 'For Title'
    }else if(content.focusIn == 'content'){
      return 'For Content'
    }else{
      return 'For Author'
    }
  }
  return (
    <>

      <Paper elevation={3} className='overflow-auto  p-2 m-2 mt-6 ' style={{height:"75vh"}}>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={2}
          className='mb-36 mt-11'
        >

          <TextField 
          id={"standard-basic-0 "+FontsData[contentInfo.title.font]} 
          onChange={changeTextTitle} autoFocus={contentInfo.focusIn == "title"} 
          onFocus={onFocusTitle} className='w-72' 
          label="Title(Optional)" 
          defaultValue={contentInfo.title.text == null ? 'New story' : contentInfo.title.text} 
          variant="standard"/>

          <TextField
            id="standard-multiline-static"
            label="Phrase or short story *"
            multiline
            rows={12}
            variant="standard"
            className="w-72 font-['atwriter']"
            autoFocus={contentInfo.focusIn == "content"}
            onFocus={onFocusContent}
            onChange={changeTextContent}
            defaultValue={contentInfo.content.text == null ? '' : contentInfo.content.text}
          />
          <TextField id="standard-basic"
            defaultValue={contentInfo.author.text == null ? '' : contentInfo.author.text}
           onChange={changeTextAuthor} autoFocus={contentInfo.focusIn == "author"} onFocus={onFocusAuthor} className='w-72' label="Author(Optional)" variant="standard" />
          <InputLabel id="language-label">Language</InputLabel>
          {contentInfo.language}
          <Select
            labelId='language-label'
            id='language'
            label="Language"
            value={contentInfo.language}
            onChange={handleChangeContentLanguage}
            autoFocus={contentInfo.focusIn == "language"}
          >
            {
              languages_names.map((item:any, index:any) => <MenuItem value={item} key={index}>{item}</MenuItem>)
            }
            
          </Select>


              <InputHashtags getHashtags={handleHashtagsChange}  getError={(error)=>{
              }}/>
        {/*  <Stack direction="row" spacing={1} alignItems="center">        
          <StarBorderIcon sx={{ color: amber[500] }}/>                                                        
          <Typography>Free</Typography>
            <Switch
              checked={contentInfo.isPremium}
              onChange={handleChangePremium}
              inputProps={{ 'aria-label': 'controlled' }}
            /> 
          <Typography>Premium</Typography>
          <StarIcon  sx={{ color: amber[500] }}/>
        </Stack> */}

        <Stack direction="row" spacing={1} alignItems="center">        
            <StarBorderIcon sx={{ color: amber[500] }}/>                                                        
            <Typography>Pi</Typography>
            <Switch
              checked={contentInfo.isPubiclyVisible}
              onChange={handleChangePubiclyVisible}
              inputProps={{ 'aria-label': 'controlled' }}
            /> 
          <Typography>Publicly Visible</Typography>
          <StarIcon  sx={{ color: amber[500] }}/>
        </Stack>

          <ButtonGroup variant="outlined"   aria-label="outlined button group">
            <Button onClick={handleClickOpen}>Preview</Button>
          </ButtonGroup>
        </Stack>
      </Paper>

      <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>

        <Tabs value={value} onChange={handleChange} centered>
          <Tab label="Color" />
          <Tab label="Font" />
          <Tab label={
            <IconButton
              edge="start"
              color="inherit"              
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          }/>
        </Tabs>
        <TabPanel value={value} index={0}>
          
            <div>
              <h3 className=''>Background color [All]</h3>
              <Tabs
                value={getContentInfoBgColor(contentInfo)}
                onChange={handleChangeBgColor}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
              >
              {
                  BgColor.map((bgcolor)=>{
                    return (
                      <Tab label={
                  <Chip label="-" style={{backgroundColor:bgcolor}} />
                  
                } className="tab-color-width" />
                    )
                  })
                }
                
                
              </Tabs>
            </div>
            <div>
              <h3 className=''>Font color [{getFocusInText(contentInfo)}]</h3>
              <Tabs
                value={getContentInfoFontColor(contentInfo)}
                onChange={handleChangeFontColor}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
              >
                {
                  FontColor.map((fnColor)=>{
                    return (
                      <Tab label={
                  <Chip label="-" style={{backgroundColor:fnColor}} />
                  
                } className="tab-color-width" />
                    )
                  })
                }
              </Tabs>
            </div>
        </TabPanel>
        <TabPanel value={value} index={1}>
            <div>
              <h3 className=''>Font Family [{getFocusInText(contentInfo)}]</h3>
              <Tabs
                value={getContentInfoFont(contentInfo)}
                onChange={handleChangeFont}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
              >
                {
                  FontsData.map((font)=>{
                    return (
                      <Tab label={
                
                                  <h3 className={font}>Hello</h3>
                                } />
                    )
                  })
                }
                
              </Tabs>
            </div>
        </TabPanel>

      </Paper>


      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
      >
       <DialogTitle id="dialog-title">
          <AppBar>
             <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                Preview
              </Typography>
              <Button autoFocus color="inherit" onClick={submitNewContent}>
                Show to world
              </Button>
            </Toolbar> 
    
          
          </AppBar>
        </DialogTitle> 

          <DialogContent className="flex mt-20 flex-col items-center justify-center h-screen">
          <figure style={{backgroundColor:BgColor[contentInfo.bgColor]}}  className="flex flex-col items-center justify-center h-full ">
                    {/* <img className="w-24 h-24 md:w-48 md:h-auto md:rounded-none rounded-full mx-auto" src="/sarah-dayan.jpg" alt="" width="384" height="512"/> */}
                    <div className="max-w-3xl mx-auto px-6 py-4 text-center shadow-md rounded-md overflow-auto scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-transparent">
                    <h3 className={'text-4xl '+FontsData[contentInfo.title.font]}
                             style={{color:FontColor[contentInfo.title.color]}}
                             >{contentInfo.title.text}</h3>
                        {infoComponent}     
                        <blockquote>

                            <p
                              
                             className={" text-3xl font-medium "+FontsData[contentInfo.content.font]}
                            style={{color:FontColor[contentInfo.content.color]}}
                            >
                                
                              {contentInfo.content.text}                                
                            </p>
                        </blockquote>
                        <figcaption className={"text-2xl font-medium " + FontsData[contentInfo.author.font]}>
                            <div className="" style={{color:FontColor[contentInfo.author.color]}}>
                                {contentInfo.author.text}
                            </div>
                        </figcaption>
                    </div>
                </figure>
        
          </DialogContent>          
      </Dialog>
    </>
  );
}




export default CreatorScreen;