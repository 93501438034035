import React, { useEffect, useState } from 'react'
import { useLazyGetNewsQuery } from '../../_redux/slices/API/news.service';
import InfiniteScroll from 'react-infinite-scroll-component';
import ErrorMessage from '../../shared/components/ErrorMessage';


import Timeline from '@mui/lab/Timeline';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import Loading from '../../shared/components/Loading';
import NewsItem from './components/NewsItem';
import { Card, Typography } from '@mui/material';

export enum TYPE_INTERACTION {
  like,
  comment,
  reply,
  follow,
}

export enum TYPE_ELEMENT {
  author,
  content,
  comment
}

function NewsScreen() {
  const [page, setPage] = useState(1);
  const [items, setItems] = useState<any>([]);

  const [
    trigger,
    result
  ] = useLazyGetNewsQuery()

  const fetchMoreData = () => {
    // Llame a su API aquí para obtener los siguientes elementos
    // Añada los nuevos elementos al estado `items`
    // Si no hay más elementos, actualice el estado `hasMore` a `false`
    console.log('try fetching more...', result.data.totalPages)
    if (page < result.data.totalPages) {
      console.log('try fetching more...', page)
      setPage((prevPage: any) => prevPage + 1);
    }

  };


  useEffect(() => {
    console.log('pafge change')
    trigger({ page }, false)
  }, [page])


  useEffect(() => {
    if (result) {
      if (result.data && !result.isFetching) {
        if (result.data.news) {
          if (result.data.news.length > 0) {
            console.log('data new ', result)
            setItems((prevContent: any) => prevContent.concat(...result.data.news))
          }
        }
      }
    }

  }, [result.data, result.isFetching])

  let newsView
  if (result.isLoading) {
    newsView = (
      <Loading />
    )
  } else if (result.isSuccess) {
    newsView = (
      <>
        <Timeline position="right" sx={{
          [`& .${timelineItemClasses.root}:before`]: {
            flex: 0,
            padding: 0,
          },
        }}>
          {items.map(({ _id,
            type_interaction,
            type_element,
            receptor,
            sender,
            is_readed,
            content_id,
            comment_id,
            created_at }: any) => (
            <NewsItem
              key={_id}
              type_interaction={type_interaction}
              type_element={type_element}
              receptor={receptor}
              sender={sender}
              created_at={created_at}
              is_readed={is_readed}
              _id={_id}
              content_id={content_id}
              comment_id={comment_id}

            />
          ))}

        </Timeline>
      </>
    )
  } else if (result.isError) {
    newsView = (
      <ErrorMessage message={result.error}></ErrorMessage>
    )
  }
  return (
    <>
    
                    <div className='grid grid-cols-1 md:grid-cols-9' >
                    
                    <div className='hidden md:block'>
                    <div className='m-2'>
                        <img className="w-48 max-w-xs" src="../_assets/logoBlack.png" alt="Quotless" />
                            <h1  className=" text-center text-6xl font-semibold text-gray-800  font-lora hidden">Quotless</h1>
                        </div>
                    </div>            
           
                    <div className='col-start-4 col-span-3' >                
                    
    <div className='flex flex-col md:w-full w-screen h-screen justify-center'>
      <Card variant="outlined" className='p-2 m-2'>
        <Typography gutterBottom variant="h5" component="div">
          News
        </Typography>
        <InfiniteScroll
          dataLength={items.length}
          next={fetchMoreData}
          hasMore={result.isSuccess ? page < result.data.totalPages : false}
          loader={<h4>Loading...</h4>}
          endMessage={<h4>There are no more news</h4>}
          height={500}
          scrollableTarget="dialog-content"
        >
          <div id="dialog-content">
            {newsView}
          </div>
        </InfiniteScroll>
      </Card>
    </div>
                    </div>
                    <div className='hidden md:block'></div>
                    </div>



    </>
  )
}

export default NewsScreen