import React, { useEffect, useState } from 'react';
import Modal from '@mui/material/Modal';
import { Box } from '@mui/system';
import { authFirebase, createUserLoginEmailPasswordFirebase, listenEventForUserEvents, mapErrorCodeToMessage } from '../../firebase';
import { useDispatch } from 'react-redux';
import { reset, signPassword } from '../../_redux/slices/auth/auth.slice';
import { Alert } from '@mui/material';
import { FirebaseError } from 'firebase/app';
import { Unsubscribe } from 'firebase/firestore';
import Loading from '../../shared/components/Loading';

interface ErrorMessageState { errorCode: string | Number, message: string }
const RegisterModal = ({ isOpen, onClose }: any) => {
    const dispatch = useDispatch()
    const [userFirebaseId, setUserFirebaseId] = useState<null | string>(null)
    const [accessToken, setAccessToken] = useState<null | string>(null)
    const [isRegisterLoading, setIsRegisterLoading] = useState<any>(false)
    const [errorMessage, setErrorMessage] = useState<null | ErrorMessageState>(null)
    const [newCredentials, setNewCredentials] = useState({
        email: '',
        password: '',
        confirmPassword: ''
    })

    const [errors, setErrors] = useState({ email: '', password: '', confirmPassword: '' });
    const handleSubmitNewUser = () => {

        createUserLoginEmailPasswordFirebase(authFirebase, newCredentials.email, newCredentials.password)
            .then(async (userCredential) => {
                // Signed in 
                const user = userCredential.user;
                const token = await user.getIdToken();
                console.log(user)

                setErrorMessage(null)

                setAccessToken(token)
                setUserFirebaseId(user.uid)
            })
            .catch((error: FirebaseError) => {
                const errorCode = error.code;
                const message = error.message.replace('Firebase: ', '');
                console.log(message)
                dispatch(reset({}))
                setErrorMessage({
                    errorCode,
                    message: mapErrorCodeToMessage(errorCode)
                })
                // ..
            });

    }


    useEffect(() => {
        let unsubs: null | Unsubscribe = null
        if (userFirebaseId) {
            setIsRegisterLoading(true)
            unsubs = listenEventForUserEvents(userFirebaseId, (doc) => {
                if (doc) {
                    setIsRegisterLoading(false)

                    //change state user auth


                    dispatch(signPassword({
                        userInfo: {
                            uid: doc.eventData.mongoId,
                            username: doc.eventData.username,
                            language_preferences: doc.eventData.language_preferences,

                        },
                        userToken: accessToken,
                    }))
                }

            })
        }

        // Limpia el listener cuando el componente se desmonta
        return () => {
            if (unsubs != null) {
                unsubs();
            }
        };
    }, [userFirebaseId])




    const handleChangeFrmNewUser = (e: any) => {
        const { name, value } = e.target;
        setNewCredentials({
            ...newCredentials,
            [name]: value
        });

        // Validación del correo electrónico
        if (name === 'email') {
            if (!value) {
                setErrors({ ...errors, email: 'Email field cannot be empty' });
            } else if (!/\S+@\S+\.\S+/.test(value)) {
                setErrors({ ...errors, email: 'Enter a valid email address' });
            } else {
                setErrors({ ...errors, email: '' });
            }
        }

        // Validación de la contraseña
        if (name === 'password') {
            if (!value) {
                setErrors({ ...errors, password: 'Password field cannot be empty' });
            } else {
                setErrors({ ...errors, password: '' });
            }
        }

        // Validación de la repetición de la contraseña
        if (name === 'confirmPassword') {
            if (value !== newCredentials.password) {
                setErrors({ ...errors, confirmPassword: 'Passwords do not match' });
            } else {
                setErrors({ ...errors, confirmPassword: '' });
            }
        }
    };
    // Verificar si hay errores de validación
    const hasErrors = Object.values(errors).some(error => error !== '');

    return (
        <Modal
            open={isOpen}
            onClose={onClose}
            aria-labelledby="register-modal-title"
            aria-describedby="register-modal-description"
        >
            <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', bgcolor: 'Background', boxShadow: 24, p: 4, width: 400 }}>
                <h2 id="register-modal-title" className='flex text-center text-lg font-bold'>Create new account</h2>
                <div id="register-modal-description">
                    <form>

                        {
                            errorMessage ?
                                <Alert variant="outlined" severity="warning">
                                    {errorMessage.message}
                                </Alert> :
                                (null)
                        }
                        <div className="mb-4">
                            <label htmlFor="email" className="block text-gray-700 text-sm font-bold mb-2">Email</label>


                            <input type="email" id="EmailAddress" name="email"
                                value={newCredentials.email}
                                onChange={handleChangeFrmNewUser}
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                placeholder="Enter your email" />

                            {errors.email && <div style={{ color: 'red' }}>{errors.email}</div>}
                        </div>
                        <div className="mb-4">
                            <label htmlFor="password" className="block text-gray-700 text-sm font-bold mb-2">Password</label>

                            <input type="password" id="Password"
                                name="password"
                                value={newCredentials.password}
                                onChange={handleChangeFrmNewUser}
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" placeholder="Enter your password" />

                            {errors.password && <div style={{ color: 'red' }}>{errors.password}</div>}
                        </div>

                        <div className="mb-4">
                            <label htmlFor="confirmPassword" className="block text-gray-700 text-sm font-bold mb-2">Confirm password</label>

                            <input id="confirmPassword"
                                name="confirmPassword"
                                value={newCredentials.confirmPassword}
                                onChange={handleChangeFrmNewUser}
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type="password" placeholder="Enter your password" />


                            {errors.confirmPassword && <div style={{ color: 'red' }}>{errors.confirmPassword}</div>}
                        </div>

                        <div className="mb-4">
                            <label className="text-sm text-gray-700" htmlFor="terms">
                                When registering, you agree to Quotless'
                                <a href='/termsandconditions.html' className="underline">
                                    Terms and Conditions
                                </a> and <a href='/privacypolicy.html' className="underline">
                                    Privacy Policy
                                </a>.
                            </label>
                        </div>
                        {
              !isRegisterLoading ? (
<button type="button" disabled={hasErrors} onClick={handleSubmitNewUser} className={`bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline ${hasErrors ? "bg-slate-400" : "bg-sky-500"}`}>Join Now</button>

              ) : (
                <Loading />
              )
            }
                        
                    </form>
                </div>
            </Box>
        </Modal>
    );
};

export default RegisterModal;