import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import Account from '../../Account';
import Footer from './Components/Footer'

const LoginScreen = () => {
    const { userInfo } = useSelector((state:any) => state.auth)
    if (userInfo) {
        return (
          <Navigate to="/"/>
        )
    }
    return ( 
        <>
        <div className='relative w-screen'>
            <Account/>            
        </div>
            <div className=' inset-x-0  bottom-8 text-center'>
                <Footer />
            </div>
        </>
     );
}
 


export default LoginScreen;