import { ChangeEvent, FormEvent, useState } from "react"
import { useSearchParams, useNavigate } from "react-router-dom"
import { authFirebase, confirmPasswordResetFirebase, mapErrorCodeToMessage } from "../../firebase"
import { Alert } from "@mui/material"


const defaultFormFields = {
    password: '',
    confirmPassword: '',
}

interface ErrorMessageState { errorCode: string | Number, message: string }


function PasswordReset() {
    /**
     * Extract oobCode from the URL.
     * Delete console.log in production.
     */
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const [successMessage, setSuccessMessage] = useState(false)
    const [errorMessage, setErrorMessage] = useState<null | ErrorMessageState>(null)

    const [formFields, setFormFields] = useState(defaultFormFields)
    const { password, confirmPassword } = formFields
    const [errors, setErrors] = useState({ password: '', confirmPassword: '' });

    let oobCode: string | null = searchParams.get('oobCode')

    const resetFormFields = () => {
        return (
            setFormFields(defaultFormFields)
        )
    }

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()



        try {
            if (oobCode && confirmPassword) {
                await confirmPasswordResetFirebase(authFirebase, oobCode, confirmPassword)
                resetFormFields()
                setSuccessMessage(true)
                setErrorMessage(null)
            } else {
                //alert('Something is wrong; try again later!')
                console.log('missing oobCode')
                setErrorMessage({
                    errorCode:'something is wrong',
                    message:'Something is wrong; try again later!'
                })
                setSuccessMessage(false)
            }
        } catch (error: any) {
            const errorCode = error.code;
                const message = error.message.replace('Firebase: ', '');
                console.log(message)

                setErrorMessage({
                    errorCode,
                    message: mapErrorCodeToMessage(errorCode)
                })
            setSuccessMessage(false)
            console.log(error.message)
        }
    }

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target
        setFormFields({ ...formFields, [name]: value })

        // Validación de la contraseña
        if (name === 'password') {
            if (!value) {
                setErrors({ ...errors, password: 'Password field cannot be empty' });
            } else {
                setErrors({ ...errors, password: '' });
            }
        }

        // Validación de la repetición de la contraseña
        if (name === 'confirmPassword') {
            if (value !== password) {
                setErrors({ ...errors, confirmPassword: 'Passwords do not match' });
            } else {
                setErrors({ ...errors, confirmPassword: '' });
            }
        }
    }
    // Verificar si hay errores de validación
    const hasErrors = Object.values(errors).some(error => error !== '');
    return (
        <div>
            <div className="bg-amber-100 min-h-screen flex items-center justify-center">

                <div className='absolute'>

                    <div className='flex flex-col'>
                        <div className='mb-8'>
                            <img className="w-96 max-w-xs" src="../_assets/logoBlack.png" alt="Quotless" />
                            <h1 className=" text-center text-6xl font-semibold text-gray-800  font-lora hidden">Quotless</h1>
                        </div>
                    </div>
                    {
                        successMessage ?

                            <Alert variant="outlined" severity="success">
                                <div>
                                    <h3>Success! Your Password change successfully</h3>
                                    <button
                                        onClick={() => navigate('/')}
                                    >
                                        Go to the Login page
                                    </button>
                                </div>
                            </Alert>
                            :
                            <form onSubmit={handleSubmit} className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">

                                <h2 id="register-modal-title" className='flex text-center text-lg font-bold'>Change Your Password and Unlock New Opportunities!</h2>
                                <p>
                                    We have successfully processed your request to reset your password. Please enter a new password below:
                                </p>
                                {
                                    errorMessage ?
                                        <Alert variant="outlined" severity="warning">
                                            {errorMessage.message}
                                        </Alert> :
                                        (null)
                                }
                                <div className="mb-4">
                                    <label htmlFor="password" className="block text-gray-700 text-sm font-bold mb-2">Password</label>

                                    <input
                                        type="password"
                                        name="password"
                                        value={password}
                                        onChange={handleChange}
                                        placeholder="New Password"
                                        required
                                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />

                                    {errors.password && <div style={{ color: 'red' }}>{errors.password}</div>}
                                </div>

                                <div className="mb-4">
                                    <label htmlFor="confirmPassword" className="block text-gray-700 text-sm font-bold mb-2">Confirm password</label>

                                    <input id="confirmPassword"
                                        type='password'
                                        name='confirmPassword'
                                        value={confirmPassword}
                                        onChange={handleChange}
                                        placeholder="Confirm Password"
                                        required
                                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />


                                    {errors.confirmPassword && <div style={{ color: 'red' }}>{errors.confirmPassword}</div>}
                                </div>
                                <button type="submit" disabled={hasErrors} className={`bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline ${hasErrors ? "bg-slate-400" : "bg-sky-500"}`}>Confirm Password Change</button>
                            </form>
                    }
                </div>
            </div>

        </div>
    )
}

export default PasswordReset