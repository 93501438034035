import React from 'react'
import Badge from '@mui/material/Badge';
import CommentIcon from '@mui/icons-material/Comment';
import IconButton from '@mui/material/IconButton';
import { amber } from '@mui/material/colors';
import CommentView from '..';
type CommentWithBadgeIconProps = {
    item:any,
    openValue?:boolean
}
function CommentWithBadgeIcon({item, openValue}:CommentWithBadgeIconProps) {
    
    const [openCommentView, setOpenCommentView] = React.useState((openValue!=null || openValue!=undefined) ? openValue : false);

    function showCommentView(mainContentId:string) {
        setOpenCommentView(true)
    }

    function closeCommentView() {
        setOpenCommentView(false)
    }
  return (
    <>
    <Badge color="primary" overlap="circular" anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }} badgeContent={item.comments.length} max={999}>  
    <IconButton onClick={()=> showCommentView(item._id)} aria-label="smsicon">
        <CommentIcon fontSize="inherit" sx={{ color: amber[200] }}/>                
    </IconButton>
    </Badge>
    {
                    openCommentView ? (<CommentView  idContent={item._id} onClose={closeCommentView} open={openCommentView}/>):(null)
    } 
    </>
    
  )
}

export default CommentWithBadgeIcon