import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
// Make TS accept the existence of our window.__ENV object - defined in index.html:
interface WindowWithEnv extends Window {
    __ENV?: {
      backendURL: string, // REACT_APP_BACKEND_URL environment variable
      sandbox: "true" | "false", // REACT_APP_SANDBOX_SDK environment variable - string, not boolean!
    }
}
const _window: WindowWithEnv = window;
const backendURL = _window.__ENV && _window.__ENV.backendURL;

export const apiAuthorSlice = createApi({
  reducerPath: 'apiAuthorSlice',
  baseQuery: fetchBaseQuery({   
    
    baseUrl: backendURL,
    prepareHeaders: (headers, { getState }:any) => {
      const token = getState().auth.userToken
      if (token) {        
       // include token in req header
        headers.set('authorization', `Bearer ${token}`)  
        return headers
      }
    }
  }),
  tagTypes: ['Author'],
  endpoints: (builder) => ({
    getAuthor: builder.query({
      query: ({id,page}) => ({
        url: '/author/'+id+'/contents/'+page,
        method: 'GET',     
      })      
    }),
    getAuthorMe: builder.query({
      query: ({page}) => ({
        url: '/author/'+page+'/me/contents',
        method: 'GET',     
      })      
    }),
    getSubs: builder.query({
      query: ({page}) => ({
        url: '/author/subs/all/'+page,
        method: 'GET',     
      }),
    }),
    getMeSubs: builder.query({
      query: ({page}) => ({
        url: '/author/subs/me/'+page,
        method: 'GET',     
      }),
    }),
    addSubsToAuthor: builder.mutation({
      query: (id) => ({
        url: '/author/'+id+'/subs',
        method: 'POST', 
        body: {},
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },    
      }),
    }), 
    removeSubsToAuthor: builder.mutation({
      query: (id) => ({
        url: '/author/'+id+'/subs',
        method: 'DELETE',     
      }),
    }),  
    updateProfileInfo: builder.mutation({
      query: (payload) => ({
        url: 'author/update/profile',
        method: 'PUT',
        body: payload,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      })
    }),
    getProfileInfo: builder.query({
      query: (id) => ({
        url: 'author/'+id+'/profile',
        method: 'GET',
      })
    })
  }),
   
})
export const { 
  useGetSubsQuery, 
  useGetMeSubsQuery, 
  useGetAuthorQuery, 
  useGetAuthorMeQuery, 
  useAddSubsToAuthorMutation,
  useRemoveSubsToAuthorMutation, 
  useUpdateProfileInfoMutation, 
  useLazyGetProfileInfoQuery } = apiAuthorSlice