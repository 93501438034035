import React, { useEffect } from 'react';
import { Routes, Route, useParams, useNavigate, useLocation } from 'react-router-dom';
import { useGetContentQuery, useAddContentToFavoriteMutation, useRemoveContentToFavoriteMutation } from '../../_redux/slices/API/content.service'
import Loading from '../../shared/components/Loading'
import ErrorMessage from '../../shared/components/ErrorMessage'
import FontsData from '../../_entities/FontsData'
import FontColor from '../../_entities/FontColor'
import BgColor from '../../_entities/BgColor'
import PremiumIcon from '../../shared/components/PremiumIcon'
import Badge from '@mui/material/Badge';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import IconButton from '@mui/material/IconButton';
import Chip from '@mui/material/Chip';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import { pink } from '@mui/material/colors';
import PageViewTracker from '../../Statistics/PageViewTracker';
import CommentWithBadgeIcon from '../../CommentView/components/CommentWithBadgeIcon';
import { TYPE_INTERACTION } from '../NewsScreen';
import { ALIGN_TEXT_VALUES, DECORATION_LINE_TEXT_VALUES, ITALIC_TEXT_VALUES, SIZE_TEXT_VALUES, WEIGHT_TEXT_VALUES } from '../../_redux/slices/editor/editor.slice';
import { Mention, MentionsInput } from 'react-mentions';
import styleEditor from '../../_components/editor/editor.styles';
import { formatNumber } from '../../shared/utils/number.util';
import OptionsIcon from '../../ReportView/components/OptionsIcon';
import { ChangeFormatDate } from '../../shared/utils/date.util';
import ItemMenuContainer from './ItemMenuContainer';

interface ContentStory {

  text: string | null,
  font: number,
  color: number,
  align: number,
  size: number,
  weight: number
  italic: number
  decorationLine: number

}
const LinkToCompleteView = (props: any) => {

  const {
    item
  } = props
  return (
    <>
      {item.isPremium && item.nLettersPremium != undefined && item.nLettersPremium != null ?
        (<Chip label={item.nLettersPremium + ' letters premium'} color="primary" size="small" variant="outlined" />) : null
      }
    </>
  )
}
const DetailScreen = () => {
  const navigate = useNavigate();
  let { idContent } = useParams();
  let { state } = useLocation()


  const { data: contentInfo, isLoading, isSuccess, isError, error, isFetching, refetch } = useGetContentQuery(
    { id: idContent }
  );


  const [addContentToFavorite, responseAddFavorite] = useAddContentToFavoriteMutation()
  const [removeContentToFavorite, responseRemoveFavorite] = useRemoveContentToFavoriteMutation()

  const gotoProfile = (item: any) => {
    //TODO: Pasar esta funcion (gotoProfile) a una archivo de utils o hooks
    console.log('go to profile')
    if (state.userId == item._id) {
      navigate(-1)
    } else {

      navigate(`/profile/${item._id}`);
    }

  }

  const addFavoriteAction = (id: string) => {
    addContentToFavorite(id)
      .unwrap()
      .then(() => {

        refetch()
      })
      .catch((error: any) => {
        console.log(error, 'ERROR/// :(')

      })
  }

  const removeFavoriteAction = (id: string) => {
    removeContentToFavorite(id)
      .unwrap()
      .then(() => {
        refetch()
      })
      .catch((error: any) => {
        console.log(error, 'ERROR/// :(')
      })
  }


  function verifyColorFont(colorF: string, colorBg: string) {
    if (colorF == '#000000' && colorBg == '#000000') return '#FAF7F6'
    return colorF
  }

  function stringToColor(string: string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

  function stringAvatar(name: string) {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      //children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
      children: `${name[0]}${name[Math.round(name.length / 2)]}`,
    };
  }

  const renderSuggestion = (suggestion: any, search: any, highlightedDisplay: any) => {
    // Lógica para personalizar la representación de cada sugerencia
    return <div>{highlightedDisplay} - {formatNumber(suggestion.usageCount != undefined || suggestion.usageCount != null ? suggestion.usageCount : 0)}</div>;
  };

  let infoComponent
  if (responseAddFavorite.isLoading) {
    infoComponent = (<Loading />)
  } else if (responseAddFavorite.isError) {
    infoComponent = (<ErrorMessage message={responseAddFavorite.error} />)
  }
  if (responseRemoveFavorite.isLoading) {
    infoComponent = (<Loading />)
  } else if (responseRemoveFavorite.isError) {
    infoComponent = (<ErrorMessage message={responseRemoveFavorite.error} />)
  }
  let viewContent
  if (isLoading) {
    viewContent = (<Loading />)
  } else if (isSuccess) {
    viewContent = contentInfo.editorVersion == null || contentInfo.editorVersion == undefined ?
      (

        <figure style={{ backgroundColor: BgColor[contentInfo.bgColor] }} className=" h-screen sm:rounded-none md:rounded-3xl flex flex-col items-center  justify-center ">

          <div className="absolute top-0 right-0 rounded-t-md bg-opacity-30 bg-blur-md bg-gray-800 text-white p-4 rounded-md shadow-md">
            <p className="text-sm">
              <span className=" font-freehand  tracking-widest">{ChangeFormatDate(contentInfo.created_at)}</span>
            </p>
          </div>
          {/* <img className="w-24 h-24 md:w-48 md:h-auto md:rounded-none rounded-full mx-auto" src="/sarah-dayan.jpg" alt="" width="384" height="512"/> */}
          <div className="max-w-3xl max-h-full mx-auto mb-24 px-6 py-4 text-center shadow-md rounded-md scroll-alt overflow-auto scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-transparent">
            {contentInfo.isPremium ?
              (
                <div className=' absolute top-3 left-3'>
                  <PremiumIcon />
                </div>
              ) : null
            }
            {
              contentInfo.titleText != null && contentInfo.titleText != undefined ? (
                <h3 className={'text-4xl ' + FontsData[contentInfo.titleFont]}
                  style={{ color: verifyColorFont(FontColor[contentInfo.titleColor], BgColor[contentInfo.bgColor]) }}
                >{contentInfo.titleText}</h3>) : (null)
            }
            <div className=" pt-6 md:p-8 text-center md:text-left space-y-4">
              {infoComponent}
              <blockquote>

                <p

                  className={" text-3xl font-medium " + FontsData[contentInfo.contentFont]}
                  style={{ color: verifyColorFont(FontColor[contentInfo.contentColor], BgColor[contentInfo.bgColor]) }}
                >

                  {contentInfo.contentText}
                </p>
              </blockquote>
              {
                contentInfo.authorText != null && contentInfo.authorText != undefined ? (
                  <figcaption className={"text-2xl font-medium " + FontsData[contentInfo.authorFont]}>
                    <div className="" style={{ color: verifyColorFont(FontColor[contentInfo.authorColor], BgColor[contentInfo.bgColor]) }}>
                      {contentInfo.authorText}
                    </div>
                  </figcaption>) : (null)
              }

              <Stack direction="column" className={'absolute z-50 bottom-24 right-2 '} spacing={3} justifyContent="center" alignItems="center">

                <Avatar {...stringAvatar(contentInfo.createdBy.username)} onClick={() => gotoProfile(contentInfo.createdBy)} />

              <ItemMenuContainer>

                {
                  contentInfo.isFavorite ?
                    <Badge color="primary" overlap="circular" anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }} badgeContent={contentInfo.likes} max={999}>
                      <IconButton onClick={() => removeFavoriteAction(contentInfo._id)} aria-label="favorite" >
                        <FavoriteIcon fontSize="inherit" sx={{ color: pink[500] }} />
                      </IconButton>
                    </Badge> :
                    <Badge color="primary" overlap="circular" anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }} badgeContent={contentInfo.likes} max={999}>
                      <IconButton onClick={() => addFavoriteAction(contentInfo._id)} aria-label="favoriteBorder" >
                        <FavoriteBorderIcon fontSize="inherit" sx={{ color: pink[500] }} />
                      </IconButton>
                    </Badge>
                }
              </ItemMenuContainer>

<ItemMenuContainer>

                <CommentWithBadgeIcon item={contentInfo} openValue={state.fromNotification == true && (state.typeInteraction == TYPE_INTERACTION.comment || state.typeInteraction == TYPE_INTERACTION.reply)} />
</ItemMenuContainer>
<ItemMenuContainer>

              <OptionsIcon item={contentInfo} />
</ItemMenuContainer>
              </Stack>
            </div>
            <div className='absolute bottom-14 left-2'>
              <div className='flex'>
                {contentInfo.tags.map(
                  (tag: any) => (
                    <div className=' '>
                      <Chip label={tag} color="primary" size="small" variant="outlined" />
                    </div>)
                )
                }
              </div>
            </div>
          </div>
        </figure>


      ) : (
        contentInfo.editorVersion == 1 ? (
          <figure style={{ backgroundColor: BgColor[contentInfo.bgColor] }}
            className={"md:border md:border-gray-300 custom-height-content shadow-inner drop-shadow-lg   sm:rounded-none md:rounded-3xl relative  md:flex flex items-center overflow-hidden  bg-slate-100  md:p-0 dark:bg-slate-800"}
          >


            <div className="absolute top-0 right-0 rounded-t-md bg-opacity-30 bg-blur-md bg-gray-800 text-white p-4 rounded-md shadow-md">
              <p className="text-sm">
                <span className=" font-freehand  tracking-widest">{ChangeFormatDate(contentInfo.created_at)}</span>
              </p>
            </div>
            <div className="w-full h-full pt-6  space-y-4">


              <div className={"w-full justify-center md:p-8 pb-40 scroll-alt max-h-full overflow-auto  scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-transparent items-center"}>
                {
                  contentInfo.story.map((story: ContentStory, idx: number) => (

                    <div className={'w-screen md:w-full ' + SIZE_TEXT_VALUES[story.size] + ' ' + FontsData[story.font]}>

                      <MentionsInput
                        id={"standard-basic-" + idx}

                        value={story.text == null ? '' : story.text}
                        spellCheck={false}

                        disabled={true}
                        style={{
                          color: FontColor[story.color],
                          'highlighter': {
                            padding: '8px',
                            margin: '0px',
                            ...styleEditor[ALIGN_TEXT_VALUES[story.align]],
                            ...styleEditor[DECORATION_LINE_TEXT_VALUES[story.decorationLine]],
                            ...styleEditor[ITALIC_TEXT_VALUES[story.italic]],
                            ...styleEditor[WEIGHT_TEXT_VALUES[story.weight]],
                          },

                          'input':
                          {
                            padding: '8px',
                            ...styleEditor[ALIGN_TEXT_VALUES[story.align]],
                            ...styleEditor[DECORATION_LINE_TEXT_VALUES[story.decorationLine]],
                            ...styleEditor[ITALIC_TEXT_VALUES[story.italic]],
                            ...styleEditor[WEIGHT_TEXT_VALUES[story.weight]],
                          },
                          suggestions: {
                            list: {
                              backgroundColor: '#333',
                              border: '1px solid rgba(0,0,0,0)',
                              borderRadius: '5px',
                              fontSize: 14,
                            },
                            item: {
                              padding: '5px 15px',
                              borderBottom: '1px solid rgba(0,0,0,0.15)',
                              '&focused': {
                                backgroundColor: '#444',
                                color: '#fff'
                              },
                            },
                          },
                        }}
                        className={SIZE_TEXT_VALUES[story.size]}
                      >
                        <Mention
                          trigger="#"
                          markup='#[__display__](__id__)'
                          displayTransform={(id: string, display: string) => '#' + display}
                          data={[]}
                          style={{
                            backgroundColor: 'red',
                          }}
                          renderSuggestion={renderSuggestion}
                          appendSpaceOnAdd={true}
                        />

                      </MentionsInput>
                    </div>
                  ))
                }
              </div>

              <LinkToCompleteView item={contentInfo} />




            </div>


            <Stack direction="column" className={' absolute z-50 bottom-32 right-2 '} spacing={3} justifyContent="center" alignItems="center">


              <Avatar {...stringAvatar(contentInfo.createdBy.username)} onClick={() => gotoProfile(contentInfo.createdBy)} />
<ItemMenuContainer>

              {
                contentInfo.isFavorite ?
                  <Badge color="primary" overlap="circular" anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }} badgeContent={contentInfo.likes} max={999}>
                    <IconButton onClick={() => removeFavoriteAction(contentInfo._id)} aria-label="favorite" >
                      <FavoriteIcon fontSize="inherit" sx={{ color: pink[500] }} />
                    </IconButton>
                  </Badge> :
                  <Badge color="primary" overlap="circular" anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }} badgeContent={contentInfo.likes} max={999}>
                    <IconButton onClick={() => addFavoriteAction(contentInfo._id)} aria-label="favoriteBorder">
                      <FavoriteBorderIcon fontSize="inherit" sx={{ color: pink[500] }} />
                    </IconButton>
                  </Badge>
              }
</ItemMenuContainer>
<ItemMenuContainer>

              <CommentWithBadgeIcon item={contentInfo} openValue={state.fromNotification == true && (state.typeInteraction == TYPE_INTERACTION.comment || state.typeInteraction == TYPE_INTERACTION.reply)} />
</ItemMenuContainer>
<ItemMenuContainer>

              <OptionsIcon item={contentInfo} />
</ItemMenuContainer>

            </Stack>
          </figure>
        ) : (null)



      )
  }
  else if (isError) {
    viewContent = (
      <ErrorMessage message={error}></ErrorMessage>
    )
  }

  return (
    <>
      <div className='grid grid-cols-1 md:grid-cols-9' >

        <div className='hidden md:block'>
          <div className='m-2'>
            <img className="w-48 max-w-xs" src="../_assets/logoBlack.png" alt="Quotless" />
            <h1 className=" text-center text-6xl font-semibold text-gray-800  font-lora hidden">Quotless</h1>
          </div>
        </div>

        <div className='col-start-4 col-span-3' >
          <div className=' h-full'>
            {viewContent}
          </div>
        </div>
        <div className='hidden md:block'></div>
      </div>

    </>
  )
}

export default DetailScreen