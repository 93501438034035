import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
// Make TS accept the existence of our window.__ENV object - defined in index.html:
interface WindowWithEnv extends Window {
    __ENV?: {
      backendURL: string, // REACT_APP_BACKEND_URL environment variable
      sandbox: "true" | "false", // REACT_APP_SANDBOX_SDK environment variable - string, not boolean!
    }
}
const _window: WindowWithEnv = window;
const backendURL = _window.__ENV && _window.__ENV.backendURL;

export const apiCommentSlice = createApi({
    reducerPath: 'apiCommentSlice',
    baseQuery: fetchBaseQuery({
      baseUrl: backendURL,
      prepareHeaders: (headers, { getState }:any) => {
        const token = getState().auth.userToken
        if (token) {        
         // include token in req header
          headers.set('authorization', `Bearer ${token}`)  
          return headers
        }
      }
    }),
    tagTypes: ['Comment'],
    endpoints: (builder) => ({
      getCommentsByContent: builder.query({
        query: ({page,idContent}) => ({
          url: '/comment/'+page+'/content/'+idContent,
          method: 'GET',     
        }),                 
      }),
      getCommentsByComment: builder.query({
        query: ({page,idComment}) => ({
            url: '/comment/'+page+'/comment/'+idComment,
            method: 'GET',     
        }),
      }),
      addNewComment: builder.mutation({
        query: ({payload, idContent}) => ({
          url: '/comment/'+idContent,
          method: 'POST',
          body: payload,
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
        }),
        invalidatesTags: ['Comment'],
      }),
      addNewReply: builder.mutation({
        query: ({payload, idContent, idCommentParent}) => ({
          url: '/comment/'+idContent+'/comment/'+idCommentParent,
          method: 'POST',
          body: payload,
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
        }),
        invalidatesTags: ['Comment'],
      }),
      removeComment: builder.mutation({
        query: (idComment) => ({
          url: '/comment/'+idComment,
          method: 'DELETE',        
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
        }),
        invalidatesTags: ['Comment'],
      }),
      addCommentToFavorite: builder.mutation({
        query: (commentId) => ({
          url: '/comment/favorite/'+commentId,
          method: 'POST',        
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
        }),
        invalidatesTags: ['Comment'],
      }),
      removeCommentToFavorite: builder.mutation({
        query: (commentId) => ({
          url: '/comment/favorite/'+commentId,
          method: 'DELETE',        
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
        }),
        invalidatesTags: ['Comment'],
      }),
    }),
    
     
  })
  export const { 
    useLazyGetCommentsByContentQuery,
    useLazyGetCommentsByCommentQuery,
    useAddNewCommentMutation,
    useAddNewReplyMutation,
    useRemoveCommentMutation,
    useAddCommentToFavoriteMutation,
    useRemoveCommentToFavoriteMutation
  } = apiCommentSlice