import * as React from 'react';
import StarIcon from '@mui/icons-material/Star';
import { amber } from '@mui/material/colors';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import ClickAwayListener from '@mui/material/ClickAwayListener';
const PremiumIcon = () => {
      const [open, setOpen] = React.useState(false);

      const handleTooltipClose = () => {
        setOpen(false);
      };

      const handleTooltipOpen = () => {
        setOpen(true);
      };

    return ( 
        <ClickAwayListener onClickAway={handleTooltipClose}>
            <div>
                 <Tooltip
                        PopperProps={{
                          disablePortal: true,
                        }}
                        onClose={handleTooltipClose}
                        open={open}
                        disableFocusListener
                        disableHoverListener
                        disableTouchListener
                        title="This content is Premium."
                      >
                    <IconButton onClick={handleTooltipOpen}  aria-label="delete" style={{width:'2em'}}>
                        <StarIcon  sx={{ color: amber[500] }}/>                
                    </IconButton>
                </Tooltip>
            </div>
        </ClickAwayListener>
    );
}
 
export default PremiumIcon;