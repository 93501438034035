import React from 'react'
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import PremiumIcon from '../../shared/components/PremiumIcon'
import FavoriteIcon from '@mui/icons-material/Favorite';
import { pink } from '@mui/material/colors';
import CommentWithBadgeIcon from '../../CommentView/components/CommentWithBadgeIcon';
type ItemListFavoriteContentProps={
    item:any,
    goToPreview:(id:string)=>void,
    responseRemoveFavoriteContent:any,
    removeFavoriteItem:(id:string)=>void,
}
function ItemListFavoriteContent({item, goToPreview, removeFavoriteItem, responseRemoveFavoriteContent}:ItemListFavoriteContentProps) {
    const convertDate = (data: any) => {
        if (!data) return ''
        const dateString = data;
        const date = new Date(dateString);
        const year = date.getUTCFullYear();
        const month = ('0' + (date.getUTCMonth() + 1)).slice(-2);
        const day = ('0' + date.getUTCDate()).slice(-2);
        const hours = ('0' + date.getUTCHours()).slice(-2);
        const minutes = ('0' + date.getUTCMinutes()).slice(-2);
        const seconds = ('0' + date.getUTCSeconds()).slice(-2);
        //const formattedDate = `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
        const formattedDate = `${day}/${month}/${year}`;
        return formattedDate
      }
    return (
        <div key={item._id} className="bg-white rounded-lg shadow-lg p-4 w-full">
            <div className="flex items-center justify-between">
                <div className='flex items-center'>
                    {
                        item.isPremium ? (<PremiumIcon />) : null
                    }
                    <p className="text-gray-600 text-sm font-light">
                        {convertDate(item.created_at)}
                    </p>
                </div>
                <CommentWithBadgeIcon item={item}/>
                <IconButton onClick={() => removeFavoriteItem(item._id)} disabled={responseRemoveFavoriteContent.isLoading} aria-label="delete" style={{ width: '2em' }}>
                    <FavoriteIcon fontSize="inherit" sx={{ color: pink[500] }} />
                </IconButton>

            </div>
            <p className="text-gray-800 text-lg font-medium mt-2" onClick={() => goToPreview(item._id)}>{item.contentText}</p>
        </div>
    )
}

export default ItemListFavoriteContent