import { AnyAction, PayloadAction, createSlice } from "@reduxjs/toolkit";
import BgColor from "../../../_entities/BgColor";
import FontsData from "../../../_entities/FontsData";
import FontColor from "../../../_entities/FontColor";

export const LIMIT_CHARACTERS = 2500

export type ALIGN_TEXT = 'center-align' | 'left-align' | 'right-align'
export type SIZE_TEXT = 'txt-xs' | 'txt-sm' | 'txt-base' | 'txt-lg' | 'txt-xl' | 'txt-2xl' | 'txt-3xl' | 'txt-4xl' | 'txt-5xl' | 'txt-6xl' | 'txt-7xl' | 'txt-8xl' | 'txt-9xl'
export type WEIGHT_TEXT = 'txtw-normal' | 'txtw-black'
export type ITALIC_TEXT = 'txt-not-italic' | 'txt-italic'
export type DECORATION_LINE_TEXT = 'txt-not-decoration-line' | 'txt-underline' | 'txt-overline' | 'txt-line-through' | 'txt-underline-overline'

export const ALIGN_TEXT_VALUES: ALIGN_TEXT[] = ['center-align', 'left-align', 'right-align'];
export const SIZE_TEXT_VALUES: SIZE_TEXT[] = ['txt-xs', 'txt-sm', 'txt-base', 'txt-lg', 'txt-xl', 'txt-2xl', 'txt-3xl', 'txt-4xl', 'txt-5xl', 'txt-6xl', 'txt-7xl', 'txt-8xl', 'txt-9xl'];
export const WEIGHT_TEXT_VALUES: WEIGHT_TEXT[] = ['txtw-normal', 'txtw-black'];
export const ITALIC_TEXT_VALUES: ITALIC_TEXT[] = ['txt-not-italic', 'txt-italic'];
export const DECORATION_LINE_TEXT_VALUES: DECORATION_LINE_TEXT[] = ['txt-not-decoration-line', 'txt-underline', 'txt-overline', 'txt-line-through','txt-underline-overline'];

export interface ContentItem{
    text: string | null,
    font: number,
    color: number,
    align:number,
    size:number,
    weight:number,
    italic:number,
    decorationLine: number,
    
}

export interface Editor {

    content: ContentItem[],
    bgColor: number,
    focusIn: number,
    n_characters:number,
    language: string
}


const initialParagraph = {
    text:'',
    align:0,
    color:1,
    decorationLine:0,
    font:0,
    italic:0,
    size:0,
    weight:0,
}
const initialState: Editor = {
    content: [initialParagraph],
    bgColor: 0,
    focusIn: 0,
    n_characters:0,
    language: ''
}
const editorSlice = createSlice(
    {
        name: 'editor',
        initialState,
        reducers: {
            newAfterParagraph:(state: Editor) => {                
                if(state.content.length<5){
                    state.content.splice(state.focusIn+1,0,initialParagraph)
                    state.focusIn = state.focusIn+1; 
                }                                                                                       
            },
            newBeforeParagraph:(state: Editor) => {                
                if(state.content.length<5){
                    state.content.splice(state.focusIn,0,initialParagraph)
                    state.focusIn = state.focusIn; 
                }                                                                                        
            },
            removeParagraph:(state: Editor,{payload}) => {  
                console.log('data idx', payload)              
                if(state.content.length>1){
                    state.content.splice(payload,1)
                    state.focusIn = payload-1; 
                }                                                                                        
            },
            changeFocus:(state: Editor, { payload }) => {                
                if (payload >= 0 && payload < state.content.length) {                    
                    state.focusIn = payload;                    
                }                
            },
            changeBackgroundColor:(state: Editor, { payload }) => {                
                if (payload >= 0 && payload < BgColor.length) {                    
                    state.bgColor = payload;                    
                }                
            }, 
            changeFont:(state: Editor, { payload }) => {
                const index = state.focusIn;
                if (index >= 0 && index < state.content.length) {                                        
                    if (payload >= 0 && payload < FontsData.length) {                    
                        state.content[index].font = payload;                                        
                    }                
                }       
            },
            changeFontColor:(state: Editor, { payload }) => {
                const index = state.focusIn;
                if (index >= 0 && index < state.content.length) {                                        
                    if (payload >= 0 && payload < FontColor.length) {                    
                        state.content[index].color = payload;                                        
                    }                
                }       
            },            
            write:(state: Editor, { payload }) => {
                const index = state.focusIn;
                let n_c = 0 
                state.content.forEach((line:ContentItem,idx:number)=>{
                    if(idx==index){
                        return 
                    }
                    const line_count = (line.text as string).length
                    n_c = line_count + n_c
                })
                n_c = n_c + payload.length
                if (index >= 0 && index < state.content.length && n_c<LIMIT_CHARACTERS) {       
                    state.content[index].text = payload;
                    state.n_characters = n_c                             
                }                
            },
            addSize: (state: Editor) => {
                const index = state.focusIn;
                if (index >= 0 && index < state.content.length) {
                    if(state.content[index].size < (SIZE_TEXT_VALUES.length-1)){
                        state.content[index].size += 1;
                    }
                }                
            },
            restSize:(state: Editor) => {
                const index = state.focusIn;
                if (index >= 0 && index < state.content.length) {
                    if(state.content[index].size > 0){
                        state.content[index].size -= 1;
                    }
                }                
            },
            alignText:(state: Editor,{payload}) => {
                const index = state.focusIn;
                if (index >= 0 && index < state.content.length) {
                    if(payload >= 0 && payload < ALIGN_TEXT_VALUES.length){
                        state.content[index].align = payload;
                    }
                }                
            },
            changeWeight:(state: Editor) => {
                const index = state.focusIn;
                if (index >= 0 && index < state.content.length) {
                    if(state.content[index].weight>=0 && state.content[index].weight<(WEIGHT_TEXT_VALUES.length-1)){
                        state.content[index].weight+=1
                    }else{
                        state.content[index].weight=0
                    }
                }                
            },
            changeItalic:(state: Editor) => {
                const index = state.focusIn;
                if (index >= 0 && index < state.content.length) {                    
                        state.content[index].italic=state.content[index].italic==1 ? 0 : 1
                   
                }                
            },
            changeDecorationLine:(state: Editor) => {
                const index = state.focusIn;
                if (index >= 0 && index < state.content.length) {                    
                    if(state.content[index].decorationLine>=0 && state.content[index].decorationLine<(DECORATION_LINE_TEXT_VALUES.length-1)){
                        state.content[index].decorationLine+=1
                    }else{
                        state.content[index].decorationLine=0
                    }
                   
                }                
            },
            setLanguage: (state: Editor, {payload}) => {
                console.warn(payload)
                    if (payload) {                    
                        state.language = payload;                                        
                    }  
                
            },
        },
    },
)


// export actions
export const { 
    changeFocus, 
    changeBackgroundColor, 
    changeFont, 
    changeFontColor, 
    write, 
    addSize, 
    restSize,
    alignText,
    changeWeight,
    changeItalic,
    changeDecorationLine,
    newAfterParagraph,
    newBeforeParagraph,
    removeParagraph,
    setLanguage
} = editorSlice.actions
export default editorSlice.reducer