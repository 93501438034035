import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import * as React from 'react';


const ErrorMessage = (props:any) => {
    const {
        message
    } = props

    const formatError = (message:any)=>{
        console.log(message)
        let error = ''
        let txt = ''
        if (!message) return 'Unknow'
        if(message.originalStatus){
            error=JSON.stringify(message.originalStatus)    
        }else if(message.status){
            error=JSON.stringify(message.status)    
        }
        if(message.data){
            txt = message.data.message ? message.data.message : message.data
        }
        return error + ' - ' + JSON.stringify(txt)
        
    }

    return ( 
        <Alert severity="error">
        <AlertTitle>Error</AlertTitle>
              { formatError(message) }
        </Alert>
     );
}
 
export default ErrorMessage;