const styleEditor = {
    "center-align":{
        "text-align": "center"
    },

    "left-align":{
        "text-align":" left"
    },

    "right-align":{
        "text-align":" right"
    },

    "txt-xs":{

        "font-size": "0.75rem", /* 12px */
        "line-height": "1rem" /* 16px */
    },	
    "txt-sm":{
        "font-size": "0.875rem", /* 14px */
        "line-height": "1.25rem" /* 20px */
    },
    "txt-base":{
        "font-size": "1rem", /* 16px */
        "line-height": "1.5rem" /* 24px */
    },	
    "txt-lg":{
        "font-size": "1.125rem", /* 18px */
        "line-height": "1.75rem" /* 28px */
    },	
    "txt-xl":{
        "font-size": "1.25rem", /* 20px */
        "line-height": "1.75rem" /* 28px */
    },	
    "txt-2xl":{
        "font-size": "1.5rem", /* 24px */
        "line-height": "2rem" /* 32px */
    },
    "txt-3xl":{
        "font-size": "1.875rem", /* 30px */
        "line-height": "2.25rem" /* 36px */
    },	
    "txt-4xl":{
        "font-size": "2.25rem", /* 36px */
        "line-height": "2.5rem" /* 40px */
    },	
    "txt-5xl":{
        "font-size": "3rem", /* 48px */
        "line-height": "1"
    },	
    "txt-6xl":{
        "font-size": "3.75rem", /* 60px */
        "line-height": "1"
    },	
    "txt-7xl":{
        "font-size": "4.5rem", /* 72px */
        "line-height": "1"
    },	
    "txt-8xl":{
        "font-size": "6rem", /* 96px */
        "line-height": "1"
    },	
    "txt-9xl":{
        "font-size": "8rem", /* 128px */
        "line-height": "1"
    },	



    "txtw-normal":{
        "font-weight": "400"
    },

    "txtw-medium":{
        "font-weight": "500"
    },

    "txtw-bold":{
        "font-weight": "700"
    },

    "txtw-black":{
        "font-weight": "900"
    },


    "txt-italic":{
        "font-style": "italic"
    },
    "txt-not-italic":{
        "font-style": "normal"
    },


    "txt-not-decoration-line":{
        "text-decoration-line": "none"
    },
    "txt-underline":{
        "text-decoration-line": "underline"
    },
    "txt-overline":{
        "text-decoration-line": "overline"
    },
    "txt-line-through":{
        "text-decoration-line": "line-through"
    },
    "txt-underline-overline":{
        "text-decoration-line": "underline overline"
    }
}


export default styleEditor