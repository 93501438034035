import axios from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { AuthPIResult } from '../../../_entities/PI';

// Make TS accept the existence of our window.__ENV object - defined in index.html:
interface WindowWithEnv extends Window {
    __ENV?: {
      backendURL: string, // REACT_APP_BACKEND_URL environment variable
      sandbox: "true" | "false", // REACT_APP_SANDBOX_SDK environment variable - string, not boolean!
    }
}
const _window: WindowWithEnv = window;
const backendURL = _window.__ENV && _window.__ENV.backendURL;

const axiosClient = axios.create({ baseURL: `${backendURL}`, timeout: 20000, withCredentials: true});
const config = {headers: {'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'}};

export const signAccountUser = createAsyncThunk(
  'auth/signin',
  async (authResult:AuthPIResult, { rejectWithValue }) => {
    try {
        const response = await signInUser(authResult)
        localStorage.setItem('loginWith','PI')
        localStorage.setItem('userToken', response.data.data.systemtoken)
        return response?.data
    } catch (error:any) {
    // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

/*export const logoutAccountUser = createAsyncThunk(
  'auth/logout',
  async (data:any, { rejectWithValue }) => {
    try {
        const response = await signOutUser()
        
        return response?.data
    } catch (error:any) {
    // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

const signOutUser = () => {
    return axiosClient.get('/user/signout',config);
  }*/

const signInUser = async (authResult: AuthPIResult) => {
    return  await axiosClient.post('/user/signin', {authResult}, config)
}
