import * as React from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { Button, IconButton } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
const InfoAboutButtonCoffee = () => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div>
      <IconButton color='primary' aria-label=""
        aria-describedby={id}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <InfoOutlinedIcon />
      </IconButton>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}

        className='w-3/4'
      >
        <div className='p-4 w-3/4'>
          <h2 className='font-bold text-sm'>  Why Support Quotless?</h2>

          <p className='text-sm p-2'>
            At the heart of Quotless lies a shared passion for words, stories, and inspiration. Every quote, every story, and every reflective moment you find in Quotless is the result of dedicated effort and passionate commitment.
          </p>


          <p className='text-sm p-2'>
            Your support is more than just a cup of coffee; it's the fuel that propels our dedication:
          </p>
          <ul className='text-sm p-2'>
            <li>
              🌟 <span className='font-bold'>Crafts Daily Magic:</span> Every day, we strive to weave magic through words that inspire, motivate, and connect. Your support allows us to dedicate time to perfecting this daily magic just for you.
            </li>
            <li>
              💡 <span className='font-bold'>Investing in Inspiration:</span> Through "Buy Me a Coffee," you can directly contribute to the evolution of Quotless. Your support enables us to invest in new features, explore creative ideas, and take inspiration to unimaginable heights.
            </li>
            <li>
              🌐 <span className='font-bold'>Beyond the Server:</span> Behind every quote is a server supporting the Quotless experience. Your contribution helps maintain this virtual haven where words come to life.
            </li>
          </ul>




          <p className='text-sm p-2'>
            Together, we create a sanctuary of inspiration!
          </p>
          <p className='text-sm p-2'>
            Your support is not just a transaction; it's an emotional connection with the essence of Quotless. We join forces in creating something beautiful, something that impacts and resonates in every corner of your life.
          </p>
          <p className='text-sm'>
            Thank you for being part of our journey. Your support is the thread weaving the tapestry of Quotless.
          </p>
        </div>
      </Popover>
    </div>

  );
}



const BuyMeAcoffeeButtonMobile = () => {
  return (
    <div className='flex w-10 h-10'>

      <a href="https://www.buymeacoffee.com/quotless"
        target="_blank">
          <img
          src="https://media4.giphy.com/media/TDQOtnWgsBx99cNoyH/giphy.gif"
          alt="https://giphy.com/embed/TDQOtnWgsBx99cNoyH"
          style={{ height: "100%", width: "100%" }} />
      </a>
        
      
    </div>
  )
}

export default BuyMeAcoffeeButtonMobile