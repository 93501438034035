import * as React from 'react';

import { TextField, Chip, Autocomplete, Alert } from '@mui/material';
import { useLazyGetHashtagsSuggestionsByUsageQuery } from '../../../_redux/slices/API/hashtags.service';
import { formatNumber } from '../../../shared/utils/number.util';


type InputHashtagsProps = {
    getHashtags: (hashtags: any[]) => any
    getError: (error: any) => any
}

export default function InputHashtags({ getHashtags, getError }: InputHashtagsProps) {
    const [hashtags, setHashtags] = React.useState<any>([]);
    const [suggestedHashtags, setSuggestedHashtags] = React.useState<any>([]);
    const [errorLongitud, setErrorLongitud] = React.useState<string>('');

    const [trigger, result] = useLazyGetHashtagsSuggestionsByUsageQuery()

    React.useEffect(() => {
        getHashtags(hashtags)
    }, [hashtags])

    React.useEffect(() => {
        getError(errorLongitud)
    }, [errorLongitud])

    function removeCommas(str: string) {
        return str.replace(/,/g, '');
    }

    function cleanHashtag(hashtag: string) {
        // Remover cualquier carácter no válido excepto letras, números y guiones bajos
        const cleanedHashtag = hashtag.replace(/[^a-zA-Z0-9_]/g, '');

        return cleanedHashtag;
    }
    const handleAddHashtag = (event: any) => {
        if (event.key === 'Enter' || event.key === ',') {
            const newHashtag: string = cleanHashtag(removeCommas(event.target.value.trim()));

            if (newHashtag !== '' && !hashtags.includes(newHashtag)) {

            
                if (hashtags.length >= 5) {
                    // Mostrar error de cantidad máxima de hashtags

                    return;
                }

                setHashtags((prevHashtags: any) => [...prevHashtags, newHashtag]);
                event.target.value = '';
            }
        }
    };


    const handleDeleteHashtag = (deletedHashtag: any) => {
        setHashtags((prevHashtags: any) =>
            prevHashtags.filter((hashtag: any) => hashtag !== deletedHashtag)
        );
    };

    const handleInputChange = async (event: any, value: any) => {
        const newHashtag: string = cleanHashtag(removeCommas(event.target.value.trim()));
        if (newHashtag.length > 40) {
            // Mostrar error de longitud máxima de hashtag
            setErrorLongitud('Some elements exceed the character limit, the max is 40 characters.')
            return;
        }else{
            setErrorLongitud('')
        }
        // Aquí puedes realizar una solicitud al API para obtener las sugerencias de hashtags
        // y luego actualizar el estado de suggestedHashtags con los datos obtenidos.
        if (!result.isLoading && !result.isFetching && newHashtag.length>3) {
            const result = await trigger({ suggestion:newHashtag }, false)
            //const suggestions = result.data.suggestedHashtags.map((data:any)=> ('#'+data.text + '('+formatNumber(data.usageCount)+')'))
            //setSuggestedHashtags(suggestions);
            setSuggestedHashtags([...result.data.suggestedHashtags])
    
        }else{
            setSuggestedHashtags([])
        }
        

    };

    const formatOption = (option:any)=>{
        console.log(option)
        return '#'+option.text + '('+formatNumber(option.usageCount)+')'
    }

    return (
        <div>
            <Autocomplete
                freeSolo
                style={{ width: '80vw' }}
                options={suggestedHashtags}
                getOptionLabel={(option:any) => option.text}
                renderOption={(props, option) => (
                    <li {...props}>
                      {formatOption(option)}
                    </li>
                  )}
                renderInput={(params:any) => (
                    <TextField
                        {...params}

                        label="Hashtags"
                        fullWidth
                        onKeyDown={handleAddHashtag}
                        error={hashtags.length > 5}
                        helperText={hashtags.length > 5 ? 'Max 5 hashtags' : ''}
                        InputProps={{
                            ...params.InputProps,
                            startAdornment: hashtags.map((hashtag: any) => (
                                <Chip
                                    key={hashtag}
                                    label={'#' + hashtag}
                                    onDelete={() => handleDeleteHashtag(hashtag)}
                                    color="primary"
                                    style={{ margin: '4px' }}
                                />
                            )),
                            multiline: true
                        }}
                    />
                )}
                onInputChange={handleInputChange}
            />
            {
                errorLongitud != '' ? (<Alert severity="error">{errorLongitud}</Alert>) : (null)
            }
            {/* <div>
      <h3>Hashtags:</h3>
      <ul>
        {hashtags.map((hashtag:any) => (
          <li key={hashtag}>#{hashtag}</li>
        ))}
      </ul>
    </div> */}
        </div>
    );
}