import * as React from 'react';
import { styled } from '@mui/material/styles';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import { useDispatch, useSelector } from 'react-redux';
import FormatStrikethroughIcon from '@mui/icons-material/FormatStrikethrough';
import FormatOverlineIcon from '@mui/icons-material/FormatOverline';
import DensityLargeIcon from '@mui/icons-material/DensityLarge';
import { ALIGN_TEXT, ALIGN_TEXT_VALUES, addSize, alignText, changeDecorationLine, changeItalic, changeWeight, restSize } from '../../_redux/slices/editor/editor.slice';



const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    '& .MuiToggleButtonGroup-grouped': {
        margin: theme.spacing(0.5),
        border: 0,
        '&.Mui-disabled': {
            border: 0,
        },
        '&:not(:first-of-type)': {
            borderRadius: theme.shape.borderRadius,
        },
        '&:first-of-type': {
            borderRadius: theme.shape.borderRadius,
        },
    },
}));

export default function ToolBar() {

    //const [formats, setFormats] = React.useState(() => []);
    const dispatch = useDispatch()
    const { content,
        bgColor,
        focusIn } = useSelector((state: any) => state.editor)

        
    




    const updateWeight = () => {
        dispatch(changeWeight())
    }

    const addSizeAction = () => {
        dispatch(addSize());
    }

    const restSizeAction = () => {
        dispatch(restSize());
    }
    
    const updateAlign = (event: React.MouseEvent<HTMLElement>,
        newAlignment: ALIGN_TEXT,) => {
        const alignValue = ALIGN_TEXT_VALUES.indexOf(newAlignment)
        dispatch(alignText(alignValue))
    }

    const updateDecorationLine = () => {
        dispatch(changeDecorationLine())
    }

    const updateItalic = () => {
        dispatch(changeItalic())
    }

    const getValueFormatButtons = () => {
        let formats:string[] = []
        if(content[focusIn].weight>0){
            formats.push('bold')
        }else{
            let idxBold = formats.indexOf('bold')
            if(idxBold>=0){
                formats.splice(idxBold,1)
            }
        }

        if(content[focusIn].italic>0){            
            formats.push('italic')
        }else{            
            let idxItalic = formats.indexOf('italic')
            if(idxItalic>=0){
                formats.splice(idxItalic,1)
            }
        }

        if(content[focusIn].decorationLine>0){
            formats.push('underlined')
        }else{
            let idxUnderlined = formats.indexOf('underlined')
            if(idxUnderlined>=0){
                formats.splice(idxUnderlined,1)
            }
        }
        console.log(formats)
        return formats
    }

    return (
        <div className='w-full flex justify-center'>
            <Paper
                elevation={0}
                sx={{
                    display: 'flex',
                    border: (theme) => `1px solid ${theme.palette.divider}`,
                    flexWrap: 'wrap',
                    backgroundColor: ' rgb(253,230,138)'
                }}
                className='bg-amber-200'
            >
                <StyledToggleButtonGroup
                    size="small"
                    value={ALIGN_TEXT_VALUES[content[focusIn].align]}
                    exclusive
                    onChange={updateAlign}
                    aria-label="text alignment"
                >
                    <ToggleButton value="left-align" aria-label="left aligned" >
                        <FormatAlignLeftIcon />
                    </ToggleButton>
                    <ToggleButton value="center-align" aria-label="centered">
                        <FormatAlignCenterIcon />
                    </ToggleButton>
                    <ToggleButton value="right-align" aria-label="right aligned" >
                        <FormatAlignRightIcon />
                    </ToggleButton>
                </StyledToggleButtonGroup>
                <Divider flexItem orientation="vertical" sx={{ mx: 0.5, my: 1 }} />
                <StyledToggleButtonGroup
                    size="small"
                    value={getValueFormatButtons()}
                    onChange={()=>{}}
                    aria-label="text formatting"
                >
                    <ToggleButton value="bold" aria-label="bold" onClick={updateWeight}>
                        <FormatBoldIcon />
                    </ToggleButton>
                    <ToggleButton value="italic" aria-label="italic" onClick={updateItalic}>
                        <FormatItalicIcon />
                    </ToggleButton>
                    <ToggleButton value="underlined" aria-label="underlined" onClick={updateDecorationLine}>
                        {
                            content[focusIn].decorationLine == 0 ? (<FormatUnderlinedIcon />):(null)                          
                        }
                        {
                            content[focusIn].decorationLine == 1 ? (<FormatUnderlinedIcon />):(null)                             
                        }
                        {
                            content[focusIn].decorationLine == 2 ? (<FormatOverlineIcon />):(null)                            
                        }
                        {
                            content[focusIn].decorationLine == 3 ? (<FormatStrikethroughIcon />):(null)                                                        
                        }
                        {
                            content[focusIn].decorationLine == 4 ? (<DensityLargeIcon />):(null) 
                        }
                    </ToggleButton>
                    {/* <ToggleButton value="color" aria-label="color" >
            <FormatColorFillIcon />
            <ArrowDropDownIcon />
          </ToggleButton> */}
                </StyledToggleButtonGroup>
                <Divider flexItem orientation="vertical" sx={{ mx: 0.5, my: 1 }} />


                <IconButton aria-label="plus" onClick={addSizeAction}>
                    <AddCircleIcon />
                </IconButton>
                <ToggleButton value="italic" aria-label="italic" disabled>
                    {content[focusIn].size}
                </ToggleButton>
                <IconButton aria-label="minus" onClick={restSizeAction}>
                    <RemoveCircleIcon />
                </IconButton>

            </Paper>
        </div>
    );
}