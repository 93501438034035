import React,{ useEffect } from 'react';
import GoogleIcon from '@mui/icons-material/Google';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import Loading from '../../shared/components/Loading';
import { getAuth,signInWithRedirect , getRedirectResult, GoogleAuthProvider, signInWithPopup, UserCredential } from "firebase/auth";
import { signGoogle } from '../../_redux/slices/auth/auth.slice';
import { useDispatch } from 'react-redux';
import { authFirebase, signInWithCredentialFirebase } from '../../firebase';
interface GoogleFirebaseLoginButtonProps {
  onSuccess: (token: string, uid:string, username:string | null) => void;
  onFailure: (error: any) => void;

}
const auth = getAuth();
const provider = new GoogleAuthProvider();
export const GoogleFirebaseLoginButton = ({ onSuccess, onFailure }:GoogleFirebaseLoginButtonProps) => {


  const [openAlert, setOpenAlert] = React.useState<boolean>(false);

const dispatch =  useDispatch()
  const handleClose = () => {
    setOpenAlert(false);
  };


  const loginToGoogle = async () => {
    //signInWithRedirect(auth, provider)
   
   signInWithPopup(authFirebase, provider)
   .then(async (result:UserCredential)=>{
    console.log('result', result)
    if(result){
      const user = result.user;
      const token = await user.getIdToken();
      onSuccess(token,result.user.uid,user.displayName)


    }
   })
   .catch((error)=>{
    console.log(error)
   });
  }

  /*getRedirectResult(auth)
  .then((result:any) => {
    console.log('result', result)
    if(result){
      
      // This gives you a Google Access Token. You can use it to access Google APIs.
      const credential:any = GoogleAuthProvider.credentialFromResult(result);
      const token = credential.accessToken;
      console.log('token', result.user.accessToken)
      onSuccess(result.user.accessToken,result.user.uid,result.user.username)


    }
  }).catch((error:any) => {
    // Handle Errors here.
    const errorCode = error.code;
    const errorMessage = error.message;
    // The email of the user's account used.

    // The AuthCredential type that was used.
    const credential = GoogleAuthProvider.credentialFromError(error);
    
    console.log(error)
    // ...
  });
  */
  /*(response:any) => {
    if (response.credential) {
      // El inicio de sesión fue exitoso
      const { id_token } = response.credential;
      onSuccess(id_token);
    } else {
      // El inicio de sesión falló
      onFailure(response.error);
    }
  };*/



  return (
    
    <div>    
    <div>               
        <button onClick={()=>{ 
                  setOpenAlert(true)          
        } } className="w-full hover:bg-red-800 text-red-500 hover:text-red-200 font-bold py-2 px-4 rounded">
     <GoogleIcon/> Login with Google
        </button>
     
    </div>

    <Dialog
        open={openAlert}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Quotless sign in"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <p>
            By accepting, you agree to the <a href='/termsandconditions.html' className='underline font-bold'>terms, conditions</a>, and <a href='/privacypolicy.html'  className='underline font-bold'>privacy policies</a> of Quotless. By using this application, you acknowledge your consent to the aforementioned.
            </p>
       
          </DialogContentText>
          <DialogActions>
            <Button onClick={handleClose}>Do Not Agree</Button>
            <Button onClick={()=>{
              loginToGoogle()
            } }>I Agree</Button>
              
          </DialogActions>
        </DialogContent>
      </Dialog>
  </div>
  );
};