import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
// Make TS accept the existence of our window.__ENV object - defined in index.html:
interface WindowWithEnv extends Window {
    __ENV?: {
      backendURL: string, // REACT_APP_BACKEND_URL environment variable
      sandbox: "true" | "false", // REACT_APP_SANDBOX_SDK environment variable - string, not boolean!
    }
}
const _window: WindowWithEnv = window;
const backendURL = _window.__ENV && _window.__ENV.backendURL;

export const apiContentSlice = createApi({
  reducerPath: 'apiContentSlice',
  baseQuery: fetchBaseQuery({
    baseUrl: backendURL,
    prepareHeaders: (headers, { getState }:any) => {
      const token = getState().auth.userToken
      if (token) {        
       // include token in req header
        headers.set('authorization', `Bearer ${token}`)  
        return headers
      }
    }
  }),
  tagTypes: ['Content'],
  endpoints: (builder) => ({
    getContent: builder.query({
      query: ({id}) => ({
        url: '/content/'+id+'/one',
        method: 'GET',     
      }),
    }),
    getFeed: builder.query({
      query: ({page}) => {
        console.warn('page --- ', page)
        return({
        url: '/content/feed/'+page,
        method: 'GET',     
      })},
    }),
    getFavorites: builder.query({
      query: ({page}) => ({
        url: '/content/favorite/all/'+page,
        method: 'GET',     
      }),
    }),
    addNewContent: builder.mutation({
      query: (payload) => ({
        url: '/content',
        method: 'POST',
        body: payload,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      }),
      invalidatesTags: ['Content'],
    }),
    addContentToFavorite: builder.mutation({
      query: (contentId) => ({
        url: '/content/favorite/'+contentId,
        method: 'POST',        
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      }),
      invalidatesTags: ['Content'],
    }),
    removeContentToFavorite: builder.mutation({
      query: (contentId) => ({
        url: '/content/favorite/'+contentId,
        method: 'DELETE',        
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      }),
      invalidatesTags: ['Content'],
    }),
    removeContent: builder.mutation({
      query: (contentId) => ({
        url: '/content/'+contentId,
        method: 'DELETE',        
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      }),
      invalidatesTags: ['Content'],
    })
  }),
   
})
export const { 
  useGetContentQuery,
  useLazyGetFeedQuery, 
  useGetFavoritesQuery,
  useAddNewContentMutation,
  useAddContentToFavoriteMutation,
  useRemoveContentToFavoriteMutation,
  useRemoveContentMutation
} = apiContentSlice